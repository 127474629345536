import React from "react";
//-- mui
import SwitchMuiCustom from "../../../../Inputs/Booleans/SwitchMui";

const SwitchItem = (props) => {

  const {
    field,
    fieldId,
    columnIndex = 0,
    handleSelectedChange,
  } = props;

  return (
    <>
      <SwitchMuiCustom
        checked={field.isSelected}
        label={field.text}
        onChange={() => {
          handleSelectedChange(fieldId, !field.isSelected, columnIndex);
        }}
      />
      {field.isSelected}
    </>
  );
};

export default SwitchItem;