import axios from "axios";
import { store } from "../../../../store";
import {
	showNotificationError,
	showControlledError,
	unlockedWindow,
} from "../../../../store/actions";
import {
	errorGet,
	errorCreate,
	errorEdit,
	errorDelete,
	errorInJS,
	deleteControlledError,
	createEditControlledError,
	serviceUnavailable,
	unauthorizedError,
} from "../Notifications/notification-messages";

const METHODS = {
	get: errorGet,
	post: errorCreate,
	put: errorEdit,
	patch: errorEdit,
	delete: errorDelete,
	default: errorInJS,
};

export const resolveError = async (error, paramsLabels = {}) => {
	if (axios.isCancel(error)) { return; }

	const dispatch = store.dispatch;
	dispatch(unlockedWindow());

	const { data, status, method } = await errorMessageHandler(error);

	switch (status) {
		case 401:
			dispatch(showNotificationError(unauthorizedError(data?.code)));
			break;
		case 503:
			dispatch(showNotificationError(serviceUnavailable()));
			break;
		default:
			if (status < 500) {
				const contolledError = method === "delete" ? deleteControlledError(error) : createEditControlledError(error);
				dispatch(showControlledError({
					...contolledError,
					paramsLabels,
				}));
			} else {
				dispatch(showNotificationError(METHODS[method](error)));
			}
			break;
	}

	return data;
};

export const errorMessageHandler = async (error) => {
	let data = error?.response?.data;
	if (error?.request?.responseType === "blob") {
		data = await data.text();
		data = JSON.parse(data);
	}

	const status = error?.response?.status;

	return {
		data,
		status,
		method: status ? error?.config?.method : "default",
	};
};