import PropTypes from "prop-types";
import React, { useRef } from "react";
import { isFunction, omit, isEmpty } from 'lodash';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import {
	ActionsColumn,
	AvatarCell,
	ContractTypeColumn,
	EndContractColumn,
	StatusColumn,
	StructureColumn
} from "./Cells";
import GridNoRecordsRender from "../Templates/GridNoRecordsRender";
import { columnProps } from "../table/utils/GridCustomProps";
/* services */
import { areaAPI, branchesAPI, departmentAPI, employerRegistrationAPI, jobPositionsApi } from "../../services/enterprise-structure";
import { contractsAPI } from "../../services/contract";
import { CustomSelectCell, CustomSelectHeader } from '../table/CustomCells';
import Loader from "../table/Loader";
import { conditional } from '../table/CommandCell';
import { validFilter2 } from "./constants";
import { changeCallback, getExtras, isFromMopers, isVisible } from "./utilities";


const Table = ({
	loading,
	tableProps,
	noRenderDescription,
	init,
	showActions,
	onEdit,
	onDelete,
	onHistory,
	with_permission_user = true,
	onlyActives = false,
	forPayroll = false,
	customColumn,
	withCheckbox = true
}) => {

	const { t } = useTranslation();
	const structure = useSelector((state) => state.configuration.steps) ?? [];
	const gridRef = useRef();

	const { filter } = tableProps;

	const Actions = (props) => {
		return <ActionsColumn
			{...props}
			enterEdit={onEdit}
			enterDelete={onDelete}
			enterHistory={onHistory}
		/>;
	};

	const handleChange = (filters, callback, column) => {
		changeCallback(filters, callback, column, structure);
	};

	const getExtrasFilters = (column, filters) => {
		let extraFilters = { with_permission_user, ...isFromMopers() };
		return getExtras(column, filters, structure, extraFilters);
	};

	const isColumnVisible = (title) => {
		return isVisible(title, customColumn);
	};

	return (
		<>
			<Grid ref={gridRef} {...omit(tableProps, ["onSelectPage", "multiSelection"])} >
				<GridNoRecords>
					<GridNoRecordsRender init={init} filter={filter} initialMsg={noRenderDescription} />
				</GridNoRecords>
				{(isFunction(tableProps.onRowClick) && withCheckbox) && <GridColumn
					locked
					field={"selected"}
					width={50}
					headerCell={headerProps => (
						<CustomSelectHeader
							{...headerProps}
							multiSelection={tableProps.multiSelection}
							generalSelect={false}
							data={tableProps.data}
							onSelectPage={tableProps.onSelectPage}
						/>
					)}
					cell={cellProps => (
						<CustomSelectCell {...cellProps} onRowClick={tableProps.onRowClick} generalSelect={false} />
					)}
				/>}
				<GridColumn
					cell={cellProps => <AvatarCell {...cellProps} />}
					width={40}
				/>
				<GridColumn
					{...columnProps("key", filter, "input")}
					title={t("key")}
					width={70}
				/>
				<GridColumn
					{...columnProps("first_surname", filter, "input", { validationRules: { valueFormat: "alpha" } })}
					className="capitalize"
					title={t("first-surname")}
				/>
				<GridColumn
					{...columnProps("second_surname", filter, "input", { validationRules: { valueFormat: "alpha" } })}
					className="capitalize"
					title={t("second-surname")}
				/>
				<GridColumn
					{...columnProps("name", filter, "input", { validationRules: { valueFormat: "alpha" } })}
					title={t("name")}
				/>

				{(!isEmpty(customColumn) && isColumnVisible("employer-registration")) &&
					<GridColumn
						{...columnProps("employer_registration", filter, "serverdropdown", [
							employerRegistrationAPI.get, "id", "name",
							{
								searchBy: "search",
								filterEfields: "key|name",
								filterSplit: "-",
								format: "key|name",
								fields: "id,key,name",
								noDefault: true,
								changeCallback: (filters, callback) => handleChange(filters, callback, "employer_registration"),
								extraFilters: getExtrasFilters("employer_registration", filter)
							}]
						)}
						title={t("employer-registration")}
						cell={(cellProps) => <StructureColumn {...cellProps} column={"employer_registration"} />}
					/>
				}
				<GridColumn
					{...columnProps("branch", filter, "serverdropdown", [
						branchesAPI.get, "id", "name",
						{
							searchBy: "search",
							filterEfields: "key|name",
							filterSplit: "-",
							format: "key|name",
							fields: "id,key,name",
							noDefault: true,
							changeCallback: (filters, callback) => handleChange(filters, callback, "branch"),
							extraFilters: getExtrasFilters("branch", filter)
						}]
					)}
					title={t("branch")}
					cell={(cellProps) => <StructureColumn {...cellProps} column={"branch"} />}
				/>
				{(!isEmpty(customColumn) && isColumnVisible("area")) &&
					<GridColumn
						{...columnProps("area", filter, "serverdropdown", [
							areaAPI.get, "id", "name",
							{
								searchBy: "search",
								filterEfields: "key|name",
								filterSplit: "-",
								format: "key|name",
								fields: "id,key,name",
								noDefault: true,
								changeCallback: (filters, callback) => handleChange(filters, callback, "area"),
								extraFilters: getExtrasFilters("area", filter)
							}]
						)}
						title={t("area")}
						cell={(cellProps) => <StructureColumn {...cellProps} column={"area"} />}
					/>
				}

				{(!isEmpty(customColumn) && isColumnVisible("department")) &&
					<GridColumn
						{...columnProps("department", filter, "serverdropdown", [
							departmentAPI.get, "id", "name",
							{
								searchBy: "search",
								filterEfields: "key|name",
								filterSplit: "-",
								format: "key|name",
								fields: "id,key,name",
								noDefault: true,
								changeCallback: (filters, callback) => handleChange(filters, callback, "department"),
								extraFilters: getExtrasFilters("department", filter)
							}]
						)}
						title={t("department")}
						cell={(cellProps) => <StructureColumn {...cellProps} column={"department"} />}
					/>
				}

				<GridColumn
					{...columnProps("job_position", filter, "serverdropdown", [
						jobPositionsApi.get, "id", "name",
						{
							searchBy: "search",
							filterEfields: "key|name",
							filterSplit: "-",
							format: "key|name",
							fields: "id,key,name,rfc",
							noDefault: true,
							changeCallback: (filters, callback) => handleChange(filters, callback, "job_position"),
							extraFilters: getExtrasFilters("job_position", filter)
						}]
					)}
					title={t("job-position")}
					cell={(cellProps) => <StructureColumn {...cellProps} column={"job_position"} />}
				/>
				<GridColumn
					{...columnProps("contract_type", filter, "serverdropdown", [
						contractsAPI.get, "id", "name",
						{
							searchBy: "search",
							filterEfields: "key|name",
							filterSplit: "-",
							format: "key|name",
							fields: "id,key,name",
							noDefault: true,
						}]
					)}
					title={t("contract-type")}
					cell={cellProps => <ContractTypeColumn {...cellProps} />}
					width={140}

				/>
				<GridColumn
					{...columnProps("contract", filter, "dateRange", {
						isRequired: false,
						startProps: { label: t("from") },
						endProps: { label: t("until") },
						switchProps: { show: false, }
					})}
					title={t("contract-end-date")}
					cell={cellProps => <EndContractColumn {...cellProps} forTable={true} />}
				/>
				{!onlyActives && <GridColumn
					{...columnProps("active", conditional(forPayroll, "none", filter), "dropdown", [validFilter2(), "value", "label"])}
					cell={cellProps => <StatusColumn {...cellProps} />}
					width={120}
					title={t("valid")}
				/>}
				{showActions &&
					<GridColumn
						title={t("actions")}
						width={100}
						cell={Actions}
						locked={true}
					/>}
			</Grid>
			<Loader loading={loading} gridRef={gridRef} />
		</>
	);
};

Table.propTypes = {
	customColumn: PropTypes.any,
	forPayroll: PropTypes.bool,
	init: PropTypes.any,
	loading: PropTypes.any,
	noRenderDescription: PropTypes.any,
	onDelete: PropTypes.any,
	onEdit: PropTypes.any,
	onHistory: PropTypes.any,
	onlyActives: PropTypes.bool,
	showActions: PropTypes.any,
	tableProps: PropTypes.shape({
		data: PropTypes.any,
		filter: PropTypes.any,
		multiSelection: PropTypes.any,
		onRowClick: PropTypes.any,
		onSelectPage: PropTypes.any
	}),
	withCheckbox: PropTypes.bool,
	with_permission_user: PropTypes.bool
};

export default Table;