import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { debounce, first, isArray, isEmpty, isFunction, isNumber, isObject, isString, last, omit, size, toString, uniq } from "lodash";
import { copyObject, initialValues, initializeRole, mainItems, stepItemsBase, validateComponentShow, workerName } from "../utils/utilities";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaEdit } from "../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { resolveError } from "../../../../../general/@components/requests/resolve-error";
import { showSuccessNotification } from "../../../../../general/@components/Notifications";
import { successCreated } from "../../../../../general/@components/Notifications/notification-messages";
import { isAbrhil } from "../../../../../general/@components/general/auth";
import { usersApi } from "../../../../../general/services/administrator";
import { usePackages } from "../../../../../general/@hooks/usePackages/usePackages";
import { t } from "i18next";
import { noSpaces } from "../../../../../general/@components/form/utilities";
import { workersApi } from "../../../../../general/services/worker";
import { lockedWindow, showNotificationWarning, unlockedWindow } from "../../../../../../store/actions";
import { hasValue } from "../../../../../general/@components/general/GeneralUtilities";
import useServerController from "./ServerController";
import useGetLoginMode from "./useGetLoginMode";
import {
    attendancePayload,
    calculateUserType,
    deleteParams,
    groupsPayload,
    paramsDelete,
    systemPayload,
    treatItem,
    validateParams,
} from "../utils/constants";
import { permsGate } from "../../../../../general/@components/navigation/utilities";
import useRequestLoad from "../../../../../general/services/useRequestLoad";
import { currentUserInfo } from "../../../../../general/@components/requests/user-store";
import { DialogContext } from "../contexts";

const validate = { shouldValidate: true };

function useDialogController({ type, refresh, dataItem, onClose: close } = {}) {

    const { resetConfigs } = usePackages();
    const dispatch = useDispatch();

    const inAttendance = type === 'attendance';
    const canAssignRoles = permsGate('user-and-roles@users.assign-roles');
    const canWrite = permsGate(`${!inAttendance ? 'user-and-roles@users' : 'modules@users-list'}.write`);
    const { current } = useSelector(state => state.tenant);
    const structure = useSelector((state) => state?.configuration?.steps)?.slice(0, -3)?.map(el => el.step) ?? [];

    const [isEdit, setIsEdit] = useState(false);
    const [required, setRequired] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [initValues, setInitValues] = useState(initialValues(inAttendance, canAssignRoles));
    const [mainTreatedItems, setMainTreatedItems] = useState([]);
    const [finished, setFinished] = useState();
    const [success, setSuccess] = useState();
    const [disabled, setDisabled] = useState(false);
    const [direction, setDirection] = useState(1);
    const [formData, setFormData] = useState(null);
    const [stepItems, setStepItems] = useState([]);
    const [responseError, setResponseError] = useState();
    const [viewName, setViewName] = useState(null);
    const [srcImage, setSrcImage] = useState(null);
    const [workerSelected, setWorkerSelected] = useState(null);

    // Roles
    const [roleIndex, setRoleIndex] = useState(null);
    const [roleInModules, setRoleInModules] = useState(null);
    const [currentInfo, setCurrentInfo] = useState(null);
    const [showCurrentInfo, setShowCurrentInfo] = useState(false);
    const [currentGroup, setCurrentGroup] = useState({});
    const [showGroupInfo, setShowGroupInfo] = useState(false);

    const stepitemsBackup = useRef(null);
    const lastKey = useRef(null);

    const [fetchEmail, emailLoading] = useRequestLoad();
    const [fetchUsername, usernameLoading] = useRequestLoad();

    const loginMode = useGetLoginMode();
    const isSSO = loginMode === 2;

    const definedItems = mainItems(inAttendance, canAssignRoles, loginMode);
    const initialHistoric = definedItems.find(el => el.key === 'user')?.path;
    const [historic, setHistoric] = useState([]);
    const currentPath = last(historic);
    const currentItem = definedItems?.find(el => currentPath && (el.path === currentPath));
    const currentSchema = currentItem?.schema;
    const currentElement = currentItem?.open;
    const currentStep = Math.max(stepItems?.findIndex(el => el.path === currentPath || currentPath?.includes(el.path)), 0);
    const isLastStep = currentStep === stepItems.length - 1;
    const isAboveInitial = currentStep > 0;

    const [loadingMenu, setLoadingMenu] = useState(true);

    const {
        control,
        reset,
        trigger,
        setError,
        setValue,
        getValues,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: initValues,
        ...((isEdit || currentSchema) ? {
            resolver: yupResolver(isEdit ? ValidationSchemaEdit(loginMode, canAssignRoles) : currentSchema)
        } : {})
    });

    const serverData = useServerController({ type, open, selected, setValue });

    const {
        loading,
        extraPerms,
        rolesData,
        tenants,
    } = serverData;

    const itemsDatasource = {
        0: null,
        1: null,
        2: !inAttendance ? rolesData : null,
        3: null,
        4: tenants,
    };

    useEffect(() => {
        resetMainMenu();
        if (open) {
            setHistoric(!isEdit ? [initialHistoric] : []);
            setTimeout(() => {
                setLoadingMenu(false);
            }, 400);
        }
    }, [open, type, isEdit])

    useEffect(() => {
        if (!open || !size(rolesData) || isEdit) return;
        initializeSystem();
    }, [open, rolesData])

    useEffect(() => {
        if (!size(dataItem)) return;
        handleOpen(dataItem);
    }, [dataItem])

    useEffect(() => {
        if (open) return;
        const newInit = {
            ...initValues,
            ...initialValues(inAttendance, canAssignRoles),
            tenants: [
                { id: current?.id, name: current?.name }
            ]
        };
        setInitValues(newInit);
        reset({ ...newInit });
    }, [current?.id, open])

    useEffect(() => {
        if (open) {
            if (isEdit) {
                validateItems(mainTreatedItems, errors);
            }
            document.addEventListener('keyup', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keyup', handleKeyDown);
        }
    }, [open, currentPath])

    useEffect(() => {
        const steps = stepItemsBase(definedItems).map(el => {
            const show = validateComponentShow(el, itemsDatasource, inAttendance, selected, true);
            if (show) return { label: el.label, isValid: undefined, step: el.step, fields: el?.fields, key: el.key, path: el.path };
        }).filter(el => el !== undefined && isNumber(el?.step)).sort((a, b) => a.step - b.step);
        stepitemsBackup.current = steps;
        setStepItems(steps);
    }, [tenants, type])

    useEffect(() => {
        const options = definedItems.map(el => {
            const show = validateComponentShow(el, itemsDatasource, inAttendance, selected, false);
            if (show) return el;
        }).filter(el => el !== undefined).sort((a, b) => { return a.menu_order - b.menu_order; });
        setMainTreatedItems(options);
    }, [tenants, selected, type])

    useEffect(() => {
        if (responseError && open) {
            validateResponseError(responseError);
        }
    }, [responseError])

    const handleKeyDown = (e) => {
        const { keyCode } = e;
        const allowedKeys = [27];
        if (!allowedKeys.includes(keyCode)) return;
        if (keyCode === 27) {
            if (currentPath && isEdit) {
                handleReturn();
            } else {
                handleClose();
            }
        }
    };

    const initializeSystem = () => {
        if (!open || !isAbrhil()) return;
        const systemRole = rolesData.find(el => el.config?.system_role);
        if (!systemRole) return;
        const systemRoleTreated = initializeRole(systemRole, getValues);
        systemRoleTreated.saved = true;
        setValue('groups_perms', [systemRoleTreated]);
    }

    function resetMainMenu() {
        const newItems = mainTreatedItems.map(el => {
            el.errorClass = '';
            return el;
        });
        setMainTreatedItems(newItems);
    }

    function handleOpen(item) {
        setRequired(true);
        setSelected(item);
        if (item) {
            setIsEdit(true);
            const cleanItem = treatItem(copyObject(item), getValues);
            const userData = { ...initValues, ...cleanItem };
            setWorkerSelected(userData.worker);
            setRequired(false);
            reset(userData);
        }
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setDirection(1);
        setHistoric([]);
        setIsEdit(false);
        setSrcImage(null);
        setSelected(null);
        setLoadingMenu(true);
        setCurrentGroup(null);
        setCurrentInfo(null);
        setRoleIndex(null);
        setStepItems(stepitemsBackup.current);
        reset(initialValues(inAttendance, canAssignRoles));
        clearErrors();
        resetMenu();
        if (isFunction(close)) {
            close();
        }
    }

    function validateResponseError(error) {
        let errorFiltered = isArray(error) ? error[0] : error;
        if (isObject(error)) {
            errorFiltered = Object.keys(error)[0];
        }
        if (isString(errorFiltered)) {
            errorFiltered = errorFiltered.toLowerCase();
        }
        const messageError = isObject(error) ? Object.values(error)[0] : errorFiltered;
        if (isString(messageError)) {
            if (messageError.length > 200) {
                return;
            }
        }
        mainTreatedItems.forEach(el => {
            el.fields.forEach(li => {
                if (errorFiltered.includes(li) || errorFiltered.includes(t(li).toLowerCase())) {
                    if (!isEdit) {
                        updateSteps(true, false);
                    }
                    if (li !== 'worker') {
                        setError(li, { type: 'custom', message: messageError });
                    } else {
                        setError('worker.key', { type: 'custom', message: messageError });
                        setError('worker.id', { type: 'custom', message: messageError });
                    }
                    if (isEdit) {
                        validateItems(mainTreatedItems, errors);
                    }
                }
            })
        });
    }

    const onSubmit = async (data) => {
        onSubmitError({});
        if (!isEdit) {
            if (isLastStep) {
                setFinished(false);
                updateSteps(true, true);
                setDisabled(true);
                finalSubmit({ ...formData, ...data });
            } else {
                setDirection(-1);
                setFormData((prevData) => ({ ...prevData, ...data }));
                const nextFocused = stepItems[currentStep + 1]?.path;
                if (nextFocused) {
                    handlePushPath(nextFocused);
                }
            }
        } else {
            setFinished(false);
            setDisabled(true);
            finalSubmit(data);
        }
    }

    const onSubmitError = (errors) => {
        if (size(errors)) {
            setDisabled(true);
            setFinished(true);
            setSuccess(false);
        }
        if (isEdit) {
            validateItems(mainTreatedItems, errors);
        } else {
            validateStep(currentItem, errors);
        }
        if (size(errors)) {
            setTimeout(() => {
                setFinished(undefined);
                setSuccess(undefined);
                setDisabled(false);
                if (currentPath) {
                    const goMenu = mainTreatedItems.find(el => el.errorClass);
                    const actualItem = mainTreatedItems.find(el => el.path === currentPath);
                    if (actualItem?.errorClass === '' && goMenu) {
                        resetMenu();
                    }
                }
            }, 600);
        }
    }

    const updateSteps = (schemaErrors, value) => {
        const valueSent = value !== undefined ? value : undefined;
        let valueSet = valueSent === null ? undefined : !schemaErrors;
        if (valueSent !== undefined) {
            valueSet = valueSent;
        }
        setStepItems(prevState => {
            const pathUse = first(currentPath?.split('/'));
            return prevState.map((currentStep) => ({
                ...currentStep,
                isValid: currentStep.path === pathUse ? valueSet : currentStep.isValid
            }));
        });
    };

    function buildPayload(request) {
        request.username = request.username === "" ? null : request.username;
        request.email = request.email === "" ? null : request.email;
        request.list_group = request.attendance;
        request.worker = request?.worker?.id;
        request.tenants = size(request?.tenants) ? request.tenants.map(el => el.id) : [current?.id];
        if (!isAbrhil()) { delete request.tenants; }
        request = validateParams(request, ['external_name', 'password', 'password2']);
        request.groups_perms = groupsPayload(request.groups_perms, inAttendance);
        request.type_user = calculateUserType(request, inAttendance);
        request = deleteParams(request, paramsDelete);
        request = attendancePayload(request, inAttendance);
        request = systemPayload(request, inAttendance);
        return request;
    }

    function validateItems(items, errors) {
        const newItems = items.map(el => {
            const noValid = validateStep(el, errors);
            el.errorClass = noValid ? 'error-main-item' : '';
            return el;
        });
        setMainTreatedItems(newItems);
    }

    async function generalRequest(body) {
        const api = isEdit ? usersApi.patch : usersApi.post;
        const params = isEdit ? [body.id, body] : [body];
        try {
            await api(...params);
            refreshConfigs(body);
            return true;
        } catch (error) {
            resolveError(error);
            setDisabled(false);
            const filteredError = error?.response?.data?.detail ?? error?.response?.data;
            setResponseError(filteredError);
            return false;
        }
    }

    const refreshConfigs = (newConfigs) => {
        const currentUser = currentUserInfo()?.user_info;
        if (inAttendance || !isEdit || (currentUser?.id !== newConfigs?.id) || !newConfigs?.id) return;
        const updatedConfigs = omit(newConfigs?.extra_perms, 'id');
        resetConfigs(updatedConfigs);
    }

    const finalSubmit = async (data) => {
        setResponseError(null);
        const dataSend = copyObject(data);
        const body = buildPayload(dataSend);
        const response = await generalRequest(body);
        setDisabled(false);
        setSuccess(response);
        setFinished(true);
        if (response) {
            setTimeout(() => {
                if (isFunction(refresh)) {
                    refresh();
                }
                setDisabled(false);
                if (currentPath && isEdit) {
                    resetMenu();
                } else {
                    handleClose();
                }
                if (!isEdit) {
                    showSuccessNotification(successCreated());
                }
            }, 600);
        }
        setTimeout(() => {
            setFinished(undefined);
            setSuccess(undefined);
        }, 1000);
    }

    function validateStep(item, errors) {
        const totalData = item?.fields;
        if (item.path?.includes('/')) {
            const parentPath = last(item.path?.split('/')?.slice(0, -1));
            const newItem = definedItems.find(el => el.path === parentPath);
            return validateStep(newItem, errors);
        }
        const builtData = { value_items: totalData, datasource: itemsDatasource[item?.step] };
        return validateValues(builtData, errors);
    }

    function validateValues({ value_items, datasource }, errors) {
        value_items = value_items?.filter(el => isString(el));
        let schemaErrors = value_items?.some(el => errors?.[el]);
        if (schemaErrors) {
            let validDataSource = datasource !== null ? size(datasource) : null;
            if ((validDataSource > 0 && validDataSource !== null) || validDataSource === null) {
                schemaErrors = true;
            } else {
                value_items?.forEach(el => clearErrors(el));
                schemaErrors = false;
            }
        }
        updateSteps(schemaErrors);
        return schemaErrors;
    }

    function resetMenu() {
        setViewName(null);
        setHistoric([]);
    }

    const handleBack = () => {
        handleReturn();
        updateSteps(true, null);
        setDirection(1);
    };

    const handleClick = (item) => {
        customEnterCallbacks(item);
        handlePushPath(item?.path);
        setViewName(item?.name);
    }

    const handleReturn = () => {
        customReturnCallbacks(currentItem);
        handlePopPath();
    }

    const handlePushPath = path => path && setHistoric(prev => uniq([...prev, path]));

    const handlePopPath = () => setHistoric(prev => (prev.slice(0, -1)));

    const updateHeaderInfo = (info, icon, classname) => setCurrentInfo({ info, icon, class: classname });

    const validateEmail = useCallback(async (email) => {
        if (email === '' || !email.includes('@')) return;
        if (errors?.email) return;
        fetchEmail({
            api: usersApi.validateEmail({ email }),
            onFailed: () => null,
            callback: ({ valid } = {}) => {
                let emailResponse = !valid && getValues('initial_email') !== email ? email : undefined;
                setValue('existingEmail', emailResponse, validate);
                trigger('email');
            }
        });
    }, [errors]);

    const validateUser = useCallback(async (username) => {
        if (errors?.username || username === '') return;
        const usernameFilter = { username, tree: true };
        fetchUsername({
            api: usersApi.get(usernameFilter),
            callback: ([found] = []) => {
                let usernameResp = found?.username;
                if (getValues('initial_username') === usernameResp) {
                    usernameResp = undefined;
                }
                setValue('existingUser', usernameResp, validate);
                trigger('username');
            }
        });
    }, [errors]);

    const debounceEmailCheck = useRef(debounce(validateEmail, 100)).current;
    const debounceUsernameCheck = useRef(debounce(validateUser, 200)).current;

    const onEmailChange = useCallback((e) => {
        debounceEmailCheck(e.value);
        return noSpaces(e);
    }, [errors]);

    const onUsernameChange = useCallback((e) => {
        debounceUsernameCheck(e.value);
        return noSpaces(e);
    }, [errors]);

    const onNew = () => handleOpen();

    const onClose = () => handleClose();

    function saveWorker(worker) {
        setValue(`worker.key`, worker.key, validate);
        setValue(`worker.name`, worker.name, validate);
        setValue(`worker.first_surname`, worker.first_surname, validate);
        setValue(`worker.second_surname`, worker.second_surname, validate);
        setValue(`worker.id`, worker.id, validate);
        setValue(`external_name`, "");
        const fullName = workerName(worker);
        setValue(`worker.full_name`, fullName, validate);
        setSrcImage(isEmpty(worker.photo) ? "" : worker.photo?.full_size);
    }

    const handleSelectWorker = (worker) => {
        saveWorker(worker);
        setWorkerSelected(worker);
    }

    const getEmployee = async (key) => {
        if (key === lastKey) return;
        if (key === '') return dontSaveWorker(false);
        lastKey.current = key;
        dispatch(lockedWindow());
        try {
            const params = {
                tenant: current?.id,
                worker: key,
                select: true,
                tree: true,
                all: true,
                with_permission_user: false,
            };
            const response = await workersApi.get(params);
            if (response.length > 0) {
                const worker = response[0];
                handleSelectWorker(worker);
            } else {
                setValue('worker', null);
                dispatch(showNotificationWarning({ message: `No se encontraron coincidencias para la clave ${key}.` }));
            }
        }
        catch (error) {
            resolveError(error);
        }
        finally {
            dispatch(unlockedWindow());
        }
    }

    const debounceWorker = useRef(debounce(getEmployee, 1000)).current;

    const onWorkerChange = (event) => {
        debounceWorker(event.value);
        return noSpaces(event);
    }

    const handleKeyPress = ({ charCode, key, target, type }) => {
        if ((charCode === 13 || (key ?? "").toString().toLowerCase() === "enter") || (type === "blur" && toString(workerSelected?.key) !== target?.value)) {
            if (hasValue(target?.value)) {
                getEmployee(target.value);
            }
        }
    };

    function handleInternalUser(e) {
        dontSaveWorker();
        return e;
    }

    const passwordValidation = e => {
        e = noSpaces(e);
        return e;
    }

    function dontSaveWorker(clear = true) {
        setValue(`worker.key`, "");
        setValue(`worker.name`, "");
        setValue(`worker.first_surname`, "");
        setValue(`worker.second_surname`, "");
        setValue(`worker.full_name`, "");
        setValue(`worker.id`, null);
        setValue(`external_name`, "");
        setValue(`worker.photo`, {});
        setSrcImage(null);
        setWorkerSelected(null);
        clear && clearErrors(['worker']);
    }

    const validateRoleAssign = () => {
        if (!canAssignRoles) {
            dispatch(showNotificationWarning({
                message: 'Sin permisos de asignar roles',
                description: 'Este permiso es necesario para realizar esta acción'
            }));
        }
        return canAssignRoles;
    }

    const customReturnCallbacks = (item) => {
        const key = item?.key;
        const callback = {
            branches: () => {
                setRoleIndex(null);
            },
            groups: () => {
                setShowCurrentInfo(false);
                setShowGroupInfo(false);
            }
        }[key];
        if (callback) callback();
    }

    const customEnterCallbacks = (item) => {
        const key = item?.key;
        const callback = {
            groups: () => {
                setShowCurrentInfo(true);
                setShowGroupInfo(true);
            }
        }[key];
        if (callback) callback();
    }

    return {
        control,
        open,
        onNew,
        stepItems,
        isEdit,
        onClose,
        errors,
        rolesData,
        structure,
        extraPerms,
        currentPath,
        currentItem,
        definedItems,
        currentStep,
        historic,
        required,
        selected,
        finished,
        disabled,
        direction,
        roleInModules,
        currentElement,
        canAssignRoles,
        isAboveInitial,
        loadingMenu,
        isLastStep,
        canWrite,
        viewName,
        success,
        tenants,
        current,
        loading,
        reset,
        trigger,
        setError,
        setValue,
        getValues,
        showGroupInfo,
        setShowGroupInfo,
        showCurrentInfo,
        setShowCurrentInfo,
        onSubmit,
        handleReturn,
        handleBack,
        setViewName,
        handleClose,
        handleSubmit,
        onSubmitError,
        setCurrentInfo,
        setCurrentGroup,
        setRoleInModules,
        validateRoleAssign,
        updateHeaderInfo,
        handlePushPath,
        handlePopPath,
        srcImage,
        currentGroup,
        currentInfo,
        handleClick,
        emailLoading,
        usernameLoading,
        roleIndex,
        setRoleIndex,
        mainTreatedItems,
        onEmailChange,
        onUsernameChange,
        passwordValidation,
        handleSelectWorker,
        onWorkerChange,
        handleKeyPress,
        workerSelected,
        handleInternalUser,
        inAttendance,
        loginMode,
        isSSO,
    };
};

export const useDialogContext = () => useContext(DialogContext);

export default function DialogController({
    children,
    ...others
}) {
    const dialogValues = useDialogController(others);

    return (
        <DialogContext.Provider value={dialogValues}>
            {children}
        </DialogContext.Provider>
    );
};

DialogController.propTypes = {
    children: PropTypes.any,
}