import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
//components
import { workersApi } from "../../../services/worker";
import { parseRequest } from "../../requests/parse-request";
import { currentTenant, hasErrorTetant } from "../../requests/validate-tenant";
import Modal from '../../Inputs/search/Worker/Modal';
import { showNotificationWarning } from "../../../../../store/actions";
import Input from "../../Inputs/search/Input";
import { debounce, isFunction, isString } from "lodash";
import { resolveError } from "../../requests/resolve-error";
import { getUrlParam, setSearchParam } from "../../../@hooks/useReloadWorker";
import { uid } from "uid";
import { isLetter, onlyLetters } from "../../Inputs/search/SearchField";
import { getFieldsRequest } from "./RequestSearch";

function SearchWorker({
    edit,
    disabled,
    label,
    onChange,
    onWorkerSearch,
    onKeyPress,
    refreshCounter,
    size,
    worker = {},
    setWorker = () => { },
    extraFilters = {},
    paramName = 'worker',
    withParam = true,
    searchOnChange = false,
    userWorkgroups = false,
    resetInUnmount = true,
    toList = false,
    className = "",
    style = {},
    onlyActives = false,
    onlyId = false,
    withModal = true,
    extraParams = {}
}) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { worker: extWorker } = location?.state ?? {};

    const [key, setKey] = useState(null);
    const [name, setName] = useState(null);
    const [selected, setSelected] = useState({});
    const [loading, setLoading] = useState(false);
    const [openDialogForm, setOpenDialogForm] = useState(false);
    const urlKey = parseInt(getUrlParam(paramName ?? "worker"));

    const clearing = useRef(false);
    const workerKey = useRef(null);

    useEffect(() => {
        return () => {
            if (!resetInUnmount || toList) return;
            updateWorker(null);
        };
    }, []);

    useEffect(() => {
        if (toList || !withParam) return;
        if ((!isNaN(urlKey) && urlKey !== parseInt(workerKey.current)) || refreshCounter) {
            searchWorkerByKey(urlKey);
        }
    }, [urlKey, refreshCounter]);

    useEffect(() => {
        if (!extWorker || toList) return;
        const extKey = parseInt(extWorker);
        searchWorkerByKey(extKey);
    }, [extWorker]);

    // *** Actualiza key ***//
    useEffect(() => {
        if (toList) {
            setKey(worker?.key);
            return;
        }
        if (worker?.key) {
            setKey(worker?.key);
            setSelected(worker);
            return;
        }
        setKey("");
        setSelected({});
    }, [worker?.key]);

    const updateWorker = (value, nav = true) => setSearchParam({ [paramName]: value }, nav ? navigate : null);

    // *** Reinicia el valor de worker y muestra una notificacion *** //
    function validateWorker() {
        if (withParam) updateWorker(null);
        setWorker(null);
        let message = 'El colaborador no pertenece a algún grupo de trabajo asignado a usted o no existe';

        if (!userWorkgroups) {
            message = 'El colaborador no existe o no se encuentra asignado a su usuario';
        }
        dispatch(showNotificationWarning({
            maxWidth: 'sm',
            title: 'Atención',
            message,
        }));
    }

    // *** Seleccion de colaborador ***//

    const getKey = value => {
        if (isString(value)) {
            return value?.replace(/\D/g, '');
        }
        return value;
    };

    const handleSelectWorker = (selection) => {
        handleCloseDialogForm();
        searchWorkerByKey(selection);
    };

    const handleOpenDialogForm = () => {
        setOpenDialogForm(true);
    };

    const handleCloseDialogForm = () => {
        setOpenDialogForm(false);
    };

    // *** Busqueda manual de colaborador ***//

    const validateValue = value => {
        const justLetters = onlyLetters(value);
        const show = isLetter(justLetters?.[0]);
        if (!show || !withModal) return;
        setName(justLetters);
        handleOpenDialogForm();
    };

    const handleOnChangeKey = (e) => {
        validateValue(e.value);
        const value = getKey(e.value);
        setKey(value);
        if (!e.value) {
            searchWorkerByKey(value);
        } else if (searchOnChange) {
            debounceSearch(value);
        }
        if (isFunction(onChange)) {
            onChange(value);
        }
    };

    const handleOnKeyPress = (e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            e.preventDefault();
            searchWorkerByKey(key);
            if (isFunction(onKeyPress)) {
                onKeyPress(key);
            }
        }
    };

    const onClear = (value) => {
        const key = getKey(value);
        clearing.current = true;
        setKey(key);
        searchWorkerByKey(key);
        resetClearing();
    };

    const resetClearing = useRef(debounce(() => clearing.current = false, 250)).current;

    const searchWorkerByKey = async (keyToSearch) => {
        workerKey.current = keyToSearch;
        if (keyToSearch) {
            setLoading(true);
            try {
                let params = parseRequest({
                    tenant: currentTenant(),
                    include: getFieldsRequest(paramName),
                    ...(onlyId ? { fields: "id,key" } : {}),
                    ...(userWorkgroups ? { from_mopers: true } : {}),
                    worker: keyToSearch,
                    ...extraFilters,
                    limit: 1,
                    attendance: paramName === 'kardex_worker'
                });

                const response = await workersApi.get(params);
                let responseMatch = response.results[0];

                if (isFunction(onWorkerSearch)) {
                    onWorkerSearch(responseMatch);
                }
                if (responseMatch) {
                    if (toList) {
                        return;
                    }
                    if (!withModal) {
                        setWorker({ ...responseMatch });
                        return;
                    }
                    setWorker({ ...responseMatch });
                    if (withParam) {
                        updateWorker(responseMatch.key);
                    }
                } else {
                    validateWorker();
                }
            } catch (error) {
                resolveError(error);
                workerKey.current = null;
                if (withParam) updateWorker(null);
            } finally {
                setLoading(false);
            }
            return;
        }
        setSelected({});

        if (toList && isFunction(onWorkerSearch)) {
            onWorkerSearch({});
        };
        if (withParam) updateWorker(null);
        setLoading(false);
        setWorker(null);
    };

    const debounceSearch = useRef(debounce(searchWorkerByKey, 400)).current;

    const handleBlur = useCallback(debounce((e) => {
        if (e.target.value !== worker?.key && !clearing.current) {
            setKey(worker?.key);
        }
    }, 200), [worker, clearing.current]);

    return (
        <>
            <Modal
                openDialogForm={openDialogForm}
                handleCloseDialogForm={handleCloseDialogForm}
                handleSelect={handleSelectWorker}
                selected={selected.key}
                initSearchValue={name}
                setSelected={setSelected}
                extraFilters={{ ...(userWorkgroups ? { from_mopers: true, ...extraFilters } : { ...extraFilters }) }}
                onlyActives={onlyActives}
                includeTo={getFieldsRequest(paramName)}
                extraParams={extraParams}
            />
            <Input
                className={className}
                id={`search-key-input-${uid()}`}
                label={label ?? t("key")}
                name="key"
                disabled={hasErrorTetant() || loading || edit || disabled}
                value={key}
                onChange={handleOnChangeKey}
                onKeyDown={handleOnKeyPress}
                isLoading={loading}
                onBlur={handleBlur}
                {...(withModal ? { onSearch: handleOpenDialogForm } : {})}
                autoFocus
                onClear={(e) => onClear(e.value)}
                inputProps={{ maxLength: 11 }}
                size={size}
                InputProps={{
                    style: {
                        backgroundColor: 'var(--backgroundText)',
                        ...style
                    }
                }}
            />
        </>
    );
}

SearchWorker.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    edit: PropTypes.bool,
    extraFilters: PropTypes.object,
    extraParams: PropTypes.object,
    label: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onWorkerSearch: PropTypes.func,
    onlyActives: PropTypes.bool,
    onlyId: PropTypes.bool,
    paramName: PropTypes.string,
    refreshCounter: PropTypes.number,
    resetInUnmount: PropTypes.bool,
    searchOnChange: PropTypes.bool,
    setWorker: PropTypes.func,
    size: PropTypes.any,
    style: PropTypes.object,
    toList: PropTypes.bool,
    userWorkgroups: PropTypes.bool,
    withModal: PropTypes.bool,
    withParam: PropTypes.bool,
    worker: PropTypes.any
};

export default SearchWorker;
