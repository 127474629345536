import { useState } from "react";
import { implementService } from "./implemet-service";
import { isFunction } from "lodash";
import { resolveError } from "../@components/requests/resolve-error";

/**
 * @typedef {Object} FetchOptions
 * @property {Promise<any>} api - Promesa con la petición
 * @property {(response: any) => void|Promise<void>} callback - Callback cuando la petición es exitosa
 * @property {(error: any) => void} [onFailed] - Callback cuando la petición falla
 * @property {any} [paramLabels] - Parámetros adicionales
 * @property {boolean} [waitCallback] - Esperar a que el callback termine antes de continuar
 * @property {boolean} [load] - Indica si se debe activar el estado de carga
 */

/**
 * @callback FetchFunction
 * @param {FetchOptions} options
 * @returns {Promise<void>}
 */

/**
 * Hook personalizado para manejar la carga de datos y el estado de carga.
 *
 * @param {boolean} [initialState=false] - El estado inicial del indicador de carga.
 * @returns {[FetchFunction, boolean, React.Dispatch<React.SetStateAction<boolean>>]} - [Función para hacer el request, State de la carga de la petición, setState de el estado de carga manual]
 */
const useRequestLoad = (initialState = false) => {
    const [loading, setLoading] = useState(initialState);

    /** @type {FetchFunction} */
    const getData = async ({
        api,
        callback,
        onFailed,
        paramLabels,
        waitCallback = true,
        load = true
    }) => {
        if (load) setLoading(true);
        return await implementService(
            api,
            (resp) => onCallback(resp, callback, waitCallback),
            (err) => onError(err, onFailed),
            true,
            paramLabels,
            false
        );
    };

    /**
     * @param {any} resp
     * @param {(resp: any) => Promise<void>} customCall
     * @param {boolean} waitCallback
     * @returns {Promise<any>}
     */
    const onCallback = async (resp, customCall, waitCallback) => {
        if (isFunction(customCall)) {
            if (waitCallback) {
                await customCall(resp);
            } else {
                customCall(resp);
            }
        }
        setLoading(false);
        return resp;
    };

    /**
     * @param {any} error
     * @param {(error: any) => void} [customError]
     */
    const onError = (error, customError) => {
        if (isFunction(customError)) {
            customError(error);
        } else {
            resolveError(error);
        }
        setLoading(false);
    };

    return [getData, loading, setLoading];
};

export default useRequestLoad;