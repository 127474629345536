import { faScrewdriverWrench, faUtensils } from "@fortawesome/pro-light-svg-icons";
import systemItems from './system';
import mirrorItems from './mirror';
import abrilItems from "./abrhil";
import trainingItems from './training';
import attendanceMainItems from './attendance-mopers-main';
import attendanceItems from './attendance-mopers';
import imssItems from './imss';
import cultureMainItems from './culture-people-main';
import cultureItems from './culture-people';
import timbrasoftItems from './timbrasoft';
import payrollItems from './payroll';
import kioskoItems from "./kiosko";
import clockItems from './clock';
import kpiItems from './kpis';


//* Al agregar o actualizar elementos es de suma importancia que el id se construya
//* de la siguiente forma: '{padre raiz}@{item id del backend}'

//* Al agregar un nuevo componente el backend debe de haber hecho el previo registro

//* En caso de actualizar el ID de un componente al que se navegue mediante navigate en el sistema
//* por ejemplo: const path = getNavItem(padre@hijoID) -> navigate(path)
//* se debe actualizar su referencia (padre@nuevoID) al llamar a la funcion getNavItem la cual se encarga
//* de recuperar el path del elemento en el menu de REDUX dinamico mediante su ID

const menuElements = [
	...systemItems,
	...mirrorItems,
	...abrilItems,
	...trainingItems,
	...attendanceMainItems,
	...attendanceItems,
	...imssItems,
	...cultureMainItems,
	...cultureItems,
	...timbrasoftItems,
	...payrollItems,
	...kioskoItems,
	...clockItems,
	...kpiItems,

	//En construccion
	{
		id: "modules@dining-room",
		type: "item",
		icon: faUtensils,
		inConstruction: true,
		componentElm: true
	},
	{
		id: 'modules@timbrasoft-catalogs',
		type: 'item',
		package_permissions: ["payroll_package"],
		inConstruction: true,
		componentElm: true,
	},
	{
		id: 'modules@bans',
		type: 'item',
		inConstruction: true,
		componentElm: true
	},

	{
		id: 'modules@manage-corporate',
		type: 'item',
		inConstruction: true,
		componentElm: true
	},

	{
		id: 'modules@box',
		type: 'item',
		inConstruction: true,
		componentElm: true
	},
	{
		id: 'modules@cash',
		type: 'item',
		inConstruction: true,
		componentElm: true
	},
	{
		id: 'modules@billing-companies',
		type: 'item',
		inConstruction: true,
		componentElm: true
	},
	{
		id: 'modules@config-report-stamp',
		type: 'item',
		inConstruction: true,
		componentElm: true,
		//componentElm: <ConfigReportStamp />
	},
	{
		id: 'modules@nationalities-mirror',
		type: 'item',
		inConstruction: true,
		componentElm: true
	},

	//* UTILERIAS *//
	{
		id: "utils",
		icon: 'config-icon',
		type: "item",
		inConstruction: true,
		componentElm: true
	},

	//* HERRAMIENTAS *//
	{
		id: "tools",
		icon: faScrewdriverWrench,
		type: "item",
		inConstruction: true,
		componentElm: true
	}
];

export default menuElements;