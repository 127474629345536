import React from 'react';
import PropTypes from 'prop-types';
import { CustomTooltip } from '../../../../../../../../../general/@components/Templates/cells';
import { isFunction } from 'lodash';
import '../../../../../styles/style.scss';

function Flag({
    title,
    onClick,
    position = '',
    color = '',
}) {
    const isButton = isFunction(onClick);

    return (
        <CustomTooltip title={title}>
            <button
                onClick={onClick}
                className={`mopers-flag-indicator ${position} ${color} ${isButton ? "pointer-cursor" : ""}`}
            />
        </CustomTooltip>
    );
}

export default Flag;

Flag.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    position: PropTypes.string,
    color: PropTypes.string,
};