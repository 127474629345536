import Catalog from "./catalog.svg";
import Schedule from "./schedule.svg";
import Constance from "./constance.svg";
import Excel from "./Excel.svg";
import Dc3Icon from "./Dc3_Icon.svg";
import Star from "./star.svg";
import Verification from "./verification.svg";
import Attendance from "./attendance-list.svg";
import Clear from "./clear.svg";
import Dc3Text from "./dc3_text.svg";
import Cirse from "./cirse_text.svg";
import Check from "./Check.svg";
import Grades from "./Grades.svg";
const Courses = {
	catalog: Catalog,
    schedule: Schedule,
    constance: Constance,
    excel: Excel,
    dc3icon: Dc3Icon,
    star: Star,
    verification: Verification,
    attendanceparticipants: Attendance,
    clear: Clear,
    dc3text: Dc3Text,
    cirse: Cirse,
    checktraining: Check,
    grades: Grades
};

export default Courses;