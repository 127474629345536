import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { each, forEach, size, isFunction, first, omit } from 'lodash';
import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid2";
import FormDialog from "../../../modal/dialog/FormDialog";
import { workersApi } from "../../../../services/worker";
import { parseRequest } from "../../../requests/parse-request";
import { pageable, selectedItem } from "../../../table/utils/GridCustomProps";
import { hasErrorTetant } from "../../../requests/validate-tenant";
import { showNotificationWarning } from "../../../../../../store/actions";
import { errorTenant } from "../../../Notifications/notification-messages";
import Table from "../../../employees/Table";
import { tableFields, include, getPrefix } from "../../../employees/constants";
import useController, { getTitles } from "../ComonControls";
import Actions from '../Actions';
import { Container } from '../Container';
import { hasValue, valueOrOption } from '../../../general/GeneralUtilities';
import { ModalContentProps, ModalProps } from './propsDescriptions';
import { conditional } from '../../../table/CommandCell';
import WorkerHeaderButtons from "../../../employees/WorkerHeaderButtons";
import useRequestLoad from "../../../../services/useRequestLoad";
import { omitPayload } from "../../../employees/utilities";

export const ModalContent = ({
	onClose,
	handleCloseDialogForm,
	extraFilters,
	extraParams,
	initSearchValue,
	selectBy = "key",
	onlyActives = false,
	includeTo,
	forPayroll = false,
	extraFields = "",
	prefix = "",
	getApi,
	workers,
	multi,
	fromMopers = false,
	with_permission_user = true,
	includeData = false,
	setWorkersSelectedData = [],
	workersSelectedData = [],
	children,
	withoutActions = false,
	withFinishSelection = true,
	withAcceptButton = false,
	advanceFilterProps = {},
	height = "calc(100vh - 464px)",
	...others
}) => {

	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [init, setInit] = useState(false);
	const searchOnChange = useRef(true);
	const searchValue = useRef(null);
	const dispatch = useDispatch();
	const [performRequest, loading] = useRequestLoad();
	const [withCalculatedPeriod, setWithCalculatedPeriod] = useState(false);

	const handleClose = isFunction(onClose) ? onClose : handleCloseDialogForm;

	const getExtraFilters = () => {
		if (!hasValue(extraFilters)) {
			return {};
		}
		if (isFunction(extraFilters)) {
			return extraFilters();
		}
		return extraFilters;
	};

	const customFilters = {
		...getExtraFilters(),
		...conditional(onlyActives, { active: true }, {}),
		...conditional(fromMopers, { from_mopers: true }, {}),
		with_permission_user,
	};

	const getTableData = async (newPagination, newFilter) => {
		if (hasErrorTetant()) {
			dispatch(showNotificationWarning(errorTenant()));
			return;
		}
		setInit(true);

		let filters = newFilter?.filters ?? newFilter ?? {};

		const fieldPrefix = getPrefix(prefix).replace(".", "_");

		if (hasValue(prefix)) {
			let constructed = {};
			each(filters, (item, key) => {
				constructed[`${fieldPrefix}${key}`] = item;
			});
			filters = constructed;
		}

		if (!searchOnChange.current) {
			filters = {
				...omit(filters, ['active']),
				...(searchValue.current ? { search: searchValue.current, limit: 1 } : {}),
			};
		}

		if (forPayroll) {
			filters = {
				...filters,
				...((withCalculatedPeriod) ? { with_calculate: withCalculatedPeriod } : {}),
			};
		}

		const includes = include(conditional(forPayroll, "", prefix)).join(",");
		const basicfields = tableFields(conditional(forPayroll, "", prefix)).join(",");
		extraFields += conditional(extraFields, ",", "");
		let workerIds = "";
		if (workers) {
			if (size(workers)) {
				forEach(workers, function (id) {
					workerIds += `${id},`;
				});
			}
			workerIds = workerIds.trim();
			workerIds = workerIds.slice(0, workerIds.length - 1);
		}

		const params = parseRequest(
			{
				...customFilters,
				fields: `${extraFields}${basicfields}`,
				...(conditional(workerIds !== "", { ids: workerIds }, {})),
				include: `${includes},${valueOrOption(includeTo, "")}`,
			},
			{ filters },
			newPagination,
			{
				[`${fieldPrefix}contract_start`]: `${fieldPrefix}contract_end_gte`,
				[`${fieldPrefix}contract_end`]: `${fieldPrefix}contract_end_lte`,
				[`${fieldPrefix}contract_switch`]: `${fieldPrefix}is_permanent`,
				[`${fieldPrefix}leave_start`]: `${fieldPrefix}leave_gte`,
				[`${fieldPrefix}leave_end`]: `${fieldPrefix}leave_lte`,
				...(conditional(!searchOnChange.current, { search: 'worker' }, {}))
			}
		);

		const getData = conditional(isFunction(getApi), getApi, workersApi.get);
		performRequest({ api: getData(omitPayload(params, "ALL")), callback: res => onSuccess(res, filters) });
	};

	const onSuccess = (response, filters) => {
		setTotal(response.count);
		setData(response.results);
		if (response.results?.length && !searchOnChange.current && filters?.limit) {
			onDblClick({ dataItem: first(response.results), isKeyPress: true });
		}
	};

	const {
		page,
		filter,
		selection,
		refresh,
		filterChange,
		pageChange,
		selectRow,
		finishSelection,
		selectionLength,
		onAdvanceFilter,
		onDblClick,
		onSelectPage,
		control,
	} = useController({
		...others,
		forPayroll,
		with_permission_user,
		multi,
		data,
		getTableData,
		init,
		selectBy,
		onClose: handleClose,
		includeData,
		setWorkersSelectedData,
		workersSelectedData,
		extraParams,
		selectAndClose: searchOnChange.current,
	});

	const mapData = () => {
		if (hasValue(prefix)) {
			return (data ?? []).map(item => item[prefix]);
		}
		return data;
	};

	const tableProps = {
		style: { height },
		className: "principal-grid",
		data: selectedItem(mapData(), selection, selectBy),
		/* pagination */
		skip: page.skip,
		take: page.take,
		total: total,
		pageable: pageable(total),
		onPageChange: pageChange,
		/* filter */
		filter: filter,
		onFilterChange: filterChange,
		/* selection props */
		multiSelection: multi,
		selectedField: "selected",
		onRowClick: selectRow,
		onRowDoubleClick: onDblClick,
		onSelectPage,
	};

	const customColumn = useWatch({ control, name: 'custom_columns' });

	const onChangeCalculatePeriod = (e) => {
		const value = e?.value;
		setWithCalculatedPeriod(value);
		return e;
	}

	return (
		<>
			<Container>
				<WorkerHeaderButtons
					control={control}
					filters={filter}
					workerSearchProps={{
						initValue: initSearchValue,
						searchValue: searchValue,
						searchOnChange: searchOnChange
					}}
					forPayroll={forPayroll}
					onChangeCalculatePeriod={onChangeCalculatePeriod}
					onRefresh={refresh}
					advanceFilterProps={advanceFilterProps}
					onlyActives={onlyActives}
					onAdvanceFilter={onAdvanceFilter}
					customFilters={customFilters}
				>
					{children}
				</WorkerHeaderButtons>
				<Grid size={12}>
					<Table
						loading={loading}
						with_permission_user={with_permission_user}
						onlyActives={onlyActives}
						forPayroll={forPayroll}
						tableProps={tableProps}
						init={init}
						noRenderDescription={t("initial-search-modal-msg")}
						customColumn={customColumn} />
				</Grid>
			</Container>
			{!withoutActions && <Actions {...{ onClose, selectionLength, finishSelection, withFinishSelection, withAcceptButton, handleCloseDialogForm }} />}
		</>
	);
};

ModalContent.propTypes = ModalContentProps;

export const Modal = ({
	multi,
	openDialogForm,
	onClose,
	handleCloseDialogForm,
	...others
}) => {
	const { t } = useTranslation();

	const handleClose = handleCloseDialogForm ?? onClose;
	return (
		<FormDialog
			open={openDialogForm}
			title={`${t("select")} ${t(getTitles("worker", multi))}`}
			maxWidth="xl"
			handleClose={handleClose}
			dialogId={"worker-search-modal"}
		>
			<ModalContent {...others} onClose={handleClose} multi={multi} />
		</FormDialog>
	);
};

Modal.propTypes = ModalProps;

export default Modal;
