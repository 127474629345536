import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextMatrix } from '../../../Shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faBan, faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { debounce, each, isArray, isBoolean, isFunction, isNumber, isObject, isString, size } from 'lodash';
import { hasBuiltMoper, moperConstructor } from '../models';
import ModernPopup from '../../../../../../../../general/@components/general/Popup';
import { CustomTooltip } from '../../../../../../../../general/@components/Templates/cells';
import { useTranslation } from 'react-i18next';
import { Card, CircularProgress, Collapse, Grid2 as Grid } from '@mui/material';
import { resolveError } from '../../../../../../../../general/@components/requests/resolve-error';
import { moperSignaturesApi, overtimeMopersAPI } from '../../../../../../../../general/services/mopers';
import FormDialog from '../../../../../../../../general/@components/modal/dialog/FormDialog';
import DialogContent from '../../../../../../../../general/styled/DialogContent';
import DialogActions from '../../../../../../../../general/styled/DialogActions';
import { useForm, useWatch } from 'react-hook-form';
import Button from '../../../../../../../../general/styled/Button';
import { penddingSignaturesAPI } from '../../../../../../../../general/services/worker';
import FormInput from '../../../../../../../../general/@components/form/Field';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { stringValidation } from '../../../../../../../../general/@components/validations/stringValidation';
import { AnimatePresence, motion } from 'framer-motion';
import {
    fnTranslation,
    isAutoMoper,
    isMoperAuthorized,
    isMoperCanceled,
    isMoperProcess,
} from '../../../../../../moper/MOPERSByWorker/components/container/Overtime/utils/utilities';
import {
    DynamicRequestTime,
} from '../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Modals/TimePayment';
import { validateNumeric } from '../../../../../../../../general/@components/validations/numeric';
import { showNotification } from '../../../../../../../../general/@components/Notifications';
import { opacityAnimation } from '../../../../../../../../general/@components/form/Permission/Utils';
import { uid } from 'uid';
import { t } from 'i18next';
import { parseDatetime, searchInObject } from '../../../../../../../../general/@components/general/GeneralUtilities';
import IconUse from '../../../../../../../../general/@components/uiDesign/IconUse';
import PopupComponent
    from '../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Cards/OvertimeContent/components/PopupComponent';
import {
    genericProcess,
} from '../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Cards/OvertimeContent/utils/utils';
import MoperEditing from '../Modals';
import CardsPopup from '../../../../../../../../general/@components/general/Popup/components/CardsPopup';
import PopupsDict from '../PopupDrafts/PopupsDict';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import GenericLabel from '../../../../../../../../general/@components/general/GenericLabel/GenericLabel';
import { SolidDivider } from '../../../../../../../../general/@components/general/Dividers';
import RichText from '../../../../../../../../general/@components/general/RichText';
import PermissionGate from '../../../../../../../../general/@components/general/Signatures/PermissionGate';
import Flow from '../../../../../../../../general/@components/general/Signatures';
import '../../../../style/style.scss';

function DynamicMoper({
    moper,
    status,
    models,
    unique,
    onSign,
    refresh,
    onCancel,
    loadingSign,
    loadingCancel,
    loadingSignAll,
    className = '',
    showFlowCancel,
    showMoperInfo = true,
    showFlowSection = true,
    statusResponsive = false,
    showActions = true,
    showFlow = true,
    showDetails = false,
    customMsg,
    ...others
}) {
    const itemKey = useRef(uid()).current
    let moperValue = moper ?? {};

    if (!hasBuiltMoper(moper) && moper) {
        moperValue = moperConstructor(moper, models, unique);
    }

    const { folio, icon, iconUse, name, data, exactMoper } = moperValue;

    status = status ?? exactMoper?.status ?? exactMoper?.moper?.status;
    const inProcess = status === 1;
    const isAuto = isAutoMoper(exactMoper);

    const showCancel = !isAuto && showActions;

    return (
        <motion.div
            id={`dynamic-moper-${itemKey}`}
            key={`dynamic-moper-${itemKey}`}
            className={`sbw-details-moperitem ${className}`}
            layout="position"
        >
            <MainInfo
                name={name}
                icon={icon}
                iconUse={iconUse}
                folio={folio}
                moper={exactMoper}
                data={data}
                refresh={refresh}
                request={moperValue}
                showDetails={showDetails}
                statusResponsive={statusResponsive}
                {...others}
            />
            <MoperInfo
                data={data}
                show={showMoperInfo}
                moper={exactMoper}
                moperValue={moperValue}
                inProcess={inProcess}
                refresh={refresh}
                status={status}
                showDetails={showDetails}
                showFlow={showFlow}
                showCancel={showFlowCancel}
                {...others}
            />
            <FlowSection
                name={name}
                moper={moper}
                inProcess={false}
                moperValue={moperValue}
                showFlowSection={showFlowSection}
                showCancel={showFlowCancel}
                showMessage={!inProcess}
                exactMoper={exactMoper}
                status={status}
                customMsg={customMsg}
                refresh={refresh}
                {...others}
            />
            {showCancel &&
                <Actions
                    moper={moperValue}
                    onCancel={onCancel ?? refresh}
                    onSign={onSign ?? refresh}
                    loadingSign={loadingSign}
                    loadingCancel={loadingCancel}
                    loadingSignAll={loadingSignAll}
                    {...others}
                />}
        </motion.div>
    );
};

export default DynamicMoper;

DynamicMoper.propTypes = {
    moper: PropTypes.object,
    status: PropTypes.number,
    onSign: PropTypes.func,
    onCancel: PropTypes.func,
    refresh: PropTypes.func,
    showDetails: PropTypes.bool,
    className: PropTypes.string,
    showFlowSection: PropTypes.bool,
    statusResponsive: PropTypes.bool,
    showFlowCancel: PropTypes.bool,
    loadingSign: PropTypes.object,
    loadingCancel: PropTypes.object,
    loadingSignAll: PropTypes.object,
    showActions: PropTypes.bool,
    showMoperInfo: PropTypes.bool,
    models: PropTypes.object,
    showFlow: PropTypes.bool,
    unique: PropTypes.bool,
    customMsg: PropTypes.string,
};

export const MainInfo = ({
    name,
    icon,
    folio,
    data,
    moper,
    onClick,
    request,
    onDoubleClick,
    payrollPeriod,
    showDetails = true,
    statusResponsive = false,
    detail = false,
    iconClassName = '',
    ...others
}) => {
    const PopupComponent = PopupsDict(request?.popupKey);

    const [anchor, setAnchor] = useState(null);

    const handleClose = () => setAnchor(null);

    const handleClick = (e) => {
        e.stopPropagation();
        setAnchor(e.currentTarget);
    }

    return (
        <>
            <div onClick={onClick} onDoubleClick={onDoubleClick} className="sbw-details-moperitem-maininfo">{/*NOSONAR*/}
                <MoperIcon
                    {...others}
                    icon={icon}
                    name={name}
                    request={request}
                    statusResponsive={statusResponsive}
                    className={iconClassName}
                    detail={detail}
                    moper={moper}
                    data={data}
                    folio={folio}
                />
                <div className="sbw-details-namecontainer">
                    <CustomTooltip title={name}>
                        <span className="sbw-details-mopertitle">{name}</span>
                    </CustomTooltip>
                    <GenericLabel label={'Folio'} value={folio ?? '-'} className="sbw-label" />
                    {(!!PopupComponent && showDetails) &&
                        <button className="sbw-moper-view-details-btn" onClick={handleClick}>
                            <FontAwesomeIcon icon={faEye} />
                        </button>}
                </div>
            </div>
            {(!!PopupComponent && showDetails) &&
                <ModernPopup
                    target={anchor}
                    handleClose={handleClose}
                    title={'Detalles de la solicitud'}
                    className="main-popup-component"
                    withBackdrop={false}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    content={
                        <CardsPopup>
                            <PopupComponent
                                request={request}
                                payrollPeriod={payrollPeriod?.id ?? payrollPeriod}
                            />
                        </CardsPopup>
                    }
                />}
        </>
    );
};

MainInfo.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.any,
    payrollPeriod: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
    ]),
    request: PropTypes.object,
    folio: PropTypes.string,
    detail: PropTypes.bool,
    moper: PropTypes.object,
    showDetails: PropTypes.bool,
    data: PropTypes.array,
    onDoubleClick: PropTypes.func,
    onClick: PropTypes.func,
    statusResponsive: PropTypes.bool,
    iconClassName: PropTypes.string,
};

export const MoperIcon = ({
    icon: Icon,
    iconUse,
    moper,
    refresh,
    onlyRead,
    request,
    statusResponsive = false,
    detail = false,
    className = '',
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'right',
    },
    transformOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    fromList = false
}) => {

    let colorClassname = 'process';

    const [anchor, setAnchor] = useState(null);

    const isIcon = isObject(Icon);

    const onClick = (e) => {
        e.stopPropagation();
        setAnchor(e.currentTarget);
    };

    const handleClose = () => setAnchor(null);

    const isCanceled = isMoperCanceled(moper);
    const isAuth = isMoperAuthorized(moper);

    if (isAuth) {
        colorClassname = 'auth';
    }

    if (isCanceled) {
        colorClassname = 'canceled';
    }

    const cancelCallback = () => {
        if (isFunction(refresh)) {
            refresh();
        }
        handleClose();
    }

    const listIconPrefix = isString(Icon) ? Icon : Icon?.prefix;

    return (
        <>
            <div className={`sbw-details-moperitem-icon ${statusResponsive ? colorClassname : ''} ${className} ${detail ? 'button' : ''} `} onClick={detail ? onClick : null}>{/*NOSONAR*/}
                {((fromList && Icon?.icon) || iconUse) &&
                    <IconUse icon={Icon?.icon ?? iconUse} className='sbw-moper-icon' />
                }
                {(((!fromList && isIcon) || (fromList && !Icon?.key && isIcon)) && !iconUse) &&
                    <Icon className='sbw-moper-icon' />
                }
                {(((isString(Icon) && !fromList) || (fromList && !Icon?.icon && listIconPrefix)) && !iconUse) &&
                    <span className="text-icon">{fromList ? listIconPrefix : Icon}</span>
                }
            </div>
            {detail &&
                <ModernPopup
                    target={anchor}
                    handleClose={handleClose}
                    title={'Detalles de la solicitud'}
                    className="main-popup-component"
                    withBackdrop={false}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    content={
                        <PopupComponent
                            moper={moper}
                            onlyRead={onlyRead}
                            refresh={refresh}
                            onSign={cancelCallback}
                            request={request}
                        />
                    }
                />}
        </>
    );
};

MoperIcon.propTypes = {
    icon: PropTypes.any,
    moper: PropTypes.object,
    iconUse: PropTypes.string,
    detail: PropTypes.bool,
    refresh: PropTypes.func,
    onlyRead: PropTypes.bool,
    request: PropTypes.object,
    transformOrigin: PropTypes.object,
    anchorOrigin: PropTypes.object,
    statusResponsive: PropTypes.bool,
    className: PropTypes.string,
    fromList: PropTypes.bool,
};

export const MoperInfo = ({
    data,
    inProcess,
    moper,
    onClick,
    refresh,
    status,
    moperValue,
    onDoubleClick,
    show = true,
    onlyRead = false,
    withPopupFlow = true,
    showCancel = true,
    showMessage = true,
    showFlow = true,
    ...others
}) => {

    const hasFlow = size(moper?.flow_signature) > 0;
    const [target, setTarget] = useState(null);
    const [trigger, setTrigger] = useState(0);
    const [hasScroll, setHasScroll] = useState(false);
    const [slide, setSlide] = useState({});

    const infoRef = useRef();
    const released = useRef(true);

    const resizeOb = useRef(new ResizeObserver(() => {
        if (!infoRef.current || !show) return;
        setHasScroll(infoRef.current.scrollWidth > infoRef.current.clientWidth);
    })).current;

    useEffect(() => {
        if (show && infoRef.current) {
            resizeOb.observe(infoRef.current);
        }
        return () => resizeOb?.disconnect();
    }, [show])

    showFlow = showFlow && hasFlow;

    const infoStructure = [[], [], []];

    if (moperValue?.verticalMapping) {
        let dataIndex = 0;
        each(data, (item) => {
            if (infoStructure[dataIndex].length < (moperValue?.maxRowsData ?? 3)) {
                infoStructure[dataIndex].push(item);
            } else {
                dataIndex++;
                infoStructure[dataIndex].push(item);
            }
        });
    } else {
        each(data, (item, index) => {
            infoStructure[index % (moperValue?.maxColsData ?? 3)].push(item);
        });
    }

    const dataRender = infoStructure.filter(el => el.length);

    const handleDown = useCallback((e) => {
        const hasAlt = e.altKey;
        if (hasAlt) return;
        setSlide({
            startX: e.pageX - infoRef.current.offsetLeft,
            scrollLeft: infoRef.current.scrollLeft,
            pressed: true,
        });
    }, [infoRef.current]);

    const handleMove = useCallback((e) => {
        const hasAlt = e.altKey;
        if (!slide.pressed || hasAlt) return;
        e.preventDefault();
        released.current = false;
        const x = e.pageX - infoRef.current.offsetLeft;
        const walk = x - slide.startX;
        infoRef.current.scrollLeft = slide.scrollLeft - walk;
    }, [slide, infoRef.current]);

    const handleUp = () => {
        setSlide({});
        releaseDebounce();
    };

    const releaseDebounce = useRef(debounce(() => released.current = true, 300)).current;

    const handleClick = useCallback(debounce((e) => {
        if (!withPopupFlow) return;
        const times = e.detail;
        const isOneClick = times === 1;
        const isDoubleClick = times === 2;
        const hasAlt = e.altKey || !released.current;
        if (showFlow && isOneClick && !hasAlt) {
            handleViewFlow(e);
        }
        if (isDoubleClick && isFunction(onDoubleClick) && !hasAlt) {
            onDoubleClick(e, moper)
        }
        if (isFunction(onClick) && isOneClick && !hasAlt) {
            onClick(e, moper);
        }
    }, 200), [released.current, onDoubleClick, onClick, moper]);

    const handleViewFlow = (event) => {
        setTarget(event.currentTarget ?? event.target);
    };

    const handleCloseFlow = () => {
        setTarget(null);
    };

    const itemProps = {
        className: `sbw-details-moperitem-moperinfo ${hasScroll ? 'with-scroll' : ''} ${slide.pressed ? 'pressed' : ''}`,
        ...(hasScroll ? {
            onMouseDown: handleDown,
            onMouseMove: handleMove,
            onMouseUp: handleUp
        } : {})
    };

    if (!show) return null;

    return (
        <>
            <ModernPopup
                title={t('signature-flow')}
                target={target}
                triggerPosition={trigger}
                className="sbw-flow-popup"
                withBackdrop={false}
                handleClose={handleCloseFlow}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                content={
                    <PopupContent
                        moper={moper}
                        status={status}
                        refresh={refresh}
                        onlyRead={onlyRead}
                        showCancel={showCancel}
                        showMessage={showMessage}
                        onRender={() => setTrigger(prev => prev + 1)}
                    />}
            />
            <div className={`sbw-details-moperitem-moperinfo-wrapper ${inProcess ? 'full-w' : ''} ${showFlow ? 'with-signatures' : ''} ${withPopupFlow ? 'pointer' : ''}`}>{/*NOSONAR*/}
                <div ref={infoRef} key={`moper-info-${moper?.id}`} onClick={handleClick} {...itemProps}>{/*NOSONAR*/}
                    {dataRender.map((parent, ind) =>
                        <React.Fragment key={`moper-item-info-label-${ind}`}>{/*NOSONAR*/}
                            <div className="section-moper-info">
                                <TextMatrix items={parent} withTooltips={!slide.pressed} />
                            </div>
                            {ind + 1 < dataRender.length && <SolidDivider vertical />}
                        </React.Fragment>
                    )}
                </div>
                <ExtraActions
                    moper={moper}
                    moperValue={moperValue}
                    refresh={refresh}
                    {...others}
                />
            </div>
        </>
    );
};

MoperInfo.propTypes = {
    data: PropTypes.array,
    moper: PropTypes.object,
    inProcess: PropTypes.bool,
    show: PropTypes.bool,
    status: PropTypes.any,
    onlyRead: PropTypes.bool,
    withPopupFlow: PropTypes.bool,
    moperValue: PropTypes.object,
    onDoubleClick: PropTypes.func,
    onClick: PropTypes.func,
    refresh: PropTypes.func,
    showCancel: PropTypes.bool,
    showMessage: PropTypes.bool,
    showFlow: PropTypes.bool,
};

const ExtraActions = ({
    moper,
    moperValue,
    refresh,
    worker,
    ...others
}) => {
    const inProcess = isMoperProcess(moper);
    const isCanceled = isMoperCanceled(moper);
    const { editableAfterAuth, editIcon, editLabel } = moperValue;

    return (
        <div className="sbw-moper-item-extra-actions">
            <MoperComment moper={moper} moperValue={moperValue} />
            {(inProcess || (editableAfterAuth && !isCanceled)) &&
                <EditMoper
                    moper={moper}
                    refresh={refresh}
                    moperValue={moperValue}
                    label={fnTranslation(editLabel)}
                    worker={worker}
                    icon={editIcon}
                    {...others}
                />}
        </div>
    );
};

ExtraActions.propTypes = {
    moper: PropTypes.object,
    refresh: PropTypes.func,
    worker: PropTypes.object,
    moperValue: PropTypes.object,
};

const getEditableValue = (value, obj) => {
    if (isString(value)) {
        return searchInObject(value, obj);
    }
    if (isFunction(value)) {
        return value(obj);
    }
    return value;
}

const EditMoper = ({
    moper,
    worker,
    moperValue,
    refresh,
    icon,
    label,
    ...others
}) => {
    const [openEdit, setOpenEdit] = useState(false);

    const EditComponent = MoperEditing(moperValue?.catalog_key);
    const isEditable = getEditableValue(moperValue?.editable, moper) && !!EditComponent;
    const isCanceled = isMoperCanceled(moper);

    const handleClose = () => setOpenEdit(false);

    const handleEdit = () => {
        if (!isEditable) return;
        setOpenEdit(true);
    }

    if (!isEditable || isCanceled) return null;

    return (
        <>
            <CustomTooltip title={label ?? t('edit')}>
                <div className="sbw-moper-comment-btn-wrapper">
                    <button
                        onClick={handleEdit}
                        style={{ marginTop: '-3px' }}
                        className="sbw-moper-comment-btn"
                    >
                        {icon ?? <IconUse icon="Edit" />}
                    </button>
                </div>
            </CustomTooltip>
            <EditComponent
                moper={moper}
                open={openEdit}
                worker={worker}
                refresh={refresh}
                handleClose={handleClose}
                {...others}
            />
        </>
    );
};

EditMoper.propTypes = {
    moper: PropTypes.object,
    refresh: PropTypes.func,
    icon: PropTypes.element,
    label: PropTypes.string,
    moperValue: PropTypes.object,
    worker: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number
    ]),
};

export const MoperComment = ({ moper, moperValue }) => {
    const isCanceled = isMoperCanceled(moper);
    const cancelComment = moper?.cancel_comment;
    let comment = isCanceled && cancelComment ? cancelComment : moper?.request_comment;

    if (moper?.catalog_key === "vacaciones" && comment) {
        comment = comment.split(' - ').join('\n');
    }

    if (comment) {
        return <CommentRender comment={comment} asButton={moperValue?.commentButton} />;
    }
};

MoperComment.propTypes = {
    moper: PropTypes.object,
    moperValue: PropTypes.object,
};

const CommentRender = ({ comment, asButton = false }) => {
    const [target, setTarget] = useState();

    const handleComment = (event) => {
        setTarget(event.currentTarget ?? event.target);
    };

    const handleClose = () => {
        setTarget(null);
    };

    if (asButton) {
        return (
            <>
                <ModernPopup
                    title={t('comment')}
                    target={target}
                    className="sbw-flow-popup"
                    handleClose={handleClose}
                    content={
                        <span
                            style={{
                                whiteSpace: 'pre-line',
                                maxWidth: '400px',
                            }}>
                            {comment}
                        </span>}
                />
                <div className="sbw-moper-comment-btn-wrapper">
                    <button className="sbw-moper-comment-btn" onClick={handleComment}>
                        <IconUse icon="Message" />
                    </button>
                </div>
            </>
        );
    }

    return (
        <CustomTooltip title={comment}>
            <span className="sbw-moper-comment-wrap">
                <span className="label">
                    Comentario:
                </span>
                <span className="value">
                    {comment}
                </span>
            </span>
        </CustomTooltip>
    );
};

CommentRender.propTypes = {
    comment: PropTypes.string,
    asButton: PropTypes.bool,
};

export const FlowSection = ({
    moper,
    inProcess,
    exactMoper,
    status,
    refresh,
    onClick,
    moperValue,
    onDoubleClick,
    showFlowSignInfo = false,
    showCancel = false,
    showFlowSection = true,
    showMessage = false,
    withWorkgroup = false,
    showLevel = false,
}) => {
    const moperItem = moper?.exactMoper ?? exactMoper ?? {};
    const { commentButton, keepSignatures } = moperValue ?? {};

    const { request_comment } = moperItem;
    const hasComment = !!request_comment;

    if (!showFlowSection || (hasComment && !commentButton && !keepSignatures)) return null;

    return (
        <div onClick={onClick} onDoubleClick={onDoubleClick} className={`sbw-details-moperitem-usertimeinfo ${inProcess ? 'normal-w' : ''}`}>{/*NOSONAR*/}
            <FlowAndMessage
                exactMoper={exactMoper}
                status={status}
                refresh={refresh}
                showCancel={showCancel}
                showMessage={showMessage}
                withWorkgroup={withWorkgroup}
                showLevel={showLevel}
                payloadFields={moperValue?.payloadFields}
                showFlowSignInfo={showFlowSignInfo}
            />
        </div>
    );
};

FlowSection.propTypes = {
    moper: PropTypes.object,
    inProcess: PropTypes.bool,
    exactMoper: PropTypes.object,
    showFlowSignInfo: PropTypes.bool,
    status: PropTypes.any,
    moperValue: PropTypes.object,
    refresh: PropTypes.func,
    showFlowSection: PropTypes.bool,
    showMessage: PropTypes.bool,
    showCancel: PropTypes.bool,
    showLevel: PropTypes.bool,
    withWorkgroup: PropTypes.bool,
    onDoubleClick: PropTypes.func,
    onClick: PropTypes.func,
};

const TooltipBody = ({ title, comment }) => {

    return (
        <div className="moper-item-tooltip-body">
            <span>{title}</span>
            {!!comment && <span>{`Comentario: ${comment}`}</span>}
        </div>
    );
}

TooltipBody.propTypes = {
    title: PropTypes.string,
    comment: PropTypes.string,
}

const Message = ({
    isFinished,
    isCanceled,
    isAuto,
    signedBy,
    isAuth,
    words,
    autoMessage,
    actions,
    status
}) => {
    return (
        <>
            {(isFinished || isCanceled) ?
                <>
                    <span className={`blue`}>{signedBy}</span>
                    <span className={`signed-action ${(isAuth && !isCanceled) ? 'green' : 'red'}`}>{actions?.[status]}</span>
                    {!isAuto ? <RichText words={words} fontSize='clamp(10px, 1dvw, 12px)' /> : <span>{autoMessage}</span>}
                </>
                :
                <RichText words={genericProcess} />}
        </>
    );
}

Message.propTypes = {
    isFinished: PropTypes.bool,
    isCanceled: PropTypes.bool,
    isAuto: PropTypes.bool,
    signedBy: PropTypes.string,
    isAuth: PropTypes.bool,
    words: PropTypes.array,
    autoMessage: PropTypes.string,
    actions: PropTypes.object,
    status: PropTypes.number,
};

export const getMoperStatus = moper => {
    if (isMoperCanceled(moper)) {
        return 3;
    }
    if (isMoperAuthorized(moper)) {
        return 2;
    }
    return 1;
}

export const FlowAndMessage = ({
    status,
    refresh,
    onEdit,
    onRender,
    exactMoper,
    showCancel,
    payloadFields,
    showFlowSignInfo = true,
    onlyRead = false,
    showMessage = true,
    withWorkgroup = false,
    showLevel = false,
    withDivider = true,
    fromList = false
}) => {

    status = status ?? exactMoper?.moper?.status ?? getMoperStatus(exactMoper);

    const isFinished = isMoperAuthorized(exactMoper);
    const actions = { 1: 'en proceso', 2: 'autorizó', 3: 'canceló' };
    const signatures = exactMoper?.flow_signature?.filter(el => isFinished ? el?.name !== 'cancel' : el);

    const isCanceled = isMoperCanceled(exactMoper);
    const inProcess = !isFinished && !isCanceled;
    const lastSign = signatures?.[size(signatures) - 1];
    const signedDate = parseDatetime(lastSign?.signed_at, "dd/MM/yyyy", true);
    const signedTime = parseDatetime(lastSign?.signed_at, "HH:mm", true);
    const isAuto = size(signatures) === 0 || !signatures;
    const signedBy = lastSign?.signed_by ?? 'System';
    const autoMessage = 'está solicitud automáticamente';
    const cancelComment = exactMoper?.cancel_comment;

    const words = [
        { value: 'está solicitud el' },
        { value: signedDate, color: 'primary' },
        { value: 'a las' },
        { value: signedTime, color: 'primary' },
    ]

    const requestMessage = !isAuto ? words.map(el => el.value).join(' ') : autoMessage;
    const tooltipMessage = `${signedBy} ${actions?.[status]} ${requestMessage}`;

    useEffect(() => {
        if (!isFunction(onRender)) return;
        onRender();
    }, [])

    if (!size(exactMoper)) return null;

    return (
        <div className={`sbw-details-moperitem-signatures ${withDivider ? 'with-divider' : ''}`}>
            <Collapse in={(isNumber(status) && showMessage) && ((!!signedDate && (isFinished || isCanceled)) || isAuto || inProcess)} style={{ width: '100%' }}>
                <CustomTooltip title={!inProcess ? <TooltipBody title={tooltipMessage} comment={isCanceled ? cancelComment : null} /> : null}>
                    <div className="flow-moper-title">
                        <Message
                            actions={actions}
                            autoMessage={autoMessage}
                            isAuth={isFinished}
                            isAuto={isAuto}
                            isCanceled={isCanceled}
                            isFinished={isFinished}
                            signedBy={signedBy}
                            status={status}
                            words={words}
                        />
                    </div>
                </CustomTooltip>
            </Collapse>
            <Flow
                fromList={fromList}
                checkItem={exactMoper}
                onChange={refresh}
                handleEdit={!onlyRead ? onEdit : null}
                onlyRead={onlyRead}
                withWorkgroup={withWorkgroup || showFlowSignInfo}
                signInfo={showFlowSignInfo}
                showLevel={showLevel}
                payloadFields={payloadFields}
                showCancelSign={showCancel}
                minimal
            />
        </div>
    );
};

FlowAndMessage.propTypes = {
    status: PropTypes.any,
    refresh: PropTypes.func,
    onRender: PropTypes.func,
    showFlowSignInfo: PropTypes.bool,
    exactMoper: PropTypes.object,
    showMessage: PropTypes.bool,
    showCancel: PropTypes.bool,
    onlyRead: PropTypes.bool,
    onEdit: PropTypes.func,
    payloadFields: PropTypes.object,
    showLevel: PropTypes.bool,
    withDivider: PropTypes.bool,
    withWorkgroup: PropTypes.bool,
    fromList: PropTypes.bool
};

const PopupContent = ({
    moper,
    refresh,
    showCancel,
    status,
    onRender,
    showMessage = true,
    onlyRead = false,
    showLevel = true,
    showWorkgroup = false,
}) => {

    return (
        <div className="sbw-flow-popup-content">
            <FlowAndMessage
                onRender={onRender}
                exactMoper={moper}
                status={status}
                refresh={refresh}
                showLevel={showLevel}
                showCancel={showCancel}
                showMessage={showMessage}
                withWorkgroup={showWorkgroup}
                onlyRead={onlyRead}
            />
        </div>
    );
};

PopupContent.propTypes = {
    moper: PropTypes.object,
    refresh: PropTypes.func,
    onRender: PropTypes.func,
    showMessage: PropTypes.bool,
    showCancel: PropTypes.bool,
    status: PropTypes.any,
    onlyRead: PropTypes.bool,
    showLevel: PropTypes.bool,
    showWorkgroup: PropTypes.bool,
};

export function getNextSign(signatures) {
    if (!isArray(signatures)) return null;
    for (let i = size(signatures) - 1; i >= 0; i--) {
        if (signatures[i]?.is_signed === true) {
            const lastSign = i + 1;
            return signatures[lastSign];
        }
    }
    return signatures[0];
}

export const Actions = ({
    moper,
    onSign,
    loadingSignAll,
    showSuccessSign = false,
}) => {

    const { t } = useTranslation();

    const [loadingSign, setLoadingSign] = useState(false);
    const [loadingCancel, setLoadingCancel] = useState(false);
    const [success, setSuccess] = useState(null);

    const realMoper = moper?.exactMoper ?? moper;
    const moperId = realMoper?.moper?.id;
    const isParentLoading = (pMoper) => !!loadingSignAll && loadingSignAll?.moper_group === pMoper?.moper_group;

    const someLoading = (loadingSign || loadingCancel) || isParentLoading(realMoper);

    const lastSign = getNextSign(realMoper?.flow_signature);
    const canSign = lastSign?.can_sign && lastSign?.user_sign;
    const finished = isMoperAuthorized(realMoper);
    const canceled = isMoperCanceled(realMoper);
    const cantCancelAfterAuth = moper?.notCancelAfterAuth;
    const signIcon = canSign ? faCheck : faLockKeyhole;

    const handleSign = async (fMoper) => {
        setLoadingSign(true);
        const params = { moper: fMoper?.parent_moper_id };
        each(moper?.payloadFields, (value, field) => {
            params[field] = searchInObject(value, realMoper);
        });
        try {
            const response = await moperSignaturesApi.sign(params);
            setSuccess(t('signed'));
            showNotification({ message: response?.data ?? response?.detail, showTitle: false });
            if (isFunction(onSign)) {
                await onSign(response);
            }
            setLoadingSign(false);
        } catch (error) {
            resolveError(error);
            setLoadingSign(false);
        }
    };

    return (
        <AnimatePresence mode="wait">
            {(success && showSuccessSign) ?
                <motion.div
                    key={`sbw-actions-success-${moperId}`}
                    className="sbw-moperitem-actions-success-wrap"
                    {...opacityAnimation}
                >
                    <span className="sbw-moperitem-actions-success">
                        {success}
                    </span>
                </motion.div>
                :
                <motion.div
                    key={`sbw-actions-wrapper-${moperId}`}
                    className="sbw-details-moperitem-actions"
                    {...opacityAnimation}
                >
                    {((cantCancelAfterAuth && (!finished || canceled))
                        || (!cantCancelAfterAuth)) &&
                        <CancelMoper
                            moper={realMoper}
                            disabled={someLoading}
                            onCancel={onSign}
                            setSuccess={setSuccess}
                            loading={loadingCancel}
                            setLoading={setLoadingCancel}
                        />}
                    {(!finished && !canceled) &&
                        <CustomTooltip title={t(canSign ? 'Firmar' : 'No puede firmar este nivel')}>
                            <span>
                                <button
                                    onClick={() => (!someLoading && canSign) ? handleSign(realMoper) : null}
                                    className={`sbw-details-moperitem-sign ${someLoading || !canSign ? 'disabled' : ''}`}
                                    disabled={someLoading}
                                >
                                    {!loadingSign ? <FontAwesomeIcon icon={signIcon} /> : <CircularProgress />}
                                </button>
                            </span>
                        </CustomTooltip>}
                </motion.div>}
        </AnimatePresence>
    );
};

Actions.propTypes = {
    moper: PropTypes.object,
    onSign: PropTypes.func,
    showSuccessSign: PropTypes.bool,
    loadingSignAll: PropTypes.object,
};

export const absenteeismPathname = { moper_absenteeism: { pathname: 'name' } };

export const moperActions = [
    { value: 1, label: 'Cancelar solicitud' },
    { value: 2, label: 'Restaurar solicitud' },
    { value: 3, label: 'Cancelar TxT' },
];

export const CancelMoper = ({
    moper,
    setLoading,
    disabled,
    onCancel,
    onClick,
    loading,
    setSuccess,
    className = '',
    asText = false,
    style = {},
    fromList = false,
}) => {
    const [selected, setSelected] = useState(null);
    const [loadingCustom, setLoadingCustom] = useState(loading);
    const hasName = (moper?.exactMoper ?? moper)?.absenteeism_reason?.absenteeism?.name;
    const nameProp = !hasName ? absenteeismPathname : null;
    const builtMoper = !hasBuiltMoper(moper) ? moperConstructor(moper, nameProp, true) : moper;
    const exactMoper = builtMoper?.exactMoper ?? builtMoper;
    const canceled = isMoperCanceled(exactMoper) && exactMoper?.catalog_id !== 6;

    const openCancel = (moper, exactMoper) => {
        if (isFunction(onClick)) {
            onClick();
            return;
        }
        setSelected({ moper, exactMoper });
    };

    const ContentRender = !asText ? <FontAwesomeIcon icon={faBan} /> : 'Cancelar solicitud';

    return (
        <PermissionGate moper={exactMoper}>
            <CustomTooltip title={<MoperCancelTooltip moper={exactMoper} />}>
                <span>
                    <button
                        onClick={() => ((!disabled && (!loading && !loadingCustom)) && !canceled) ? openCancel(builtMoper, exactMoper) : null}
                        className={`sbw-details-moperitem-cancel ${className} ${asText ? 'as-text' : ''} ${(disabled || (loading || loadingCustom)) ? 'disabled' : ''}`}
                        disabled={disabled || canceled || loadingCustom || loading}
                        style={style}
                    >
                        {(!loading && !loadingCustom) ? ContentRender : <CircularProgress />}
                    </button>
                </span>
            </CustomTooltip>
            <CancelMoperForm
                fromList={fromList}
                onCancel={onCancel}
                selected={selected}
                setSuccess={setSuccess}
                setSelected={setSelected}
                loading={isBoolean(loading) ? loading : loadingCustom}
                setLoading={isFunction(setLoading) ? setLoading : setLoadingCustom}
            />
        </PermissionGate>
    );
}

CancelMoper.propTypes = {
    moper: PropTypes.object,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    setLoading: PropTypes.func,
    onCancel: PropTypes.func,
    onClick: PropTypes.func,
    setSuccess: PropTypes.func,
    className: PropTypes.string,
    asText: PropTypes.bool,
    style: PropTypes.object,
    fromList: PropTypes.bool
};

const titleStyle = {
    color: 'var(--egyptianBlue)',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    lineHeight: 1,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

export const CancelMoperForm = ({
    selected,
    setSelected,
    setLoading,
    onCancel,
    loading,
    setSuccess,
    fromList = false
}) => {
    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        cancel_comment: stringValidation({ required: true, min: 10, max: 100 }).when('action_type', {
            is: 2,
            then: () => stringValidation({ required: false }),
        }),
        minutes_canceled: validateNumeric({ required: false }).when('action_type', {
            is: 3,
            then: () => validateNumeric({ required: true }),
        }),
    });

    const { moper, exactMoper } = selected ?? {};

    let moperName = moper?.moper_name;
    let folio = moper?.folio;

    if (exactMoper) {
        moperName = exactMoper?.moper_name;
        folio = exactMoper?.folio;
    }

    if (moper) {
        moperName = moper?.name ?? moper?.moper_name;
        folio = moper?.folio;
    }

    const title = folio ? `${folio} - ${moperName}` : moperName;
    const isTxT = exactMoper?.pay_type === 2;
    const isDT = exactMoper?.pay_type === 3;
    const isCanceled = isMoperCanceled(exactMoper);
    const dataByTxT = (!isTxT || isCanceled) ? moperActions.filter(el => el.value !== 3) : moperActions;
    const dataInput = isCanceled ? dataByTxT.filter(el => el.value !== 1) : dataByTxT;
    const isOT = moper?.catalog === 6;

    const initValues = { cancel_comment: null, minutes_canceled: null, action_type: isOT ? 2 : 1 };

    const {
        control,
        reset,
        getValues,
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        defaultValues: initValues,
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (!selected) return;
        reset({ ...initValues });
    }, [selected])

    const onClose = () => {
        reset({ ...initValues });
        setSelected(null);
    }

    const updateLoadState = (value) => {
        if (isFunction(setLoading)) {
            setLoading(value);
        }
    }

    const onSubmit = async (data) => {
        updateLoadState(true);
        try {
            const response = await getAction(data, getValues('action_type'));
            onClose();
            if (isFunction(setSuccess)) {
                setSuccess(t('canceled'))
            }
            showNotification({ message: response?.data ?? response?.detail, showTitle: false });
            if (isFunction(onCancel)) {
                await onCancel(response);
            }
            updateLoadState(false);
        } catch (error) {
            resolveError(error);
            updateLoadState(false);
        }
    }

    const getAction = async (data, action) => {
        switch (action) {
            case 1:
                return await handleCancel(data);
            case 2:
                return await onRestore();
            case 3:
                return await onCancelTxT(data);
            default:
                break;
        }
    }

    const handleCancel = async (request) => await penddingSignaturesAPI.cancelSignature(exactMoper?.parent_moper_id, request);

    const onRestore = async () => {
        const params = {
            pay_type: exactMoper?.pay_type,
            ...(isDT ? { payment_type_rest_worked: exactMoper?.payment_type_rest_worked } : {}),
        }
        return await overtimeMopersAPI.restoreMoper(exactMoper?.id, params);
    };

    const onCancelTxT = async (request) => {
        request.moper_overtime_txt = exactMoper?.id;
        request.minutes_canceled = request.minutes_canceled * 60;
        return await overtimeMopersAPI.cancelTxT(request);
    }

    return (
        <FormDialog
            open={!!selected}
            handleClose={onClose}
            headerDivider
            maxWidth='sm'
            title="Cancelar moper"
            innerSubtitle={'En este apartado cancelará mopers con un comentario'}
            className='minimalist-dialog-design'
        >
            <DialogContent>
                <Card>
                    <Grid container spacing={2}>
                        <Grid
                            container
                            size={12}
                            spacing={1}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexFlow: 'row',
                            }}
                        >
                            <Grid style={{ flex: 'none' }}>
                                <MoperIcon {...(moper ?? {})} fromList={fromList} />
                            </Grid>
                            <Grid style={{ display: 'flex', flex: '1 0', overflow: 'hidden' }}>
                                <CustomTooltip title={title}>
                                    <span style={titleStyle}>{title}</span>
                                </CustomTooltip>
                            </Grid>
                        </Grid>
                        {/* <SubTitle
                            title={t('signature-flow')}
                            blueTitle={false}
                        >
                            <div style={{ width: '100%', paddingTop: '10px' }}>
                                <Flow
                                    minimal
                                    onlyRead
                                    checkItem={exactMoper}
                                    withWorkgroup={false}
                                    showLevel={false}
                                    showCancelSign={false}
                                />
                            </div>
                        </SubTitle> */}
                        {isOT &&
                            <Grid size={12}>
                                <FormInput
                                    fieldInput="dropdown"
                                    control={control}
                                    name="action_type"
                                    noDefault={true}
                                    isRequired={true}
                                    textField="label"
                                    dataItemKey="value"
                                    valueField="value"
                                    label={t("Acción")}
                                    data={dataInput}
                                />
                            </Grid>}
                        <InputRender control={control} moper={selected?.exactMoper} />
                    </Grid>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    design="text"
                    type="button"
                    disabled={loading}
                    onClick={onClose}
                >
                    {t("cancel")}
                </Button>
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <Button
                        design="contained"
                        type="submit"
                        loading={loading}
                    >
                        {t("apply")}
                    </Button>
                </form>
            </DialogActions>
        </FormDialog>
    );
};

CancelMoperForm.propTypes = {
    selected: PropTypes.object,
    setSelected: PropTypes.func,
    setLoading: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
    setSuccess: PropTypes.func,
    fromList: PropTypes.bool
};

export const MoperCancelTooltip = ({ moper }) => {
    const { t } = useTranslation();
    const canceled = moper?.flow_signature?.find(el => el?.name === 'cancel');
    const datetime = parseDatetime(canceled?.signed_at, 'HH:mm', true);
    const date = parseDatetime(canceled?.signed_at, 'dd/MM/yyyy', true);

    return (
        <div className="moper-cancel-tooltip">
            {!canceled?.is_signed ?
                <span>{t('cancel')}</span>
                :
                <>
                    <span>{canceled?.signed_by}</span>
                    <span>{date} {datetime}</span>
                </>
            }
        </div>
    );
}

MoperCancelTooltip.propTypes = {
    moper: PropTypes.object,
}

const TxTComponent = ({
    control,
    item,
}) => {
    const labels = [
        'Solicitadas',
        'Restantes',
        'Canceladas',
    ];
    const [data, setData] = useState([0, 0, 0]);
    const requested = (item?.minutes_requested ?? 0) / 60;
    const toCancel = useWatch({ control, name: 'minutes_canceled' });

    useEffect(() => {
        const newData = calculate();
        setData(newData);
    }, [toCancel]);

    function calculate() {
        const newRemaining = requested - (toCancel ?? 0);
        const newCanceled = (toCancel ?? 0);
        return [requested, newRemaining, newCanceled];
    };

    return (
        <Grid
            container
            size={12}
        >
            <Grid size={6}>
                <DynamicRequestTime control={control} hours={item?.minutes_requested} />
            </Grid>
            <Grid size={6}>
                <div className="balance-info-wrapper">
                    {
                        labels.map((el, key) => (
                            <div
                                key={`balance-info-${el}`}
                                className="balance-info-column"
                            >
                                <span className="label">
                                    {el}
                                </span>
                                <span className="value">
                                    {data[key]}
                                </span>
                            </div>
                        ))
                    }
                </div>
            </Grid>
        </Grid>
    );
};

TxTComponent.propTypes = {
    control: PropTypes.object,
    item: PropTypes.object,
};

const InputRender = ({ control, moper }) => {
    const { t } = useTranslation();
    const actionType = useWatch({ control, name: 'action_type' });

    return (
        <>
            {
                actionType === 3 &&
                <TxTComponent control={control} item={moper} />
            }
            {
                actionType !== 2 &&
                <Grid size={12}>
                    <FormInput
                        control={control}
                        fieldInput="TextArea"
                        name="cancel_comment"
                        label={t('cancel-comment')}
                        maxLength={101}
                        style={{ height: '70px' }}
                        autoFocus
                    />
                </Grid>
            }
        </>
    );
};

InputRender.propTypes = {
    control: PropTypes.object,
    moper: PropTypes.object,
};