import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faGear,
} from "@fortawesome/pro-light-svg-icons";
/* reducers */
import { toggleSideMenu } from "../../../../../../store/triggers/navigate";
import HeaderLogo from "../../../../../../assets/images/logo/abrhilcloud_logo";
import useAbrhilNavigation from '../../contextsAndControllers/useAbrhilNavigation';

const HeaderMenuLogo = ({
	toggleTools,
}) => {

	const { isMobile, menuCollapsed, breadcrumbs } = useAbrhilNavigation();
	const dispatch = useDispatch();
	const onClick = () => dispatch(toggleSideMenu());

	useEffect(() => {
		if (isMobile && !menuCollapsed) {
			onClick();
		}

		let collapsedMenu = localStorage.getItem("menuCollapsed");
		if (![null, undefined].includes(collapsedMenu) && !isMobile) {
			if (JSON.parse(collapsedMenu) !== menuCollapsed) {
				onClick();
			}
		}
		// eslint-disable-next-line
	}, [isMobile, breadcrumbs]);

	useEffect(() => {
		// While loading, load the state from the localStorage.
		localStorage.setItem("menuCollapsed", menuCollapsed);
		// eslint-disable-next-line
	}, [menuCollapsed]);

	const hamburgClass = `${menuCollapsed ? "" : "is-active"}`;

	return (
		<Fragment>
			<div className="app-header__logo">
				<div className={`logo-src ${menuCollapsed ? 'collapsed' : ''}`}>
					<HeaderLogo />
				</div>
			</div>
			<div className="app-header__mobile-menu">
				<Hamburger className={`mobile-toggle-nav ${hamburgClass}`} onClick={onClick} />
			</div>
			<div className="app-header__menu">
				<span>
					<button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav" onClick={toggleTools}>
						<span className="btn-icon-wrapper">
							<FontAwesomeIcon icon={faGear} />
						</span>
					</button>
				</span>
			</div>
		</Fragment>
	);
};

export default HeaderMenuLogo;

const Hamburger = ({ className, onClick }) => {
	return (
		<button type="button" className={`hamburger hamburger--elastic ${className}`} onClick={onClick}>
			<span className="hamburger-box">
				<span className="hamburger-inner"></span>
			</span>
		</button>
	);
};