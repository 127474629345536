import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import SearchField, { isLetter, onlyLetters, onlyNumbers } from "../Inputs/search/SearchField";
import { TooltipBody } from "../Inputs/search/Worker/TooltipBody";
import { customColumnsOptions } from "./constants";
import FormInput from "../form/Field";
import { AdvanceFilters, ButtonsContainer, Export, Refresh } from "../Buttons";
import { useDispatch, useSelector } from "react-redux";
import { getWorkerFilterFields } from "./filterFunctions";
import { currentTenant, hasErrorTetant } from "../requests/validate-tenant";
import { showNotificationWarning } from "../../../../store/actions";
import { errorTenant } from "../Notifications/notification-messages";
import { columnsToExport, urlExport } from "../Inputs/search/Worker/constants";
import ExportDialog from "../modal/Export/ExportDialog";
import { isNumber, toLower } from "lodash";
import { omitPayload } from "./utilities";
import useRequestLoad from "../../services/useRequestLoad";
import { extraDataAPI } from "../../services/worker";

const WorkerHeaderButtons = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        children,
        control,
        filters,
        forPayroll = false,
        onChangeCalculatePeriod,
        onRefresh,
        advanceFilterProps,
        onlyActives,
        onAdvanceFilter,
        workerSearchProps,
        withExport = true,
        customFilters = {},
    } = props ?? {};


    const {
        searchValue,
        searchOnChange
    } = workerSearchProps;

    const structure = useSelector((state) => state.configuration.steps);
    const cloneConfig = useSelector((state) => state.configuration.abrhil_package?.multi_employer_registration?.value);

    const [filterFields, setFilterFields] = useState({});
    const [fieldSelection, setFieldSelection] = useState([]);
    const [openDialogExport, setOpenDialogExport] = useState(false);

    const [fetchExtras, loadingExtras] = useRequestLoad();

    const handleChange = value => {
        const firstChar = value[0];
        const hasLetter = isLetter(firstChar);
        const hasNumber = isNumber(firstChar);
        searchOnChange.current = hasLetter || value === '';
        if (hasLetter) value = onlyLetters(value);
        if (hasNumber) value = onlyNumbers(value);
        searchValue.current = value;
        return value;
    };

    const onSearchWorker = (value, keypress) => {
        if ((!searchOnChange.current && !keypress) || (searchOnChange.current && value?.filters?.search?.length < 2 && value?.filters?.search)) return;
        if (keypress) {
            value.filters.limit = 1;
        } else {
            delete value.filters.limit;
        }
        onAdvanceFilter(value);
    };

    /**
 *
 * @param {Array} columns
*/
    const filterValuesInColumns = (columns = []) => {
        let newColumns = [];

        columns.forEach((column = []) => {
            newColumns = [
                ...newColumns,
                column.filter(field => field?.validation !== false)
            ];
        });

        return newColumns;
    };

    const validateFields = async () => {
        const extraData = await fetchExtras({ api: extraDataAPI.get({ tree: true }) });
        const formatData = extraData?.map(el => ({ name: toLower(el.name), text: el.name, isSelected: false }));
        console.warn('implement extra data in columns as second param when backend is ready', formatData);
        const finalBuild = filterValuesInColumns(columnsToExport(null/* , formatData */));
        setFieldSelection(finalBuild);
    }

    const handleOpenDialogExport = () => {

        if (hasErrorTetant()) {
            dispatch(showNotificationWarning(errorTenant()));
            return;
        }

        validateFields();
        setOpenDialogExport(true);
    };

    const closeHandleExport = () => {
        setOpenDialogExport(false);
    };

    useEffect(() => {
        const fields = getWorkerFilterFields(!onlyActives && !forPayroll, cloneConfig, structure);
        setFilterFields(fields);
        // eslint-disable-next-line
    }, [structure]);

    return (
        <>
            {children}
            <SearchField
                {...workerSearchProps}
                onAdvanceFilter={onSearchWorker}
                onChange={handleChange}
                control={control}
                filters={filters}
                label={t("worker")}
                helpIcon={true}
                titleHelp={<TooltipBody />}
            />
            <Grid size={{ xs: 3, md: 3, lg: 2, }}>
                <FormInput
                    fieldInput={"MultiSelect"}
                    control={control}
                    keyField="id"
                    textField="name"
                    value="value"
                    name={"custom_columns"}
                    label={`${t("view")} ${t("see-more-columns")}`}
                    options={customColumnsOptions()}
                />
            </Grid>
            {forPayroll &&
                <Grid size={{ xs: 4, md: 4, lg: 3, }}>
                    <FormInput
                        fieldInput={"switch"}
                        control={control}
                        name="with_calculate"
                        label={t("payroll-period-workers")}
                        onChange={onChangeCalculatePeriod}
                    />
                </Grid>
            }
            <Grid size={"grow"}>
                <ButtonsContainer className={"mt-2"}>
                    <Refresh onClick={() => onRefresh(filters)} />
                    <AdvanceFilters
                        {...advanceFilterProps}
                        filters={filters}
                        fields={filterFields}
                        onFilter={onAdvanceFilter}
                        withoutActive={onlyActives || forPayroll}
                    />
                    {withExport &&
                        < Export onClick={handleOpenDialogExport} />
                    }
                </ButtonsContainer>
                <ExportDialog
                    open={openDialogExport}
                    handleClose={closeHandleExport}
                    setFieldSelection={setFieldSelection}
                    fieldSelection={fieldSelection}
                    currentTenant={currentTenant()}
                    loading={loadingExtras}
                    filters={
                        {
                            filters: omitPayload({ ...(filters?.filters ?? {}), ...customFilters })
                        }
                    }
                    moduleFactory={urlExport} />
            </Grid>
        </>
    );
};

export default WorkerHeaderButtons;