import React, {
	useContext,
	useState,
	useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasValue, valueOrOption } from '../../general/GeneralUtilities';
import { closeWindow } from '../../../../../store/triggers/navigate';
import { windowCloseWarning } from '../../Notifications/notification-messages';
import { showNotificationWarning, stampType } from '../../../../../store/actions';
import { AbrhilWindowContext } from './contexts';

const useController = ({ windowNumber = 1, }) => {

	const window_key = `window_${windowNumber}`;
	const dispatch = useDispatch();
	const {
		[window_key]: windowElement,
		focused_window,
		[focused_window]: currentFocus,
	} = useSelector(state => state.navigate);

	const isFocused = focused_window === window_key;
	const [open, setOpen] = useState(false);
	const [item, setItem] = useState({});

	const { id } = valueOrOption(windowElement, {});

	useEffect(() => {
		if (hasValue(id)) {
			setItem(windowElement);
			setOpen(true);
			return;
		}
		setOpen(false);
		//eslint-disable-next-line
	}, [id]);

	const onClose = () => {
		if (!/window_[1-6]/.test(window_key)) {
			return dispatch(showNotificationWarning(windowCloseWarning()));
		}
		setOpen(false);
		setItem({});
		dispatch(closeWindow(window_key));
	};

	return {
		open,
		item,
		onClose,
		window_key,
		isFocused,
		stampType,
		currentFocus
	};
};

/**
 * Controlador de contexto para los conceptos de nomina
 * @returns {useController}
 * */
export const AbrhilWindowProvider = ({ children, ...props }) => (
	<AbrhilWindowContext.Provider value={useController(props)}>
		{children}
	</AbrhilWindowContext.Provider>
);

/**
 * Controlador de contexto para los conceptos de nomina
 * @returns {useController}
 * */
export const useAbrhilWindow = () => useContext(AbrhilWindowContext);

export default useAbrhilWindow;