import React from "react";
import PropTypes from "prop-types";
import { store } from "../../../../../store";
import { isFunction, isObject, isString, toString } from "lodash";
import { hasValue } from "../GeneralUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTooltip } from "../../Templates/cells";
import { permsGate } from "../../navigation/utilities";
import "./_styles.scss";

export const canViewSalary = () => permsGate('general-settings.can-see-salaries');

export const canViewMopersAmounts = () => store.getState().userConfigs?.user_info?.moper_attendance?.find(el => el.key === 'see_salaries')?.has_perm;

export const SALARY_CENSURE = '$****';

export const validateSalaryPerms = (value, perm) => {
    if (!value) return '';
    if (isString(value)) {
        if (value?.includes('$')) {
            return perm ? value : SALARY_CENSURE;
        }
    }
    return value;
};

function GenericLabel({
    label,
    value,
    labelIcon: LabelIcon,
    valueIcon,
    tooltip,
    weight = '400',
    blueValue = true,
    useKardexSalary = false,
    useSalaryPerm = true,
    primaryLabel = false,
    wrapLabel = false,
    wrapValue = false,
    className = "",
    withValue = true,
    disableTooltip = false,
    fontSize = "clamp(12px, 0.7dvw, 14px)",
    align = "horizontal",
    style = {},
    labelStyle = {},
}) {

    let iconRender = null;
    const salariesPerm = !useKardexSalary ? canViewMopersAmounts() : canViewSalary();

    value = (!isObject(value) && !isFunction(value) && hasValue(value)) ? toString(value) : value;

    if (LabelIcon?.prefix) {
        iconRender = <FontAwesomeIcon icon={LabelIcon} />;
    } else if (LabelIcon?.render) {
        iconRender = <LabelIcon />;
    } else {
        iconRender = LabelIcon;
    }

    const validatePerms = text => {
        if (!useSalaryPerm) return text;
        return validateSalaryPerms(text, salariesPerm);
    }

    return (
        <CustomTooltip title={!disableTooltip ? (tooltip ?? validatePerms(value)) : ''}>
            <div className={`sbw-label-generic-item ${align} ${className}`}>
                {
                    hasValue(label) ?
                        <span
                            className="label"
                            style={{
                                fontSize,
                                fontWeight: weight,
                                ...labelStyle,
                                ...(primaryLabel ? { color: 'var(--egyptianBlue)' } : {}),
                                ...(wrapLabel ? {
                                    whiteSpace: 'wrap',
                                    wordBreak: 'normal',
                                } : {}),
                                ...(wrapValue ? {
                                    alignSelf: 'flex-start'
                                } : {})
                            }}>
                            {iconRender}
                            {`${label}${withValue && value ? ':' : ''}`}
                        </span>
                        :
                        null
                }
                {
                    value ?
                        <span
                            className={`value ${blueValue ? 'blue' : ''}`}
                            style={{
                                ...style,
                                fontSize,
                                fontWeight: weight,
                                ...(wrapValue ? {
                                    whiteSpace: 'wrap',
                                    wordBreak: 'normal',
                                } : {})
                            }}
                        >
                            {valueIcon ? <FontAwesomeIcon icon={valueIcon} /> : null}
                            {validatePerms(value)}
                        </span>
                        :
                        null
                }
            </div>
        </CustomTooltip>
    );
};

export default GenericLabel;

GenericLabel.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    value: PropTypes.any,
    weight: PropTypes.string,
    blueValue: PropTypes.bool,
    wrapLabel: PropTypes.bool,
    wrapValue: PropTypes.bool,
    primaryLabel: PropTypes.bool,
    useSalaryPerm: PropTypes.bool,
    useKardexSalary: PropTypes.bool,
    align: PropTypes.string,
    valueIcon: PropTypes.any,
    labelIcon: PropTypes.any,
    style: PropTypes.object,
    withValue: PropTypes.bool,
    disableTooltip: PropTypes.bool,
    className: PropTypes.string,
    fontSize: PropTypes.string,
    tooltip: PropTypes.any,
    labelStyle: PropTypes.object,
};