import { toLower } from "lodash";
import { downloadFile, noPrefixDownload, OnCreatedTenant } from './utilities';
import { valueOrOption } from '../general/GeneralUtilities';
import { downloadLiquidationReport } from "../../../modules/imss/Liquidation";

const CANCEL_API = 'task-report';

export const SocketsDictionary = (tenant) => {

	const getUrl = route => `${tenant}/${route}/`;

	const commonProps = route => ({
		url: getUrl(route),
		cancel_api: CANCEL_API,
	});

	const constructor = (route, filename, apiName, xlsx = false) => ({
		...commonProps(route),
		callbackWS: async (taskID, repo_name) => await downloadFile(taskID, valueOrOption(filename, repo_name), apiName, xlsx),
	});

	const constructor2 = (route) => ({
		...commonProps(route),
		callbackWS: noPrefixDownload,
	});

	const noFileSocket = (route) => ({
		...commonProps(route),
	});

	return {
		calculate_ptu_report: constructor("payroll-calculate-response/ptu_report", 'calculate-ptu-report'),
		bimonthly_variables_report: constructor("bimonthly-payroll-variable/report", 'bimonthly-payroll-variable'),
		delete_report: constructor("bimonthly-payroll-variable/delete-report", 'bimonthly-payroll-variable-delete'),
		worker_settlement_report: constructor("worker-settlement-report", 'worker-settlement-report'),
		payroll_policy_report: constructor("payroll-policy", 'payroll-policy'),
		payroll_provision_policy: constructor("payroll-provision-policy-vacations"),
		vacations_balance_report_pdf: constructor("vacation-balance/pdf-report", 'vacation-balance-report'),
		vacations_balance_report_xlsx: constructor("vacation-balance/xlsx-report", 'vacation-balance-report'),
		massive_increase_report: constructor2("salary-movement-records/massive-increase-report"),
		massive_increase_structure: constructor2("salary-movement-records/massive-increase-structure"),
		massive_increase_apply: noFileSocket('salary-movement-records/massive-increase-apply'),
		massive_increase_delete: noFileSocket('salary-movement-records/massive-increase-delete'),
		saving_fund_adjust_report: constructor2('calculate/saving-fund-adjust-report'),
		saving_fund_adjust_update: noFileSocket('calculate/saving-fund-adjust-update'),
		massive_salary_update_generate: constructor('enterprise-structures-settings/massive-salary-update-generate'),
		massive_salary_update_apply: constructor('enterprise-structures-settings/massive-salary-update-apply'),
		payroll_canceled_report: constructor("payroll-canceled-report"),
		attendance_excel_report: constructor("attendancelist-report-excel"),
		ema_comparison: constructor("ema-comparison-report"),
		eba_comparison: constructor("eba-comparison-report"),
		idse_hiring_report: constructor("idse_hiring_transfer_report"),
		idse_all_report: constructor("idse_transfer_all_movement_report"),
		sua_infonavit: constructor("payroll_sua_comparison_report", 'payroll_sua_report'),
		contracts_catalog: {
			url: getUrl('formats-render'),
			callbackWS: (taskID, filename) => downloadFile(taskID, filename, "format", false),
		},
		payroll_dispersion_layout: constructor("payroll-report-dispersion-layout", "payroll-dispersion-layout"),
		payroll_dispersion_report: constructor("payroll-report-dispersion-report", "payroll-dispersion"),
		voucher_dispersion_layout: constructor("voucher-dispersion-layout", "voucher-dispersion-layout"),
		payroll_report: constructor('payroll-report', "payroll_report"),
		payroll_report_incidences: constructor('payroll-report-incidences', "payroll_report_incidences"),
		payroll_report_employer_registration: constructor('payroll-report-employer-registration', "payroll_report_employer_registration"),
		payroll_report_netos: constructor('payroll-report-netos', "payroll_report_netos"),
		payroll_report_dedu_perc: constructor('payroll-report-dedu-perc', "payroll_report_dedu_perc"),
		payroll_report_difference_linear: constructor('payroll-report-difference-linear', "payroll_report_difference_linear"),
		payroll_report_capture_not_applied: constructor('payroll-report-capture-not-applied', "payroll_report_capture_not_applied"),
		payroll_report_excel_general: constructor('payroll-report-excel-general', "payroll_report_excel_general"),
		payroll_report_taxes: constructor('payroll-report-taxes', "payroll_report_taxes"),
		payroll_report_concept: constructor('payroll-report-concept', "payroll_report_concept"),
		payroll_report_perceptions_difference: constructor('payroll-report-perceptions-difference', "payroll_report_perceptions_difference"),
		payroll_report_loans: constructor('payroll-report-loans', "payroll_report_loans"),
		report_birthday: constructor('report-birthday', "report_birthday"),
		report_validity: constructor('report-validity', "report_validity"),
		report_contract: constructor('report-contract', "report_contract"),
		report_antiquity: constructor('report-antiquity', "report_antiquity"),
		report_vacation_enjoyed: constructor('report-vacation-enjoyed', "report_vacation_enjoyed"),
		report_document_expiration: constructor('report-document-expiration', "report_document_expiration"),
		report_worker_immigration_validity: constructor('report-worker-immigration-validity', "report_worker_immigration_validity"),
		payroll_report_payment_transfer: constructor('payroll-report-payment-transfer', "payroll_report_payment_transfer"),
		report_imss_movement: constructor('report-imss-movement', "report_imss_movement"),
		report_personal_rotation: constructor('report-personal-rotation', "report_personal_rotation"),
		report_worker_information: constructor('report-worker-information', "report_worker_information_short"),
		report_worker_family_excel: constructor("report-worker-family-excel", "report_worker_family_excel"),
		moper_reports: constructor('moper-reports', "moper_reports"),
		sua_transfer: constructor("sua_transfer"),
		worker_sua_transfer: constructor("worker_sua_transfer"),
		report_expired_contracts: constructor("report-expired-contracts", "report_expired_contracts"),
		report_worker_current_active: constructor("report-worker-current-active", "report_worker_current_active"),
		idse_missing_movements: constructor("missing-idse-report", "idse_missing_movements"),
		moper_reports_pending: constructor("moper-reports-pending", "moper_reports_pending"),
		report_absenteeism_excel: constructor("report-absenteeism-excel", "report_absenteeism_excel"),
		report_worker_attendance_card: constructor("report-worker-attendance-card", "report_worker_attendance_card"),
		moper_reports_authorized_excel: constructor("moper-reports-authorized-excel", "moper_reports_authorized_excel"),
		payroll_report_bonus_excel: constructor("payroll-report-bonus-excel"),
		vacation_audit: constructor("vacation-audit", "vacation-audit"),
		worker_incapacities_report: constructor("worker-incapacities-report", "worker_incapacities_report"),
		worker_master_detail: constructor("worker-master-detail", "worker-master-detail"),
		enterprise_master_detail_report: constructor("enterprise-master-detail-report", "enterprise-master-detail-report"),
		report_permission_role: constructor("report-permission-role", "report-permission-role"),
		cirse_massive: constructor("cirse-massive", "cirse-layout", null, true),
		clone_holidays: constructor("clone-holidays"),
		attendancelist_print: constructor("attendancelist-report"),
		attendancelist_massive_report: constructor("attendancelist-massive-report"),
		entries_exits_print: constructor("entriesexits-report"),
		m_liquidation: {
			url: getUrl('monthly_liquidation'),
			callbackWS: null,
		},
		b_liquidation: {
			url: getUrl('bimonthly_liquidation'),
			callbackWS: null,
		},
		m_liquidation_report: {
			url: getUrl('monthly_liquidation_report'),
			callbackWS: downloadLiquidationReport,
		},
		b_liquidation_report: {
			url: getUrl('bimonthly_liquidation_report'),
			callbackWS: downloadLiquidationReport,
		},
		multi_create_users: {
			url: getUrl('multi-create-users'),
			callbackWS: null,
		},
		idse_confirmation: constructor("idse_confirmation"),
		generic_export: {
			url: getUrl('export'),
			callbackWS: (taskID, filename) => downloadFile(taskID, `${toLower(filename?.split('_')?.[0])}`, "export-data", true),
		},
		create_tenant: {
			url: `tenant_creation/`,
			callbackWS: OnCreatedTenant,
		},
		apply_holidays: {
			url: getUrl('apply-holidays'),
		},
		apply_faults: {
			url: getUrl('apply-absences'),
		},
		apply_breaks: {
			url: getUrl('apply-days-off'),
		},
		cirse_excel: constructor('cirse-excel', 'cirse', null, true),
		dc3_format: constructor("dc-3-format"),
		list_course_report: constructor("list-course-report"),
		replicate_flow_workgroups: {
			url: getUrl('copy-flow-signature'),
		},
		ema_comparison_process: {
			url: getUrl('ema-comparison-process'),
		},
		eba_comparison_process: {
			url: getUrl('eba-comparison-process'),
		},
		copy_moper_catalog: {
			url: getUrl('copy-moper-catalog')
		},
		training_certificates: constructor('certificates')
	};
};