import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Notifications from "../NavRight/Notifications"
/* material ui */
import {
	faBell
} from "@fortawesome/pro-solid-svg-icons";
import EnterpriseTab from './EnterpriseTab';
import User from '../SideMenu/User';
import { Tab } from './Tab';
import { Tabs } from './Tabs';
import { findItemByPath, getCurrentPath } from '../../utilities';
import { useSelector } from 'react-redux';
import ZoomKeyDownHandler from '../ZoomKeyDownHandler';

const TabsContainer = ({
	fixedElement
}) => {
	return (
		<div key='abrhil-windows-tabs' className='abrhil-windows-tabs'>
			<div key='abrl-tabs-container' className='tabs-container' >
				<EnterpriseTab />
				<Tabs />
				{fixedElement && <FixedTab />}
			</div>
			<ZoomKeyDownHandler />
			{/* <Notifications /> */}
			<button className='abrhil-tabs-notifications-button' onClick={() => alert("wait for updates")}>
				<FontAwesomeIcon icon={faBell} />
			</button>
			<User />
		</div>
	);
};

export default TabsContainer;

const FixedTab = () => {

	const [item, setItem] = useState(null);
	const {
		routes
	} = useSelector(state => state.navigate);

	const getPathItem = () => {
		let current = getCurrentPath();
		if (/\/(\d+)\//.test(current)) {
			current = current.replace(/\/\d+\//, '/:id/');
			current = `${current}*`;
		}
		setItem(findItemByPath(current, routes));
	};

	useEffect(() => {
		getPathItem();
		//eslint-disable-next-line
	}, [routes]);

	const onClose = () => window.close();

	if (!item) {
		return null;
	}

	return (
		<Tab
			active={true}
			title={item?.title}
			onClick={() => null}
			onClose={onClose}
		/>
	);
};