import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faXmark,
	faCheck
} from "@fortawesome/pro-solid-svg-icons";
import { opacityAnimation } from "../form/Permission/Utils";
import { motion } from "framer-motion";
import { transition } from "./constants";

const Message = ({
	taskId,
	handleCancel,
	handleConfirmCancel,
}) => {

	const { t } = useTranslation('notifications');

	return (
		<ConfirmMessage
			taskId={taskId}
			handleCancel={handleCancel}
			handleConfirm={handleConfirmCancel}
			message={t('cancel-task')}
		/>
	);
};

export default Message;

Message.propTypes = {
	taskId: PropTypes.string,
	handleCancel: PropTypes.func,
	handleConfirmCancel: PropTypes.func,
};

const ConfirmMessage = (props) => {
	const {
		taskId,
		message,
		handleConfirm,
		handleCancel
	} = props;

	return (
		<motion.div
			key={`cancel-socket-${taskId}`}
			className="confirm-message-socket"
			{...opacityAnimation}
			transition={transition}
		>
			{message}
			<div className="socket-actions-wrapper">
				<button
					className="cancel-icon"
					onClick={handleCancel}
				>
					<FontAwesomeIcon icon={faXmark} />
				</button>
				<button
					className="confirm-icon"
					onClick={handleConfirm}
				>
					<FontAwesomeIcon icon={faCheck} />
				</button>
			</div>
		</motion.div>
	);
};

ConfirmMessage.propTypes = {
	taskId: PropTypes.string,
	message: PropTypes.string,
	handleCancel: PropTypes.func,
	handleConfirm: PropTypes.func,
};