import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { ItemIcon } from '../Search/NavItem';
import { hasValue, valueOrOption } from '../../../../general/GeneralUtilities';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import { CustomTooltip } from '../../../../Templates/cells';

import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { AnimatePresence, motion } from 'framer-motion';
import { opacityAnimation } from '../../../../form/Permission/Utils';
import SubTitle from '../../../../layout/Subtitle';

const Header = ({
	withCloseButton = true,
	customTitle = ''
} = {}) => {

	const { t } = useTranslation();
	const {
		title,
		subMenu,
		navigateInside,
		isSubmenu,
		forceHide,
		withBackToMain
	} = useMegaMenu();

	const handleEsc = (e) => {
		const code = e?.keyCode?.toString();
		if (parseInt(code) === 27) {
			forceHide();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleEsc);

		return () => document.removeEventListener("keydown", handleEsc);
		//eslint-disable-next-line
	}, []);

	const onReturn = () => {
		if (!isSubmenu) {
			return null;
		}
		navigateInside(null);
	};

	const label = hasValue(customTitle) ? customTitle : valueOrOption(subMenu?.title, title);

	return (
		<AnimatePresence mode='wait'>
			<Grid
				container
				spacing={0}
				key={`mega-menu-header-${label}`}
				component={motion.div}
				{...opacityAnimation}
				transition={{ duration: 0.3 }}
				{...isSubmenu ? {} : {
					display: 'flex',
					alignItems: 'baseline'
				}}
			>
				{withCloseButton &&
					<Grid size={12}>
						<Stack spacing={0} direction="row" justifyContent={"flex-end"}>
							<IconButton aria-label="close" onClick={forceHide} sx={{ padding: "8px 12px" }}>
								<FontAwesomeIcon icon={faXmark} className="closeModalIcon" />
							</IconButton>
						</Stack>
					</Grid>
				}
				<Grid size={11} sx={{ display: 'flex', alignItems: 'center' }}>
					{withBackToMain && isSubmenu && (
						<CustomTooltip
							title={t("return")}
							className="menu-item-tooltip"
							placement="right"
						>
							<span className='megamenu-return' onClick={onReturn}>
								<ItemIcon icon={'go-back'} />
							</span>
						</CustomTooltip>
					)}
					<MenuTitle
						isSubmenu={isSubmenu}
						label={label}
						onReturn={onReturn}
					/>
				</Grid>
			</Grid>
		</AnimatePresence>
	);
};

const MenuTitle = ({
	isSubmenu,
	label,
	onReturn
} = {}) => {
	return (
		isSubmenu ?
			<span
				className={`megamenu-title ${isSubmenu ? "pointer" : ""}`}
				onClick={onReturn}
			>
				{label}
			</span>
			:
			<Grid container spacing={1} size={12}>
				<SubTitle
					asV2
					asDivider
					title={label}
					titleStyle={{ fontSize: '1.4em !important' }}
				/>
			</Grid>
	);
}

export default Header;