import { lazy } from 'react';

const payrollsIcons = {
	alimony: {
		allowedTag: "Alimony",
		Icon: lazy(async () => await import("./Alimony.svg")),
	},
	loansandpaids: {
		allowedTag: "Loans-And-Paids",
		Icon: lazy(async () => await import("./LoansAndPaids.svg")),
	},
	payrolldispersion: {
		allowedTag: "Payroll-Dispersion",
		Icon: lazy(async () => await import("./PayrollDispersion.svg")),
	},
	payrollkardex: {
		allowedTag: "Payroll-Kardex",
		Icon: lazy(async () => await import("./PayrollKardex.svg")),
	},
	periodtotals: {
		allowedTag: "Period-Totals",
		Icon: lazy(async () => await import("./PeriodTotals.svg")),
	},
	scheduledconcepts: {
		allowedTag: "Scheduled-Concepts",
		Icon: lazy(async () => await import("./ProgramedConcepts.svg")),
	},
	voucherdispersion: {
		allowedTag: "Voucher-Dispersion",
		Icon: lazy(async () => await import("./VoucherDispersion.svg")),
	},
	provisionpolicy: {
		allowedTag: "Provision-Policy",
		Icon: lazy(async () => await import("./ProvisionPolicy.svg")),
	},
	payrollpolicy: {
		allowedTag: "Payroll-Policy",
		Icon: lazy(async () => await import("./PayrollPolicy.svg")),
	},
	lock: {
		allowedTag: "Lock",
		Icon: lazy(async () => await import("./Lock.svg")),
	},
	unlock: {
		allowedTag: "Unlock",
		Icon: lazy(async () => await import("./Unlock.svg")),
	},
	payrolldelete: {
		allowedTag: "Payroll-Delete",
		Icon: lazy(async () => await import("./PayrollDelete.svg")),
	},
	reset: {
		allowedTag: "Reset",
		Icon: lazy(async () => await import("./Reset.svg")),
	},
	updatepaytype: {
		allowedTag: "Update-Pay-Type",
		Icon: lazy(async () => await import("./UpdatePayType.svg")),
	},
};

export default payrollsIcons;