import { lazy } from 'react';

const applicationsIcons = {
	training: {
		allowedTag: "Training",
		Icon: lazy(async () => await import("./Capacitacion.svg")),
	},
	contractsandformats: {
		allowedTag: "Contracts-And-Formats",
		Icon: lazy(async () => await import("./ContractsAndFormats.svg")),
	},
	fileexplorer: {
		allowedTag: "File-Explorer",
		Icon: lazy(async () => await import("./FileExplorer.svg")),
	},
	kiosk: {
		allowedTag: "Kiosk",
		Icon: lazy(async () => await import("./Kiosco.svg")),
	},
	kiosko: {
		allowedTag: "Kiosko",
		Icon: lazy(async () => await import("./Kiosco.svg")),
	},
	mopers: {
		allowedTag: "Mopers",
		Icon: lazy(async () => await import("./Mopers.svg")),
	},
	humanresources: {
		allowedTag: "Human-Resources",
		Icon: lazy(async () => await import("./HumanResources.svg")),
	},
	clock: {
		allowedTag: "Clock",
		Icon: lazy(async () => await import("./Reloj.svg")),
	},
	systems: {
		allowedTag: "Systems",
		Icon: lazy(async () => await import("./Sistemas.svg")),
	},
};

export default applicationsIcons;