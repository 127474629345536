import { config } from "./config";
import axios from "axios";
import {
  ParseParams,
  ServerAPI,
  getUrl
} from "./ServerAPI";

/**
  all principal methods -> (get, post, put, destroy)
  @example usersApi.get(params)
*/
export const RolesUsersApi = ServerAPI("roles-users");
export const workgroupsApi = ServerAPI("workgroups", true, true);
export const generateStructuresApi = ServerAPI("workgroups/generate");
export const generateAdminApi = ServerAPI("workgroups/generate-admins");
export const usersWorkGroupsApi = ServerAPI("users-workgroups");
export const getUserWorkgroupsAPI = ServerAPI("attendance-list/get-user-workgroups", true, true);
export const getUserBranchesAPI = ServerAPI("attendance-list/get-user-branches-attendance", true, true);
export const getUserEmployerRegistrationAPI = ServerAPI("attendance-list/get-user-employer-registration-attendance");
export const moperApi = ServerAPI("moper");
export const flowSigantureTemplateApi = ServerAPI("flow-signature-template");
export const userModulePermissionsApi = ServerAPI('user-modules');
export const userGroupsListAPI = ServerAPI('user-groups/list');
export const multiCreateUsers = ServerAPI('users/multi-create-user-workers');
export const userOptionsApi = () => {
  const get = () => {
    const payload = ParseParams({ include_config: true, include_tenant: false });
    const route = getUrl("app-user-options", false);
    return axios.get(route, payload).then(response => {
      return response.data[0] ?? {
        "corporates": [],
        "companies": [],
        "roles": []
      };
    });
  };

  return {
    get: get
  };
};

const baseName = "users";

const baseUsers = ServerAPI(baseName);

const changePassword = (params) => {
  const url = getUrl(`${baseName}/update-password`);
  const payload = ParseParams({ params });
  return axios.post(url, payload, config()).then((response) => response.data);
};

const getPublicUsers = (params) => {
  const url = getUrl(baseName);
  const payload = ParseParams({ params, include_tenant: false });
  return axios.get(url, config(payload)).then(response => response.data);
}

const validateEmail = (params) => {
  const url = getUrl(`${baseName}/valid-email`);
  const payload = ParseParams({ params, include_tenant: false });
  return axios.get(url, config(payload)).then(response => response.data);
}

export const usersApi = {
  ...baseUsers,
  getPublicUsers,
  changePassword,
  validateEmail,
};