import { isNil, omitBy, forEach, isDate, isString, size, isNumber } from "lodash";
import { t } from 'i18next';
import { counterInputs } from "./constants";
import { hasValue, parseDatetime } from "../../../../../general/@components/general/GeneralUtilities";
import { accessOptions } from "./dictionaries";

// Filters
export const filtersCounter = (filter) => {
  const filters = filter ?? filter?.filter;
  let realCounter = size(omitBy(Object.fromEntries(
    Object.entries(filters).map(([key, value]) => [
      key,
      counterInputs.includes(key) ? value : null,
    ])
  ), isNil));
  if (filters?.active_date) {
    realCounter = realCounter + 1;
    if (hasValue(filter?.initial_date)) {
      realCounter = realCounter + 1;
    }

    if (hasValue(filter?.end_date)) {
      realCounter = realCounter + 1;
    }
  }
  if (filters?.indicate_days !== 'ALL' && filters?.indicate_days) {
    realCounter = realCounter + 1;
  }

  return realCounter;
}

// Modals

export const getCatalog = (catalogsConfig, catalogRef) => {
  const {
    area,
    sub_area,
    sub_branch,
    department,
    sub_department,
  } = catalogsConfig ?? {};

  const catalogs = {
    sub_department,
    department,
    sub_area,
    area,
    sub_branch,
  }

  for (let option in catalogs) {
    if (catalogs[option]?.value) {
      catalogRef.current = option;
    }
  }

  return catalogRef.current;
}

export const getPrintOptions = ({ catalog, workerTypes, userWorkgroups }) => {
  let wTypes = []
  if (size(workerTypes)) {
    forEach(workerTypes, function (type) {
      const wType = {};
      wType.label = type.name;
      wType.name = type.name;
      wType.type = 'switch';
      wType.id = type.id;
      wTypes.push(wType);
    });
  }

  return {
    print_options_1: {
      print_type: [
        { label: t('Lista completa'), name: 'complete_list', type: 'switch' },
        { label: t('Lista visualizada'), name: 'visualized_list', type: 'switch' },
        { label: t('Lista personalizada'), name: 'personalized_list', type: 'switch' },
      ],
      list_content: [
        { label: t('Con datos'), name: 'with_data', type: 'switch' },
      ],
      worker_signature: [
        { label: t('Imprimir linea'), name: 'print_signature', type: 'switch' },
      ],
      worker_type: [
        { label: t('Todos'), name: 'all', type: 'switch' },
        ...wTypes,
      ]
    },
    print_options_2: {
      dates: [
        { label: t('initial_date'), name: 'initial_date', type: 'date' },
        { label: t('end_date'), name: 'final_date', type: 'date' },
      ],
      structure: [
        { label: t('employer-registration'), name: 'employer_registration', type: 'structuresearch', api: 'employer-registration' },
        { label: t('branches'), name: 'branch', type: 'structuresearch', api: 'branch' },
        { label: t('workgroup'), name: 'workgroup', type: 'multiselect', data: userWorkgroups },
        // { ...(catalog ? { label: t(catalog), name: catalog, type: 'structuresearch', api: catalog } : {}) },
      ],
      separate_by: [
        { label: t('employer-registration'), name: 'employer_registration', type: 'switch' },
        { label: t('branch'), name: 'branch', type: 'switch' },
        { label: t('workgroup'), name: 'workgroup', type: 'switch' }
        // { ...(catalog ? { label: t(catalog), name: catalog, type: 'switch' } : {}) },
      ],
      order: [
        { label: t('employer-registration'), name: 'employer_registration', type: 'switch' },
        { label: `${t('employer-registration')}-${t('branch')}`, name: 'employer_registration_branch', type: 'switch' },
        { ...(catalog ? { label: `${t('employer-registration')}-${t('branch')}-${t(catalog)}`, name: `employer_registration_branch_${catalog}`, type: 'switch' } : {}) },
      ]
    }
  }
}

// DateFormat

export const formatDate = (date, useIso = false) => {
  let newDate = null;
  if (isDate(date)) {
    return date;
  }
  if (isString(date)) {
    newDate = parseDatetime(date, 'asDate', useIso);
  }
  return newDate;
}

export const resetFilters = (filters, access) => {
  const newFilters = Object.fromEntries(
    Object.entries(filters).map(([key, value]) => [
      key,
      counterInputs.includes(key) ? null : value,
    ])
  );

  if (access) {
    delete newFilters.employer_registration;
    delete newFilters.employer_registration;
  }

  return newFilters;
}

export const accessMode = (value) => {
  return (value === accessOptions.all || value === accessOptions.workgroup);
}

export const structureFilters = (structure, allFilters) => {
  const filters = {};

  structure?.forEach(element => {
    const fieldValue = allFilters?.[element?.key];
    if (hasValue(fieldValue)) {
      filters[element?.key] = fieldValue;
    }
  });

  return filters;
}

export const withCustomItem = (data, custom) => {
  if (custom) {
    return true;
  }

  const emptyItem = data.find(element => element?.id === 0)?.id;
  return !isNumber(emptyItem);
}
