import React from 'react';
import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { uid } from 'uid';
import { t } from 'i18next';
import {
    isArray, isObject
} from "lodash";

import "./style.scss";
import { getLabel } from '../modal/WarningRowChange/change';
import { hasValue } from '../general/GeneralUtilities';
const ErrorsList = (props) => {
    return (
        <div className="ErrorsList">
            <PerfectScrollbar className={"ErrorsListScrollbar"}>
                <UlElement {...props} />
            </PerfectScrollbar>
        </div>
    );
};


export default ErrorsList;

export const UlElement = ({
    errors,
    fieldsLabels
}) => {

    const { t } = useTranslation();
    const asArray = isArray(errors);
    const errorsKeys = asArray ? errors : Object.keys(errors ?? {});

    if (errorsKeys.length === 1 && asArray) {
        const errorValue = errors[0];
        if (!(isArray(errorValue) || (isObject(errorValue) && !errorValue?.message))) {
            return errorValue?.message ?? errorValue ?? "";
        }
    }

    return (
        <ul>
            {errorsKeys.map((itemKey, index) => (
                <LiElement
                    key={index}
                    itemKey={asArray ? `${t("index-position")} ${index + 1}` : itemKey}
                    value={asArray ? itemKey : errors[itemKey]}
                    fieldsLabels={fieldsLabels}
                />
            ))}
        </ul>
    );
};

export const LiElement = ({
    itemKey,
    value,
    fieldsLabels = {}
}) => {

    let label = itemKey.includes(" ") ? itemKey : itemKey.split(".").slice(-1)[0];
    label = getLabelError(label, fieldsLabels);

    return (
        <li>
            <strong className="capitalize">
                {label}
            </strong>
            {hasValue(label) ? " - " : ""}
            {
                isArray(value) || (isObject(value) && !value?.message)
                    ? <UlElement errors={value} fieldsLabels={fieldsLabels} />
                    : value?.message ?? value ?? ""
            }
        </li>
    );
};

const getLabelError = (itemKey, fieldsLabels) => {
    const parsedKey = itemKey.includes(" ") ? itemKey : itemKey.split(".").slice(-1)[0];
    if (parsedKey === 'detail' || parsedKey === 'non_field_errors') {
        return '';
    }

    return getLabel(null, parsedKey, fieldsLabels);
};

export const errorIncidenceArr = (data, title = 'incidences') => {
    if (!hasValue(data)) {
        return;
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: '10px', paddingLeft: '30px' }}>
            <span style={{ display: 'flex', padding: '0' }} className="dialogMessage">{t(title)}</span>
            {data?.map(element => {
                return (
                    <span key={uid()} style={{ display: 'flex' }}>{`· ${element}`}</span>
                );
            })}
        </div>
    )
}