export const aditionalsData = {
    aditionals_modules: [
        {
            "element_id": -1,
            "key": "modules@modules@dining-room",
            "name": "Comedor",
            "path": "modules/dining-room",
            "is_subitem": true,
            "icon_name": "Dinning",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Comedor",
            "type": "item",
            "id": "modules@dining-room",
            "is_accesible": false
        },
        {
            "element_id": -9,
            "key": "modules@modules@Staffing",
            "name": "Staffing",
            "path": "modules/Staffing",
            "is_subitem": true,
            "icon_name": "Staffing",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Staffing",
            "type": "item",
            "id": "modules@Staffing",
            "is_accesible": false
        },
        {
            "element_id": -2,
            "key": "modules@modules@Propinas",
            "name": "Propinas",
            "path": "modules/Propinas",
            "is_subitem": true,
            "icon_name": "Tips",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Propinas",
            "type": "item",
            "id": "modules@Propinas",
            "is_accesible": false
        },
        {
            "element_id": -10,
            "key": "modules@modules@Externos",
            "name": "Externos",
            "path": "modules/Externos",
            "is_subitem": true,
            "icon_name": "External",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Externos",
            "type": "item",
            "id": "modules@Externos",
            "is_accesible": false
        },
        {
            "element_id": -3,
            "key": "modules@modules@Prácticas-internas",
            "name": "Prácticas internas",
            "path": "modules/Prácticas-internas",
            "is_subitem": true,
            "icon_name": "Internal-Training",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Prácticas internas",
            "type": "item",
            "id": "modules@Prácticas-internas",
            "is_accesible": false
        },
        {
            "element_id": -11,
            "key": "modules@modules@Eventos",
            "name": "Eventos",
            "path": "modules/Eventos",
            "is_subitem": true,
            "icon_name": "Events",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Eventos",
            "type": "item",
            "id": "modules@Eventos",
            "is_accesible": false
        },
        {
            "element_id": -4,
            "key": "modules@modules@Resguardos",
            "name": "Resguardos",
            "path": "modules/Resguardos",
            "is_subitem": true,
            "icon_name": "Safeguards",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Resguardos",
            "type": "item",
            "id": "modules@Resguardos",
            "is_accesible": false
        },
        {
            "element_id": -12,
            "key": "modules@modules@Lockers",
            "name": "Lockers",
            "path": "modules/Lockers",
            "is_subitem": true,
            "icon_name": "Lockers",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Lockers",
            "type": "item",
            "id": "modules@Lockers",
            "is_accesible": false
        },
        {
            "element_id": -5,
            "key": "modules@modules@Viviendas",
            "name": "Viviendas",
            "path": "modules/Viviendas",
            "is_subitem": true,
            "icon_name": "Housing",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Viviendas",
            "type": "item",
            "id": "modules@Viviendas",
            "is_accesible": false
        },
        {
            "element_id": -13,
            "key": "modules@modules@Briefing",
            "name": "Briefing",
            "path": "modules/Briefing",
            "is_subitem": true,
            "icon_name": "Breafing",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Briefing",
            "type": "item",
            "id": "modules@Briefing",
            "is_accesible": false
        },
        {
            "element_id": -6,
            "key": "modules@modules@Uniformes",
            "name": "Uniformes",
            "path": "modules/Uniformes",
            "is_subitem": true,
            "icon_name": "Uniforms",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Uniformes",
            "type": "item",
            "id": "modules@Uniformes",
            "is_accesible": false
        },
        {
            "element_id": -14,
            "key": "modules@modules@Control-de-presupuestos",
            "name": "Control de presupuestos",
            "path": "modules/Control-de-presupuestos",
            "is_subitem": true,
            "icon_name": "Budget-Control",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Control de presupuestos",
            "type": "item",
            "id": "modules@Control de presupuestos",
            "is_accesible": false
        },
        {
            "element_id": -7,
            "key": "modules@modules@Prorrateo-Staffing",
            "name": "Prorrateo Staffing",
            "path": "modules/Prorrateo-Staffing",
            "is_subitem": true,
            "icon_name": "Apportionment",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Prorrateo Staffing",
            "type": "item",
            "id": "modules@Prorrateo-Staffing",
            "is_accesible": false
        },
        {
            "element_id": -15,
            "key": "modules@modules@Cálculo-de-presupuestos",
            "name": "Cálculo de presupuestos",
            "path": "modules/Cálculo-de-presupuestos",
            "is_subitem": true,
            "icon_name": "Budget-Calculation",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Cálculo de presupuestos",
            "type": "item",
            "id": "modules@Cálculo-de-presupuestos",
            "is_accesible": false
        },
        {
            "element_id": -8,
            "key": "modules@modules@Prorrateo-de-nómina",
            "name": "Prorrateo de nómina",
            "path": "modules/Prorrateo-de-nómina",
            "is_subitem": true,
            "icon_name": "Apportionment",
            "is_active": true,
            "show_in_perms": false,
            "show_in_menu": true,
            "title": "Prorrateo de nómina",
            "type": "item",
            "id": "modules@Prorrateo-de-nómina",
            "is_accesible": false
        },
    ]
};