import { lazy } from 'react';

const fileIcons = {

	"pdficon": {
		allowedTag: "pdficon",
		Icon: lazy(async () => await import("./PDF.svg")),
	},
	"xlsxicon": {
		allowedTag: "xlsxicon",
		Icon: lazy(async () => await import("./XLSX.svg")),
	},
};

export default fileIcons;
