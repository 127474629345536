import React, { useEffect, useState } from 'react';
import Card from '../../../../../../../../../general/@components/general/Popup/components/Card';
import Grid from '@mui/material/Grid2';
import { mopersHistoryAPI, overtimeMopersAPI } from '../../../../../../../../../general/services/mopers';
import {
	formatOvertimeResponse,
} from '../../../../../../../moper/MOPERSByWorker/components/container/Overtime/hooks/useGrid';
import { first } from 'lodash';
import Flag from '../../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Flags';
import {
	getChecksIndicators,
	getChecksStatus,
	WorkingDayContent,
} from '../../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Cards/WorkingDay';
import DragCheckController
	from '../../../../../../../moper/MOPERSByWorker/components/container/Overtime/hooks/useDragCheck';
import ModalsController from '../../../../../../../moper/MOPERSByWorker/components/container/Overtime/hooks/useModals';
import Request
	from '../../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Cards/OvertimeContent/components/Request';
import PaymentTypes
	from '../../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Cards/OvertimeContent/components/PaymentTypes';
import {
	DayCalcType,
} from '../../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Cards/Date';
import IconUse from '../../../../../../../../../general/@components/uiDesign/IconUse';
import useRequestLoad from '../../../../../../../../../general/services/useRequestLoad';
import Loader from '../../../../../../../../../general/@components/general/Loader/Loader';
import GenericLabel from '../../../../../../../../../general/@components/general/GenericLabel/GenericLabel';

function Overtime({ request, payrollPeriod }) {
    const originDay = request?.exactMoper?.calculated?.origin_day ?? request?.exactMoper?.origin_day;
    const worker = request?.exactMoper?.worker?.id ?? request?.exactMoper?.worker;
    const [fetch, loading] = useRequestLoad(true);
    const [fetchHistoric, loadingHistoric] = useRequestLoad(true);
    const [item, setItem] = useState(null);
    const [history, setHistory] = useState(null);

    const overtimeItem = item?.overtime;
    const values = getChecksStatus(item?.checks);
    const flagOptions = getChecksIndicators(values);

    useEffect(() => {
        getOvertimeData();
    }, [])

    const getOvertimeData = () => {
        fetch({
            api: overtimeMopersAPI.get({
                initial_date: originDay,
                end_date: originDay,
                worker
            }),
            callback: (data) => {
                const res = formatOvertimeResponse(data);
                setItem(first(res));
            }
        });
        if (!payrollPeriod) return;
        fetchHistoric({
            api: mopersHistoryAPI.overtime({
                worker,
                payroll_period: payrollPeriod,
                origin_day: originDay
            }),
            callback: setHistory
        });
    }

    return (
        <ModalsController worker={worker}>
            <DragCheckController>
                <Grid size={12}>
                    <DayCalcType
                        item={overtimeItem}
                        style={{ position: 'relative', justifyContent: 'flex-start' }}
                    />
                </Grid>
                <Card style={{ padding: '5px' }}>
                    <Grid
                        container
                        style={{
                            position: 'relative',
                            minWidth: '300px',
                            padding: '10px',
                            minHeight: '98px',
                            display: 'flex'
                        }}
                    >
                        {(!loading && overtimeItem) ?
                            <Grid size={12} style={{ padding: '5px' }}>
                                {flagOptions.map(el => <Flag key={el.id} {...el} />)}
                                <WorkingDayContent
                                    menu={false}
                                    drag={false}
                                    item={overtimeItem}
                                />
                            </Grid>
                            :
                            <Loader />}
                    </Grid>
                </Card>
                <Card style={{ padding: '5px' }}>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            padding: '10px',
                            minWidth: '331px',
                            minHeight: '116px'
                        }}
                    >
                        {(!loading && overtimeItem) ?
                            <>
                                <Grid size={6}>
                                    <PaymentTypes
                                        item={overtimeItem}
                                        onlyRead
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <Request
                                        item={overtimeItem}
                                        showDetails={false}
                                        byCheck={(overtimeItem?.type_calculation ?? 1) === 1}
                                        onlyRead
                                    />
                                </Grid>
                            </>
                            : <Loader />}
                    </Grid>
                </Card>
                {!!payrollPeriod &&
                    <Card style={{ padding: '5px' }}>
                        <Grid
                            container
                            spacing={1}
                            style={{ padding: '10px' }}
                        >
                            {!loadingHistoric ?
                                <Grid container size={12}>
                                    <Grid size={12}>
                                        <GenericLabel
                                            label={'Hrs último año'}
                                            value={history?.sum_anual}
                                            fontSize="14px"
                                            labelIcon={<IconUse icon="Asigne-Structure" style={{ color: 'var(--egyptianBlue)' }} />}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <GenericLabel
                                            label={'Hrs últimos 30 días'}
                                            value={history?.sum_mens}
                                            fontSize="14px"
                                            labelIcon={<IconUse icon="Asigne-Structure" style={{ color: 'var(--egyptianBlue)' }} />}
                                        />
                                    </Grid>
                                </Grid>
                                : <Loader />}
                        </Grid>
                    </Card>}
            </DragCheckController>
        </ModalsController>
    );
};

export default Overtime;