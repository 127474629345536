import { lazy } from 'react';

const notificationIcons = {
	error: {
		allowedTag: "Error",
		Icon: lazy(async () => await import("./error.svg")),
    },
    indeterminate: {
		allowedTag: "Indeterminate",
		Icon: lazy(async () => await import("./indeterminate.svg")),
    },
    info: {
		allowedTag: "Info",
		Icon: lazy(async () => await import("./info.svg")),
    },
    check: {
		allowedTag: "Check",
		Icon: lazy(async () => await import("./check.svg")),
    },
    question_mark: {
		allowedTag: "QuestionMark",
		Icon: lazy(async () => await import("./question_mark.svg")),
    },
    warning: {
		allowedTag: "Warning",
		Icon: lazy(async () => await import("./warning.svg")),
    },
    slimclose: {
		allowedTag: "SlimClose",
		Icon: lazy(async () => await import("./close.svg")),
	},
};

export default notificationIcons;