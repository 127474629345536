import { lazy } from "react";

const mirrorItems = [
    {
        id: 'abrhil@payroll-types',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/payroll/PayrollTypes')),
    },
    {
        id: 'abrhil@federal-entities-taxes',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/payroll/FederalEntitiesTaxes')),
    },
    {
        id: 'modules@federal-entities-client',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/payroll/FederalEntitiesTaxes')),
    },
    {
        id: 'modules@federal-entities-client',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/payroll/FederalEntitiesTaxes')),
    },
    {
        id: 'abrhil@tax-tables',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(() => import('../../../../../modules/mirror/payroll/Taxes')),
    },
    {
        id: 'modules@tax-tables-mirror',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(() => import('../../../../../modules/mirror/payroll/Taxes')),
    },
    {
        id: 'abrhil@child-support',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/payroll/CatAlimony'))
    },
    {
        id: 'modules@child-support-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/payroll/CatAlimony')),
    },
    {
        id: 'abrhil@zipcode',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/PostalCode')),
    },
    {
        id: 'modules@zipcode-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/PostalCode')),
    },
    {
        id: 'abrhil@coworker-type',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.worker_type"],
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/WorkerType')),
    },
    {
        id: 'modules@coworker-type-mirror',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.worker_type"],
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/WorkerType')),
    },
    {
        id: 'abrhil@job-position-type',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position_type"],
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/JobPositionType')),
    },
    {
        id: 'modules@job-position-type-mirror',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position_type"],
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/JobPositionType')),
    },
    {
        id: 'abrhil@daily-minimun-salary',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/DailyMinimum')),
    },
    {
        id: 'modules@daily-minimun-salary-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/DailyMinimum')),
    },
    {
        id: 'abrhil@professions',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/Professions')),
    },
    {
        id: 'modules@professions-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/Professions')),
    },
    {
        id: 'abrhil@profesional-minimun-salary',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/ProfessionalMinimum')),
    },
    {
        id: 'modules@position-professional-salary',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/ProfessionalMinimum')),
    },
    {
        id: 'modules@profesional-minimun-salary-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/ProfessionalMinimum')),
    },
    {
        id: 'abrhil@uma',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/UMA')),
    },
    {
        id: 'modules@uma-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/UMA')),
    },
    {
        id: 'abrhil@umi',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/UMI')),
    },
    {
        id: 'modules@umi-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/culture-people/UMI')),
    },
    {
        id: 'abrhil@quota-imss',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/imss/QuotaIMSS'))
    },
    {
        id: 'modules@quota-imss-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/imss/QuotaIMSS')),
    },
    {
        id: 'abrhil@payment-percentage-history',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/imss/HistoryCyV')),
    },
    {
        id: 'modules@payment-percentage-history-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/imss/HistoryCyV')),
    },
    {
        id: 'abrhil@imss-reason-leaving',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/imss/ImssReasonLeaving')),
    },
    {
        id: 'modules@imss-reason-leaving-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/imss/ImssReasonLeaving')),
    },
    {
        id: 'abrhil@calendar-holidays',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/attendance/CalendarHolidays')),
    },
    {
        id: 'abrhil@absenteeism-reasons',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/absenteeism/AbsenteeismReasons'))
    },
    {
        id: 'modules@absenteeism-reasons-mirror',
        type: 'item',
        componentElm: lazy(() => import('../../../../../modules/mirror/absenteeism/AbsenteeismReasons')),
    },
    {
        id: "abrhil@status",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(() => import('../../../../../modules/mirror/training/StatusTraining')),
    },
    {
        id: 'modules@status-mirror',
        type: 'item',
        package_permissions: ["training"],
        componentElm: lazy(() => import('../../../../../modules/mirror/training/StatusTraining')),
    },
    {
        id: "abrhil@courses-classifications",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(() => import('../../../../../modules/mirror/training/CoursesClassifications')),
    },
    {
        id: 'modules@courses-classifications-mirror',
        type: 'item',
        package_permissions: ["training"],
        componentElm: lazy(() => import('../../../../../modules/mirror/training/CoursesClassifications')),
    },
];

export default mirrorItems;