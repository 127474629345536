import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { initialPage } from '../../../../../general/@components/table/utils/GridCustomProps';
import { TabsContext } from '../contexts/TabsContext';

function useTabs() {
    const [tab, setTab] = useState(0);
    const [page, setPage] = useState(initialPage);
    const [total, setTotal] = useState(0);
    const [showTabs, setShowTabs] = useState(false);
    const [showPager, setShowPager] = useState(false);

    const onTabChange = (value) => setTab(value);

    const onPageChange = e => setPage({ take: e.take, skip: e.skip });

    const resetTabs = () => setTab(0);
    const resetPage = () => setPage(initialPage);

    return {
        tab,
        page,
        total,
        showTabs,
        showPager,
        setTotal,
        resetTabs,
        resetPage,
        onPageChange,
        onTabChange,
        setShowTabs,
        setShowPager,
    };
};

export const useTabsContext = () => useContext(TabsContext);

export default function TabsController({
    children,
}) {
    const tabValues = useTabs();

    return (
        <TabsContext.Provider value={tabValues}>
            {children}
        </TabsContext.Provider>
    );
};

TabsController.propTypes = {
    children: PropTypes.any
};