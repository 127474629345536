import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';
import '../style.scss';

function CardsPopup({
    children
}) {

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            className="sbw-cards-popup-draft"
        >
            {children}
        </Grid>
    );
};

export default CardsPopup;

CardsPopup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ])
};