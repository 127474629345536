import i18next from 'i18next';
import { httpError } from '../requests/http-errors';
import { isFunction } from 'lodash';
import { logout } from '../general/auth';

const { t } = i18next;

/* exclusivo para calculos de nomina */
export function successExecute(message, detail, onConfirm) {
  return {
    title: t('success-general-title'),
    message: t(`payroll-responses:${message ?? "success"}`),
    description: detail,
    maxWidth: "sm",
    onConfirm: onConfirm
  };
}
/* exclusivo para calculos de nomina */
export function failedExecute(message, detail) {
  return {
    message: t(`payroll-responses:${message}`),
    description: detail,
    maxWidth: "sm"
  };
}

export function successCreated() {
  return {
    title: t('success-general-title'),
    message: t('success-created-message'),
    description: t('success-general-description'),
  };
}

export function successUpdated() {
  return {
    title: t('success-general-title'),
    message: t('success-updated-message'),
    description: t('success-general-description'),
  };
}

export function successCanceled() {
  return {
    title: t('success-general-title'),
    message: t('success-canceled-message'),
    description: t('success-general-description'),
  };
}

export function successDeleted() {
  return {
    title: t('success-general-title'),
    message: t('success-deleted-message'),
    description: t('success-general-description'),
  };
}

export function errorGet(error) {
  return {
    title: t('error-general-title'),
    message: httpError(error, t('error-get-message')),
    description: t('error-general-description'),
  };
}

export function errorCreate(error) {
  return {
    title: t('error-general-title'),
    message: httpError(error, t('error-create-message')),
    description: t('error-general-description'),
  };
}

export function errorEdit(error) {
  return {
    title: t('error-general-title'),
    message: httpError(error, t('error-edit-message')),
    description: t('error-general-description'),
  };
}

export function errorDelete(error) {
  return {
    title: t('error-general-title'),
    message: httpError(error, t('error-delete-message')),
    description: t('error-general-description'),
  };
}

export function errorTenant() {
  return {
    title: t('warning-general-title'),
    message: t('error-tenant-message'),
    description: ""//t('error-general-description'),
  };
}

export function questionDelete(context, onConfirm, item) {
  if (typeof context !== "string") {
    context = "";
  }
  return {
    maxWidth: "sm",
    title: t('question-delete-title'),
    message: t('question-delete-message', { context: (context ?? "").toUpperCase() }),
    description: t('question-delete-description'),
    onConfirm: isFunction(onConfirm) ? onConfirm : () => alert("delete action not specified"),
    item: item
  };
}

export function errorInJS(error) {
  return {
    title: t('error-general-title'),
    message: error?.message,
    description: '',
  };
}

export function serviceUnavailable() {
  return {
    title: t('error-general-title'),
    message: t('service-unavailable'),
    description: '',
  };
}

export function warningWorker(worker) {
  return {
    title: t('warning-general-title'),
    message: t('validation:custom.warning-worker-message', { worker }),
    description: t('warning-worker-description'),
  };
}

export function warningCustomize(paramTitle, paramMsg, paramDesc) {
  return {
    title: paramTitle,
    message: paramMsg,
    description: paramDesc,
  };
}

export function deleteControlledError(error) {
  return {
    fields: "",
    general: error?.response?.data,
  };
}


export function createEditControlledError(error) {

  let dataErr = [];
  let itemData;

  if (Array.isArray(error?.response?.data)) {

    dataErr = error?.response?.data;

    dataErr.forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(dataErr, 'non_field_errors')) {
        if (item?.non_field_errors !== undefined) {
          itemData = item?.non_field_errors;
        }
      } else {
        if (Object.keys(item).length !== 0) {
          itemData = item;
        }
      }
    });
  } else {
    itemData = error?.response?.data;
  }

  return {
    fields: itemData,
    general: "",
  };
}

export const unauthorizedError = (code) => {
  const tokenCode = "token_not_valid";
  const isTokenExpired = tokenCode === code;
  const needsLogout = [
    "user_inactive",
    "worker_inactive",
    tokenCode,
  ].includes(code);
  return {
    maxWidth: "sm",
    title: t(isTokenExpired ? "token-expired-title" : "unauthorized-title"),
    message: t(isTokenExpired ? "token-expired-message" : "unauthorized-message"),
    description: t(isTokenExpired ? "token-expired-detail" : "unauthorized-detail"),
    onConfirm: () => needsLogout ? logout() : location.reload(true),
  };
}

export function questionDisperse(context, onConfirm, item) {
  return {
    title: t('question-disperse-title'),
    message: t('question-disperse-message', { context: context.toLowerCase() }),
    description: '',
    onConfirm: typeof onConfirm === "function" ? onConfirm : null,
    item: item
  };
}

export function questionExit(context, onConfirm, item) {
  return {
    title: t('question-exit-title'),
    message: t('question-exit-message', { context: context.toLowerCase() }),
    description: '',
    onConfirm: typeof onConfirm === "function" ? onConfirm : null,
    item: item
  };
}

export const windowFixedWarning = () => ({
  maxWidth: "sm",
  title: t("wait"),
  message: t("window-fixed-warning")
});

export const windowOpenWarning = () => ({
  maxWidth: "sm",
  title: t("wait"),
  message: t("many-open-windows")
});

export const windowCloseWarning = () => ({
  maxWidth: "sm",
  title: t("wait"),
  message: t("window-key-invalid")
});

export function questionCommand(context, onConfirm, item) {
  return {
    title: t('question-command-title'),
    message: t('question-command-message'),
    description: context.toUpperCase(),
    onConfirm: typeof onConfirm === "function" ? onConfirm : null,
    item: item
  };
}