import React from 'react';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid2';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";


export const ModalTitle = ({ onClose }) => {

	const { t } = useTranslation();
	return (
		<DialogTitle className="abrhil-search-dialog-title">
			<Grid
				container
				spacing={0}
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				columns={{ lg: 14, xl: 15 }}
			>
				<Grid size="grown">
					<Grid container spacing={0}>
						<Grid className="dialog-title">
							{t("search")}
						</Grid>
					</Grid>
				</Grid>
				<Grid size={{ xs: 3, sm: 2, md: 1, lg: 1, xl: 1 }} >
					<Stack spacing={1} direction="row" justifyContent={"flex-end"}>
						<IconButton aria-label="close" onClick={onClose} sx={{ padding: "8px 12px" }}>
							<FontAwesomeIcon icon={faXmark} className="closeModalIcon" />
						</IconButton>
					</Stack>
				</Grid>
			</Grid>
		</DialogTitle>
	);
};
