import React from 'react';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { hasValue, valueOrOption } from '../../../../general/GeneralUtilities';
import { concidence } from '../../../utilities';
import GridNoRecordsRender from '../../../../Templates/GridNoRecordsRender';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import { GroupElements } from './GroupElements';
import { LinkElement } from './LinkElement';
import { RootElement } from './RootElement';
import { AnimatePresence, motion } from 'framer-motion';
import { opacityAnimation } from '../../../../form/Permission/Utils';
import { SolidDivider } from '../../../../general/Dividers';

const Menu = ({
	items = [],
	withDivider = true
} = {}) => {

	const { t } = useTranslation();
	const {
		tab,
		search,
		isSubmenu,
		subMenu,
		groupsAtFirstLevel,
		elements
	} = useMegaMenu();

	let itemToShow = items;
	if (!hasValue(itemToShow))
	if (isSubmenu) {
		itemToShow = subMenu?.children?.find(item => item.name === tab);
		itemToShow = itemToShow?.children;
	} else if (groupsAtFirstLevel) {
		itemToShow = elements?.find(item => item.name === tab);
		itemToShow = itemToShow?.children;
	} else {
		itemToShow = elements;
	}

	return (
		<>
			<AnimatePresence mode='wait'>
				<Box
					key={`childrens-list-items-${tab}`}
					className={"childrens-list-items"}
					component={motion.div}
					{...opacityAnimation}
					transition={{ duration: 0.3 }}
				>
					<Masonry columns={2} spacing={2}>
						{valueOrOption(itemToShow, []).map((item, key) => {
							if (item.type === "collapse") {
								if (isSubmenu || hasValue(tab)) {
									return <GroupElements key={key} item={item} />;
								}
								return (
									<RootElement key={key} item={item} />
								);
							}
							return (hasValue(search) && !concidence(item.title, search)) ? null : (
								<div key={key} className='module-item'>
									<LinkElement item={item} />
								</div>
							);
						}).filter(item => item !== null)}
					</Masonry>
					{withDivider &&
						<Grid container spacing={0}>
							<Grid size={11} style={{ display: 'flex', alignItems: 'center' }}>
								<SolidDivider />
							</Grid>
						</Grid>
					}
				</Box>
			</AnimatePresence>
			<div className="no-items">
				<GridNoRecordsRender
					init={hasValue(search)}
					initialMsg={t("no-items-to-show")}
				/>
			</div>
		</>
	);
};

export default Menu;