import { lazy } from "react";

const attendanceMainItems = [
	{
		id: "modules@attendance",
		type: "item",
		icon: "attendance",
		componentElm: lazy(() => import("../../../../../modules/attendance-mopers-main/attendance/Attendance")),
	},
	{
		id: "modules@mopers",
		type: "item",
		icon: 'moperssign',
		componentElm: lazy(() => import("../../../../../modules/attendance-mopers-main/moper/MOPERSByWorker")),
	},
	{
		id: 'modules@mopers-signatures-general',
		type: 'item',
		icon: 'mopers',
		componentElm: lazy(() => import('../../../../../modules/attendance-mopers-main/moper/MOPERSSignature')),
	},
	{
		id: 'modules@signatures',
		type: 'item',
		icon: 'graphicsignatures',
		componentElm: lazy(() => import('../../../../../modules/attendance-mopers-main/signature/GraphicSignatures')),
	},
	{
		id: 'modules@human-resources-settings',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people-main/SettingsRH')),
	},
];

export default attendanceMainItems;