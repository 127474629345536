import React, { useEffect, useState } from "react";
import { columnProps, initialFilter, initialPage, pageable } from "../../../../../../../general/@components/table/utils/GridCustomProps";
import { resolveError } from "../../../../../../../general/@components/requests/resolve-error";
import { branchEmpAPI } from "../../../../../../../general/services/enterprise-structure";
import { parseRequest } from "../../../../../../../general/@components/requests/parse-request";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    Grid,
    GridColumn as Column,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import GridNoRecordsRender from "../../../../../../../general/@components/Templates/GridNoRecordsRender";

function AccessSU() {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState(initialFilter());
    const [pages, setPages] = useState(initialPage);
    const tenant = useSelector(state => state.tenant.current?.id);

    useEffect(() => {
        getData();
    }, [filters, pages])

    async function getData() {
        setLoading(true);
        const request = parseRequest(
            {
                tenant,
                branches_employer: true,
            },
            filters,
            pages,
        );
        try {
            const response = await branchEmpAPI.get(request);
            setTotal(response?.count);
            setData(response?.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            resolveError(error);
        }
    }

    const onPageChange = (e) => {
        setPages({
            ...pages,
            skip: e.page.skip,
            take: e.page.take
        });
    }

    const onFilterChange = (e) => {
        setFilters(() => ({
            ...e.filter
        }))
    }

    return (
        <Grid
            data={
                !loading ?
                    data
                    :
                    []
            }
            pageable={pageable(data)}
            className='access-superuser-grid'
            skip={pages.skip}
            take={pages.take}
            total={total}
            onPageChange={onPageChange}
            filter={filters}
            onFilterChange={onFilterChange}
        >
            <GridNoRecords>
                <GridNoRecordsRender init={true} filter={true} loading={loading} />
            </GridNoRecords>

            <Column
                {...columnProps("branch_key", filters, 'input')}
                width={55}
                title={t("key")}
            />
            <Column
                {...columnProps("branch_name", filters, 'input')}
                title={t("name")}
            />
            <Column
                {...columnProps("rfc", filters, 'input')}
                width={90}
                title={`${t("RFC")}`}
            />
            <Column
                {...columnProps("employer_key", filters, 'input')}
                width={85}
                title={`${t("key")} Reg. Patronal`}
            />
            <Column
                {...columnProps("employer_name", filters, 'input')}
                title={`${t("name")} Reg. Patronal`}
            />
        </Grid>
    );
};

export default AccessSU;