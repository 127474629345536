import { lazy } from 'react';

const clocksIcons = {
	audit: {
		allowedTag: "Audit",
		Icon: lazy(async () => await import("./Bitacora.svg")),
	},
	rolesandpermissions: {
		allowedTag: "Roles-and-permissions",
		Icon: lazy(async () => await import("./RolesAndPermissions.svg")),
	},
	key: {
		allowedTag: "Key",
		Icon: lazy(async () => await import("./key.svg")),
	},
	commandkey: {
		allowedTag: "Command-key",
		Icon: lazy(async () => await import("./cmKey.svg")),
	},
	expand: {
		allowedTag: "Expand",
		Icon: lazy(async () => await import("./expand.svg")),
	},
	userkey: {
		allowedTag: "Keys",
		Icon: lazy(async () => await import("./userKey.svg")),
	},
	darkmode: {
		allowedTag: "DarkMode",
		Icon: lazy(async () => await import("./darkMode.svg")),
	},
	userviewfinder: {
		allowedTag: "User",
		Icon: lazy(async () => await import("./userViewfinder.svg")),
	},
	logout: {
		allowedTag: "LogOut",
		Icon: lazy(async () => await import("./LogOut.svg")),
	},
};

export default clocksIcons;