import { lazy } from "react";

const clockItems = [
	//* CONTROL DE ACCESO *//
	{
		id: 'access-control@clocks',
		type: 'item',
		icon: 'clock',
		componentElm: lazy(() => import('../../../../../modules/clock/Clock')),
	},
	{
		id: 'access-control@clock-workers',
		type: 'item',
		icon: 'assign-clocks',
		componentElm: lazy(() => import('../../../../../modules/clock/ClockWorker')),
	},
];

export default clockItems;