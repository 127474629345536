import { lazy } from "react";

const cultureItems = [
	{
		id: 'modules@salary-adjustment',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/SalaryAdjustment')),
	},
	{
		id: 'modules@job-changes',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/JobChanges')),
	},
	{
		id: 'modules@extra-fields',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.extra_fields"],
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/ExtraData')),
	},
	{
		id: 'modules@marital-status',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/CivilStatus')),
	},
	{
		id: 'modules@education',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/EducationRH')),
	},
	{
		id: 'modules@gender',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/Gender')),
	},
	{
		id: 'modules@requirements',
		type: 'item',
		package_permissions: ["human_resource_package", "human_resource_package.extra_requirements"],
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/Requirements')),
	},
	{
		id: 'modules@reason-leaving',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/ReasonLeaving')),
	},
	{
		id: 'modules@pay-vacations',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/catalog/PayVacations')),
	},
	{
		id: 'modules@integration-factor-type',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/benefit/IntegratingFactorType')),
	},
	{
		id: 'modules@integration-factor-history',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/culture-people/benefit/IntegrationFactorHistory')),
	},
	{
		id: "modules@massive-salary-increases",
		type: "item",
		componentElm: lazy(() => import('../../../../../modules/culture-people/MassiveSalaryIncreases')),
	},
	{
		id: "modules@policies",
		type: "item",
		componentElm: lazy(() => import('../../../../../modules/culture-people/Policy')),
	},
	{
		id: 'modules@file-explorer',
		type: 'item',
		icon: "fileexplorer",
		componentElm: lazy(() => import('../../../../../modules/culture-people/FileExplorer')),
	}
];

export default cultureItems;