// @flow
import React from 'react';
import { useTranslation } from "react-i18next";
import { Checkbox } from "@progress/kendo-react-inputs";
// import type { kendoCheckProps } from './PropTypes';
import { objectValue } from '../../form/utilities';
import { KendoLabelContainer } from '../../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../../uiDesign/sizesUtilities';

/**
 * Hook que regresa el componente checkbox basado en MUi
 *
 * @returns {KendoCheckboxComponent}
 */
const KendoCheckbox = (
	{
		controlled = true,
		checked,
		onChange,
		subLabel,
		disabled = false,
		label = "",
		formAlign = true,
		className = "",
		outlined = false,
		sideLabel = false,
		modernDesign = false,
		...others
	}/* : kendoCheckProps */
) => {

	const { t } = useTranslation();
	const { size } = kendoSizeClasses(others);
	const checkedLabel = t(checked ? "active" : "inactive");
	const classLabel = `${className} chekboxContainer ${formAlign ? "AlignCheckBox" : ""} ${outlined ? 'outlined' : ''} ${modernDesign ? 'modern-design' : ''}`;
	label = label === "switchActiveInactiveLabel" ? checkedLabel : label;

	const handleChange = (event) => {
		const isCheck = event.value;
		if (typeof onChange === "function") {
			onChange(objectValue(event, isCheck));
		}
	};

	const commonProps = {
		...others,
		size,
		...(controlled ? { value: checked !== null ? !!checked : checked } : {}),
		onChange: handleChange,
		disabled: !!disabled,
	}

	if (!sideLabel) {
		return (
			<div className={classLabel}>
				<Checkbox
					{...commonProps}
					label={label}
				/>
				{!!subLabel && <span className='checkbox-sublabel'>{subLabel}</span>}
			</div>
		);
	}

	return (
		<KendoLabelContainer sideLabel={sideLabel} label={label}>
			<div className={classLabel} >
				<Checkbox
					{...commonProps}
					label={""}
				/>
				{!!subLabel && <span className='checkbox-sublabel'>{subLabel}</span>}
			</div>
		</KendoLabelContainer>
	);
};

export default KendoCheckbox;