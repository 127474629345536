import React, { useContext } from 'react';
import FormDialog from '../../../../../../../../general/@components/modal/dialog/FormDialog';
import Grid from '@mui/material/Grid2'
import DialogContent from "../../../../../../../../general/styled/DialogContent";
import DialogActions from "../../../../../../../../general/styled/DialogActions";
import { useTranslation } from "react-i18next";
import Button from '../../../../../../../../general/styled/Button';
import { motion } from "framer-motion";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { size } from 'lodash';
import GridNoRecordsRender from '../../../../../../../../general/@components/Templates/GridNoRecordsRender';
import { scaleOpacityAnimation } from '../../../../../../../../general/@components/form/Permission/Utils';
import { FlowContext } from '../../../../contexts';
import Flow from '../../../../../../../../general/@components/general/Signatures';

function FlowModal() {
    const { t } = useTranslation();

    const {
        currentWg,
        handleClose,
        openFlow,
    } = useContext(FlowContext);

    const signaturesTemplate = currentWg?.flow_signature_template?.map(el => ({ ...el, ...el?.administrator, is_signed: true }));

    return (
        <FormDialog
            open={openFlow}
            title={`${t('flow')} - ${currentWg?.name}`}
            className='modern-dialog-design'
            style={{
                width: '50vw',
                minWidth: '400px',
                marginLeft: 'auto',
                marginRight: 'auto',
                transition: 'all 300ms',
            }}
            fullWidth
            handleClose={handleClose}
            dynamicComponent={
                <>
                    <DialogContent dividers>
                        <Card>
                            <CardContent>
                                <motion.div
                                    key='flow-modal'
                                    {...scaleOpacityAnimation}
                                    style={{
                                        minHeight: '200px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {
                                        size(signaturesTemplate) > 0 ?
                                            <Grid container spacing={2}>
                                                <Grid
                                                    size={12}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Flow checkItem={{ flow_signature: signaturesTemplate }} onlyRead={true} />
                                                </Grid>
                                            </Grid>
                                            :
                                            <GridNoRecordsRender
                                                init={true}
                                                description={t('workflow-not-configured-workgroup')}
                                            />
                                    }
                                </motion.div>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            design='text'
                            onClick={handleClose}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </>
            }
        />
    )
}

export default FlowModal;