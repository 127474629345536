import { LOAD_ATTENDANCE } from "../actions";

const initialState = {
  show: false
}

const attendance = (state = initialState, action = {}) => {
  if (action.type === LOAD_ATTENDANCE) {
    return {
      ...state,
      show: true,
      worker: action?.worker
    };
  } else {
    return state;
  }
};

export default attendance;