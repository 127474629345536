import { each, isEmpty, omit, size, xor } from "lodash";
import { initializeRole, workerName } from "./utilities";

export function treatGroups(groups, getValues) {
    return groups.map(el => {
        el = initializeRole(el, getValues);
        el.branches_employer = initializeBranches(el);
        el.saved = true;
        return el;
    });
};

const initializeBranches = ({ branches_employer } = {}) => {
    const newData = branches_employer?.map(el => {
        const newItem = { ...omit(el, ['branch', 'employer_registration']) };
        newItem.branch_id = el.branch.id;
        newItem.branch_key = el.branch.key;
        newItem.branch_name = el.branch.name;
        newItem.employer_id = el.employer_registration?.id;
        newItem.employer_key = el.employer_registration?.key;
        newItem.employer_name = el.employer_registration?.name;
        newItem.rfc = el.employer_registration?.rfc;
        return newItem;
    })
    return newData;
}

export function treatWorkgroups(workgroups) {
    return workgroups.map(el => el?.workgroup);
};

export function treatPayrollPermissions(payrollPermissions) {
    const perms = [...payrollPermissions];
    return perms.map(el => {
        el.calculate_permissions = el.calculate_permissions.map(li => li.calculate_permission);
        return el;
    })
};

export function validateParams(request, keysValidate) {
    each(keysValidate, key => {
        const value = request?.[key];
        if (!value) {
            delete request?.[key];
        }
    });
    return request;
};

export function deleteParams(request, keysDelete) {
    each(keysDelete, key => {
        delete request?.[key];
    });
    return request;
};

export function groupsPayload(roles, inAttendance) {
    if (inAttendance) return roles;
    if (isEmpty(roles)) return undefined;
    return roles.map(el => {
        const modulePerms = el.modules_perms.map(el => el.module_permission);
        const exceptions = xor(modulePerms, el.rolePerms);
        el.user_group_configs = getDashboard(el?.user_group_configs);
        el.user_permissions = exceptions;
        el.branches_employer = el.branches_employer?.map(li => {
            const newObj = {};
            newObj.branch = li.branch_id;
            newObj.attendance_access = li.attendance_access;
            newObj.confidential = li.confidential;
            if (!el.group.config.only_branch) {
                newObj.employer_registration = li.employer_id;
            }
            return newObj;
        });
        el.group = el.group?.id;
        return omit(el, ['rolePerms', 'modules_perms', 'structure_data', 'saved', 'permissions']);
    });
};

const getDashboard = (dashboard) => {
    if (dashboard?.is_subitem) return { id: dashboard?.id, default_sub_item: dashboard?.element_id, default_item: null };
    return { id: dashboard?.id, default_sub_item: null, default_item: dashboard?.element_id };
};

export const calculateUserType = (request, inAttendance) => {
    const userType = {};
    if (inAttendance) {
        const type = parseInt(request?.user_type);
        const isSign = type === 2;
        userType.is_sign_list = isSign;
        userType.is_capture_list = !isSign;
        return userType;
    }
    const groups = request?.groups_perms;
    if (groups) {
        const currentType = request?.type_user;
        const hasAttendance = groups.find(el => el.config?.is_list_group);
        const hasMoreGroups = groups.some(el => !el.config?.is_list_group);
        if (hasAttendance) {
            userType.is_capture_list = currentType?.is_capture_list ?? false;
            userType.is_sign_list = currentType?.is_sign_list ?? true;
        }
        if (hasMoreGroups) {
            userType.is_system = true;
        }
    }
    return userType;
};

export function attendancePayload(request, inAttendance) {
    if (!inAttendance) return omit(request, [
        'moper_attendance',
        'active_days',
        'cancel_mopers',
        'admin_workgroups',
    ]);
    request.moper_attendance = sanitationResponse(request.moper_attendance);
    request.active_days = sanitationResponse(request.active_days);
    request.cancel_mopers = sanitationResponse(request.cancel_mopers);
    request.admin_workgroups = request?.admin_workgroups?.map(el => ({ workgroup: el }));
    delete request.groups_perms;
    return request;
};

function sanitationResponse(values) {
    if (!values) return;
    return values?.map((m) => {
        return {
            description: m.id,
            has_perm: m.has_perm
        }
    })
};

export function systemPayload(request, inAttendance) {
    if (inAttendance) return request;
    delete request.admin_workgroups;
    delete request.cancel_mopers;
    delete request.moper_attendance;
    delete request.active_days;
    return request;
};

const getUserType = (permissions) => {
    if (permissions?.is_sign_list) return 2;
    if (permissions?.is_capture_list) return 1;
    return 2;
}

export const treatItem = (item, getValues) => {
    if (item?.email && item?.email !== "") {
        item.initial_email = item.email;
    }
    if (item?.username && item?.username !== "") {
        item.initial_username = item.username;
    }
    if (item?.type_user) {
        item.user_type = getUserType(item?.type_user);
    }
    if (item?.worker) {
        const worker = item.worker;
        const fullName = workerName(worker);
        item.worker.full_name = fullName;
    }
    if (size(item.groups_perms)) {
        item.groups_perms = treatGroups(item.groups_perms, getValues);
    }
    if (size(item?.admin_workgroups)) {
        item.admin_workgroups = treatWorkgroups(item.admin_workgroups);
    }
    // if (size(item?.payroll_permissions)) {
    //     item.payroll_permissions = treatPayrollPermissions(item.payroll_permissions);
    // }
    // if (!item?.extra_perms) {
    //     item.extra_perms = {};
    // }
    return item;
};

export const paramsDelete = [
    'role_select',
    'roles_permissions',
    'branches_permissions',
    'check_all_cancel_mopers',
    'check_all_active_days',
    'full_name',
    'user_type',
    'structure_data',
    'initial_username',
    'initial_email',
    'existingEmail',
    'attendance',
    'extra_perms', // added due to permissions refactor
    'payroll_permissions', // added due to permissions refactor
];