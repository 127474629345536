import { lazy } from 'react';

const humanResourcesIcons = {
	'sickleaves': {
		allowedTag: "Sick-Leaves",
		Icon: lazy(async () => await import("./Incapacidades.svg")),
	},
	'organizationchart': {
		allowedTag: "Organization-Chart",
		Icon: lazy(async () => await import("./Organigrama.svg")),
	},
	'vigencycontrol': {
		allowedTag: "Vigency-Control",
		Icon: lazy(async () => await import("./VigencyControl.svg")),
	},
	'workeradd': {
		allowedTag: "Worker-Add",
		Icon: lazy(async () => await import("./WorkerAdd.svg")),
	},
	'workerleave': {
		allowedTag: "Worker-Leave",
		Icon: lazy(async () => await import("./WorkerLeave.svg")),
	},
	'workerkardex': {
		allowedTag: "Worker-Kardex",
		Icon: lazy(async () => await import("./WorkerKardex.svg")),
	},
	'workers': {
		allowedTag: "Workers",
		Icon: lazy(async () => await import("./Workers.svg")),
	},
	'workerreentry': {
		allowedTag: "Worker-Reentry",
		Icon: lazy(async () => await import("./WorkerReentry.svg")),
	},
	'salarychange': {
		allowedTag: "Salary-Change",
		Icon: lazy(async () => await import("./SalaryChange.svg")),
	},
	'structurechange': {
		allowedTag: "Structure-Change",
		Icon: lazy(async () => await import("./StructureChange.svg")),
	},
	'workgroup': {
		allowedTag: "Workgroup",
		Icon: lazy(async () => await import("./Workgroup.svg")),
	},
	'infonavit': {
		allowedTag: "Infonavit",
		Icon: lazy(async () => await import("./Infonavit.svg")),
	},
	'chip': {
		allowedTag: "chip",
		Icon: lazy(async () => await import("./Chip.svg")),
	},
	'contractplant': {
		allowedTag: "Contract-Plant",
		Icon: lazy(async () => await import("./ContractPlant.svg")),
	},
	'renovatecontract': {
		allowedTag: "Renovate-Contract",
		Icon: lazy(async () => await import("./RenovateContract.svg")),
	},
	'massivesalary': {
		allowedTag: "Massive-Salary",
		Icon: lazy(async () => await import("./MassiveSalary.svg")),
	},
};

export default humanResourcesIcons;