/* libraries */
import PropTypes from "prop-types";
import React, { useEffect, useRef } from 'react';
import i18next from 'i18next';
import { find, isUndefined, isArray, isObject, each, isFunction, isNil } from 'lodash';
/* material ui */
import Tooltip from '@mui/material/Tooltip';
/* kendo ui */
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import StatusColumn from '../table/utils/StatusColumn';
import { hasValue, keyName, parseNumber, valueOrOption } from '../general/GeneralUtilities';
import Zoom from '@mui/material/Zoom';
import { conditional } from '../table/CommandCell';
import { datetimeValue, formatDateBackToFront, monthLabel } from '../Inputs/Dates/utilities';
import { uid } from "uid";
const { t } = i18next;

const defaultRight = [
	"id",
	"date",
	"dateTime",
	"currency",
	"currencyNull",
	"porcentage",
	"porcentageNull",
	"key",
	"numeric"
];

/**
	tooltip custumizado para la aplicación
	@object @param props : Es un tooltip basado en el de Material ui asi que recibe todas las propiedades descritas en la documentación
**/
export const CustomTooltip = ({
	className = "",
	placement = "top",
	children,
	...props
}) => {

	const { disabled, type } = valueOrOption(children?.props, {});

	if (!children) {
		return null;
	}

	return (
		<Tooltip
			arrow disableInteractive
			TransitionComponent={Zoom}
			PopperProps={{ position: 'absolute' }}
			{...props}
			classes={{ popper: className }}
			placement={valueOrOption(placement, "top")}
		>
			{
				disabled && !["span", "td"].includes(type)
					? <span className='abrhil-disabled-span-tooltip' >{children}</span>
					: children
			}
		</Tooltip>
	);
};

CustomTooltip.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	placement: PropTypes.string
};


/**
 * Es un itermediario para las celdas finales, todas la propiedades son basadas en las celdas de kendo react
 * Item de referencia para los ejemplos {
	 key: 1,
	 name : "branch",
	 Zone : { key: "a" name : "zone a" , }
	}
 * @string @param format:  : está propiedad define que formato sera usado para mostrar los valores de la celda
	* ejemplos:
		*? "date" : usado para mostrar campos de fecha
		*? "currency" : usado para mostrar campos dinero
		*? "keyName" : usado para mostrar campos con el formato "Key - name" --> eje: "1 - branch"
		** Mas de estos ejemplos se pueden encontrar en la función getValue de este mismo documento
		---------------------------------------------------------------------------------------------
		** Si se pasa un formato como el siguiente "string|string" : (Util para campos onjeto) se spliteara usando el primero para definir el formato
			y el segundo para definir el campo que sera tomado si es diferente al field, como el siguiente emjemplo
		*? "keyName|Zone" :dara como resultado "a - Zone a"

**/
export const CustomCell = ({
	format,
	...others
}) => {
	const splitformat = (format || "").split("|");
	return {
		"chip": <ChipCell {...others} splitformat={splitformat} />,
		"status": <StatusColumn {...others} />,
	}[splitformat[0] || "basic"] ?? <BasicCell {...others} splitformat={splitformat} />;
};

CustomCell.propTypes = {
	format: PropTypes.any
};


const handleClick = (e, onClick, selectionChange) => {
	if (isFunction(onClick)) {
		return onClick(e);
	}
	if (isFunction(selectionChange)) {
		return selectionChange(e);
	}
};


/**
 * Celda custumizada final modelo Chips sirve para mostrar los datos de celdas con datos multiples(arrays)
 * @param {object} props
 * @param {object} props.dataItem : El item que es usado por cada row (no es necesario definirlo viene por default a menos que se quiera usar uno custom)
 * @param {string} props.field : El dato especifico que sera tomado del dataItem
 * @param {string} props.className(opciona) : Clase custom para la celda
 * @param {object} props.style(opciona) : estilo custom para la celda
**/
const ChipCell = ({
	dataItem,
	field,
	className,
	style,
	onClick,
	selectionChange,
	values,
	by,
	label,
}) => {

	let list = [];
	let title = "";
	const fieldValues = valueOrOption(dataItem[field], []);

	let options = fieldValues;
	if (hasValue(by) && hasValue(values)) {
		options = valueOrOption(values, fieldValues).filter(item => fieldValues.includes(item[by]));
	}

	each(valueOrOption(options, []), (value, index) => {
		const key = conditional(value?.key, `${value?.key} - `, "");
		const text = `${key}${value?.[label] || value?.name || value?.description || value?.email || value?.id || ""}`;
		list.push({
			text: text,
			value: index,
			disabled: true,
		});
		title += `${text}\n`;
	});


	return (
		<CustomTooltip title={`${title}`}>
			<td
				onClick={(e) => handleClick(e, onClick, selectionChange)}
				className={`${className} text-center chipCell`}
				style={style}
				aria-hidden="true"
			>
				<ChipList data={list} size={"small"} style={{ pointerEvents: "none" }} />
			</td>
		</CustomTooltip >
	);
};

ChipCell.propTypes = {
	by: PropTypes.any,
	className: PropTypes.any,
	dataItem: PropTypes.any,
	field: PropTypes.any,
	label: PropTypes.any,
	onClick: PropTypes.func,
	selectionChange: PropTypes.func,
	style: PropTypes.any,
	values: PropTypes.any
};


/**
 * Celda custumizada final modelo Basico (solo texto) es el que se usa por default en el sistema
 * @param {object} props
 * @param {object} props.dataItem : El item que es usado por cada row (no es necesario definirlo viene por default a menos que se quiera usar uno custom)
 * @param {string} props.field : El dato especifico que sera tomado del dataItem
 * @param {array} props.splitformat : se crea a partir del format descrito en el CustomCell de este mismo documento
 * @param {string} props.className(opciona) : estilo custom para la celda
 * @param {object} props.style(opciona) : estilo custom para la celda
 * @param {array} props.values(opciona) : array custom del cual se va a sacar el valor que sera mostrado
 * @param {string} props.by(opciona) : en conjunto con el anterior nos sirve para saber que campo del array sera usado para filtrar el valor
 * @param {string} props.label(opciona) : en conjunto con los ultimos dos este sera usado para saber que dato del obejto filtrado sera usado para el label final
 * @param {function} props.selectionChange(opciona) : evento tipo función (las posibilidades de está están descritas en la documentación de kendo react)
**/
const BasicCell = ({
	dataItem,
	field,
	splitformat,
	className,
	style,
	values,
	by,
	label,
	onClick,
	selectionChange,
	validatePerm,
	noPermValue,
}) => {

	let formatVal = field;
	if (hasValue(values)) {
		by = valueOrOption(by, "id");
		dataItem = find(values, [by, getField(dataItem, field)]) ?? {};
		formatVal = splitformat[1] ?? label ?? "name";
	} else if (splitformat[1]) {
		formatVal = splitformat[1];
	}

	const { title, value, toRight } = getValue(dataItem, formatVal, splitformat[0]);
	const textRight = (toRight || defaultRight.includes(field) || defaultRight.includes(splitformat[0])) ? "text-right" : "";

	const permGate = () => {
		if (!isNil(validatePerm)) {
			return validatePerm ? value : noPermValue;
		}
		return value;
	};

	return (
		<CustomTooltip title={`${title !== "" ? title : permGate()}`}>
			<td
				onClick={(e) => handleClick(e, onClick, selectionChange)}
				className={`${className} ${textRight}`}
				style={style}
				data-cy={field}
				aria-hidden="true"
			>
				{permGate()}
			</td>
		</CustomTooltip >
	);
};

BasicCell.propTypes = {
	by: PropTypes.any,
	className: PropTypes.any,
	dataItem: PropTypes.any,
	field: PropTypes.any,
	label: PropTypes.any,
	onClick: PropTypes.func,
	selectionChange: PropTypes.func,
	splitformat: PropTypes.any,
	style: PropTypes.any,
	values: PropTypes.any
};

/**
	*Función auxiliar que nos ayudara a obtener el dato que finalmente sera mostrado en la celda y el tiulo de la misma
	*Si el campo en cuestion requiere un fromato en especial se procesara aqui, en caso contrario se regresa el valor original
	@param {object} item : El item que sera parseado
	@param {string} formatVal : El valor del item que sera usado para formatear
	@param {string} formatAs : El formato que sera aplicado
**/

const getValue = (item, formatVal, formatAs) => {

	let value = conditional(item, getField(item, formatVal), null);
	value = conditional(value === null || isUndefined(value), "", value);

	if (isObject(value) && !isArray(value)) {
		formatAs = conditional(isUndefined(formatAs) || formatAs === "" || formatAs === null, "keyName", formatAs);
		item = value;
	}

	let key = valueOrOption(item?.key, "");
	let name = item?.name || item?.description || "";
	let separator = conditional(key !== "" && name !== "", " - ", "");
	let rfc = conditional(item?.rfc && item?.rfc !== "", `${item?.rfc} - `, separator);
	let title = "";
	let type = valueOrOption(formatAs, "").toLowerCase();
	let decimals;
	let toRight = false;

	if (type.includes("currency") || type.includes("porcentage")) {
		const splited = type.split(".");
		toRight = true;
		type = splited[0];
		decimals = splited[1];
	}

	switch (type) {
		case "date":
			value = formatDateBackToFront(value);
			break;
		case "datetime":
			value = conditional(value, datetimeValue(value, "frontend2"), "");
			break;
		case "month":
		case "datetmonth":
			value = conditional(value, monthLabel(value), "");
			break;
		case "currency":
		case "currencynull":
			value = parseNumber(value, decimals, "$", conditional(type === "currencynull", "", 0));
			break;
		case "porcentage":
		case "porcentagenull":
			value = parseNumber(value, decimals, "%", conditional(type === "porcentagenull", "", 0));
			break;
		case "numeric":
			value = parseNumber(value);
			break;
		case "username":
			value = conditional(item?.username && item?.username !== "", item?.username, item?.name);
			value = conditional(value !== "" && value, value, item?.email);
			break;
		case "key":
			value = key;
			break;
		case "name":
			value = name;
			break;
		case "keyname":
			value = `${key}${separator}${name}`;
			title += conditional(key, `${t("key")} - ${key}\n`, "");
			title += conditional(name, `${t("name")} - ${name}`, "");
			break;
		case "withrfc":
			value = `${rfc}${key} - ${name}`;
			rfc = rfc.replaceAll("-", "");
			title += conditional(rfc, `${t("rfc")} - ${rfc}\n`, "");
			title += conditional(key, `${t("key")} - ${key}\n`, "");
			title += conditional(name, `${t("name")} - ${name}`, "");
			break;
		default:
		//nothing
	}

	return { value, title, toRight };
};

const getField = (item, field) => {
	if (!field.includes(".")) {
		return item[field];
	}

	let value = item;
	each(field.split("."), splited => {
		value = value[splited];
	});
	return value;
};

const GroupsCell = ({ dataItem, field }) => {
	const value = dataItem[field] || dataItem.groups_perms
	const data = value?.map(el => el?.group?.name);
	const { cellRef, tooltipMessage } = useCellScroll(data);

	return (
		<CustomTooltip title={tooltipMessage}>
			<td className="k-command-cell users-cell-wrap">
				<div ref={cellRef} className="users-cell">
					{data.map(el => <Chip key={uid()} text={el} size='small' style={{ pointerEvents: 'none' }} />)}
				</div>
			</td>
		</CustomTooltip>
	);
};

export default GroupsCell;

export const useCellScroll = (data) => {

	const cellRef = useRef();

	useEffect(() => {
		if (!cellRef.current || data?.length < 2) return;
		scrollController(cellRef.current);
	}, [data])

	function scrollController(element) {
		let pos = { top: 0, left: 0, x: 0, y: 0 };

		const mouseDownHandler = function (e) {
			element.style.cursor = 'grabbing';
			element.style.userSelect = 'none';

			pos = {
				left: element.scrollLeft,
				top: element.scrollTop,
				x: e.clientX,
				y: e.clientY,
			};

			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = (e) => {
			const dx = e.clientX - pos.x;
			const dy = e.clientY - pos.y;

			element.scrollTop = pos.top - dy;
			element.scrollLeft = pos.left - dx;
		};

		const mouseUpHandler = () => {
			element.style.cursor = 'grab';
			element.style.removeProperty('user-select');

			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};

		element.addEventListener('mousedown', mouseDownHandler);
	}

	const tooltipMessage = <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
		{data?.map(el => <span key={el}>{el}</span>)}
	</div>;

	return {
		cellRef,
		tooltipMessage,
	};
};

export const StructureCell = ({
	id,
	dataItem,
	field,
	style = {},
	className = ""
}) => {

	const node = dataItem?.enterprise_structure?.[field] ?? dataItem?.[field];
	const value = keyName(node);

	return (
		<CustomTooltip title={value}>
			<td id={id} className={`cell-structure-wrap ${className}`} style={style}>
				{value}
			</td>
		</CustomTooltip>
	);
};

StructureCell.propTypes = {
	dataItem: PropTypes.object,
	field: PropTypes.string,
	id: PropTypes.string,
	style: PropTypes.object,
	className: PropTypes.string,
};