import { lazy } from "react";

const payrollItems = [
	{
		id: "modules@payroll-dispersion",
		type: "item",
		icon: 'payroll-dispersion',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/calculate/PayrollDispersion')),
	},
	{
		id: "modules@calculate",
		type: "item",
		icon: 'payroll-kardex',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import("../../../../../modules/payroll/calculate/PayrollCalculate")),
	},
	{
		id: 'modules@variables',
		type: 'item',
		package_permissions: ["payroll_package"],
		icon: 'bimonthlyvariables',
		componentElm: lazy(() => import('../../../../../modules/payroll/calculate/BimonthlyVariables')),
	},
	{
		id: 'modules@periods',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/payroll/period/PayrollPeriods')),
	},
	{
		id: 'modules@alimony',
		type: 'item',
		icon: 'alimony',
		componentElm: lazy(() => import('../../../../../modules/payroll/alimony/Alimony')),
	},
	{
		id: "modules@voucher-dispersion",
		type: "item",
		icon: 'voucher-dispersion',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/voucher/VoucherDispersion')),
	},
	{
		id: "modules@import-voucher-accounts",
		type: "item",
		icon: 'voucher-dispersion',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/voucher/ImportVoucherAccounts')),
	},
	{
		id: "modules@request-voucher-accounts",
		type: "item",
		icon: 'voucher-dispersion',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/voucher/RequestVoucherAccounts')),
	},
	{
		id: 'modules@concepts-accounts',
		type: 'item',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/concept/PayrollConceptsAccounts')),
	},
	{
		id: 'modules@concepts',
		type: 'item',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/concept/PayrollConcepts')),
	},
	{
		id: "modules@scheduled-concepts",
		type: "item",
		package_permissions: ["payroll_package"],
		icon: 'scheduled-concepts',
		componentElm: lazy(() => import('../../../../../modules/payroll/concept/ScheduledConcepts')),
	},
	{
		id: "modules@loans-and-paids",
		type: "item",
		icon: 'loans-and-paids',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/concept/LoansAndPaids')),
	},
	{
		id: "modules@provision-policy",
		type: "item",
		icon: 'Provision-Policy',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/policies/PayrollProvisionPolicy')),
	},
	{
		id: 'modules@vacations-initial-provisions',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/payroll/policies/InitialVacationsProvisions')),
	},
	{
		id: "modules@payroll-policy",
		type: "item",
		icon: 'Payroll-Policy',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/payroll/policies/PayrollPolicy')),
	},
	{
		id: 'modules@payroll-settings',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/payroll/SettingsPayroll')),
	},
	//En construccion
	{
		id: 'modules@payroll-catalogs',
		type: 'item',
		inConstruction: true,
		componentElm: true,
	},
	{
		id: 'modules@payroll-report',
		type: 'item',
		inConstruction: true,
		componentElm: true,
	},
	{
		id: 'modules@receipt_vault',
		type: 'item',
		package_permissions: ["payroll_package"],
		inConstruction: true,
		componentElm: true,
		//componentElm: <Mod />
	},
	{
		id: 'modules@confronts',
		type: 'item',
		package_permissions: ["payroll_package"],
		inConstruction: true,
		componentElm: true,
		//componentElm: <Mod />
	},
];

export default payrollItems;