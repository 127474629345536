import { lazy } from 'react';
import abrhilIcons from "./Abrhil";
import aplicationsIcons from "./Aplicaciones";
import humanResourcesIcons from "./HumanResources";
import attendanceAndMopersIcons from "./AttendanceAndMopers";
import imssIcons from "./Imss";
import kioskIcons from "./Kiosco";
import payrollsIcons from "./Payrolls";
import clocksIcons from "./Relojes";
import systemsIcons from "./Systems";
import toolsBarIcons from "./ToolsBar";
import TimbrasoftIcons from "./Timbrasoft";
import Courses from "./Courses";
import actionsIcons from "./Actions";
import fileIcons from "./Files";
import requisitionIcons from "./Requisitions";
import notificationIcons from './Notifications';
import outsideIcons from '../svg';
import aditionalsIcons from "./Aditionals";

const icons = {
	...abrhilIcons,
	...aplicationsIcons,
	...humanResourcesIcons,
	...attendanceAndMopersIcons,
	...imssIcons,
	...kioskIcons,
	...payrollsIcons,
	...clocksIcons,
	...systemsIcons,
	...toolsBarIcons,
	...TimbrasoftIcons,
	...Courses,
	...actionsIcons,
	...fileIcons,
	...requisitionIcons,
	...notificationIcons,
	...outsideIcons,
	...aditionalsIcons,
	modules: {
		allowedTag: "Modules",
		Icon: lazy(async () => await import("./Modules.svg")),
	},
	catalog: {
		allowedTag: "Catalog",
		Icon: lazy(async () => await import("./CatalogIcon.svg")),
	},
	report: {
		allowedTag: "Report",
		Icon: lazy(async () => await import("./ReportIcon.svg")),
	},
	configicon: {
		allowedTag: "Config-icon",
		Icon: lazy(async () => await import("./ConfigIcon.svg")),
	},
	goto: {
		allowedTag: "Go-To",
		Icon: lazy(async () => await import("./Ir.svg")),
	},
	goback: {
		allowedTag: "Go-Back",
		Icon: lazy(async () => await import("./Regresar.svg")),
	},
	package: {
		allowedTag: "Package",
		Icon: lazy(async () => await import("./Package.svg")),
	},
};

export default icons;