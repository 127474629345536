import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import { isNumber } from "lodash";
import { useTranslation } from "react-i18next";
import Message from './Message';
import IconUse from "../uiDesign/IconUse";
import SubProcessMessage from './SubProcessMessage';
import { NotificationMessage } from "../Notifications";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { uid } from "uid";
import { opacityAnimation } from "../form/Permission/Utils";
import { transition } from "./constants";

const BodyMessage = ({
	message,
	taskId,
	currentTask,
	percentage,
	onCancel,
	...others
}) => {

	const { t } = useTranslation();

	const [cancel, setCancel] = useState(false);

	const handleCancel = () => {
		setCancel(!cancel);
	};

	const handleConfirmCancel = () => {
		onCancel(taskId);
		toast.dismiss(taskId);
		setCancel(false);
	};

	return (
		<div className="body-message-socket">
			<div
				key={`body-socket-${taskId}`}
				className='socket-message'
			>
				<NotificationMessage
					title={t("task-in-progress")}
					subtitle={currentTask?.module}
					subProcessMessage={<SubProcessMessage {...others} />}
					message={<MessageAnimation
						showConfirm={cancel}
						message={message}
						taskId={taskId}
						handleCancel={handleCancel}
						handleConfirm={handleConfirmCancel}
					/>}
				/>
			</div>

			{isNumber(percentage) &&
				<span className="percentage">
					{`${parseInt(percentage)}%`}
				</span>}

			<div className={"socket-icons-wrapper"}>
				<button
					className={`cancel-socket ${cancel ? "in-cancel" : ""}`}
					onClick={handleCancel}
					disabled={cancel}
				>
					<IconUse icon={faBan} />
				</button>
			</div>
		</div>
	);
};

export default BodyMessage;

BodyMessage.propTypes = {
	message: PropTypes.string,
	taskId: PropTypes.string,
	currentTask: PropTypes.object,
	percentage: PropTypes.number,
	onCancel: PropTypes.func,
};

const MessageAnimation = ({
	showConfirm,
	message,
	taskId,
	handleCancel,
	handleConfirm,
}) => {
	const messageId = useRef(uid()).current;

	return (
		<AnimatePresence mode="wait">
			{!showConfirm ?
				<motion.span
					key={`socket-message-${messageId}`}
					{...opacityAnimation}
					transition={transition}
				>
					{message}
				</motion.span>
				:
				<Message
					taskId={taskId}
					handleCancel={handleCancel}
					handleConfirmCancel={handleConfirm}
				/>}
		</AnimatePresence>
	);
};

MessageAnimation.propTypes = {
	showConfirm: PropTypes.bool,
	message: PropTypes.string,
	taskId: PropTypes.string,
	handleCancel: PropTypes.func,
	handleConfirm: PropTypes.func,
};