import { useForm, useWatch } from 'react-hook-form';
import {
	ALL_MOPER_KEYS,
	mopersListConstructor,
} from '../../../../../../signature/SignaturesByWorker/components/Details/components/models';
import { useEffect, useRef, useState } from 'react';
import { capitalize, each, isNumber, isString, pick, size } from 'lodash';
import { resolveError } from '../../../../../../../../general/@components/requests/resolve-error';
import {
	balancesOvertimeTXT,
	mopersCompleteTurn,
	overtimeMopersAPI,
} from '../../../../../../../../general/services/mopers';
import { parseRequest } from '../../../../../../../../general/@components/requests/parse-request';
import { customMoperModels } from '../models';
import { implementService } from '../../../../../../../../general/services/implemet-service';
import { useTranslation } from 'react-i18next';
import { useModalsContext } from './useModals';
import { useTabsContext } from '../../../../hooks/useTabs';
import { useFiltersContext } from '../../../../hooks/useFilters';
import { format, parse } from 'date-fns';
import { showSuccessNotification } from '../../../../../../../../general/@components/Notifications';
import { formatDate } from '../../../../../../attendance/Attendance/ConstantAndUtilities/utilities';
import { absenteeismDirectAPI, absenteeismReasonsAPI } from '../../../../../../../../general/services/payroll';
import { APPLY_REST_KEY } from '../components/Modals/ApplyRest/hooks/useApplyRest';
import { isHolidayRest, isNonAttendance, isWorkerRestMoper } from '../utils/utilities';
import { successDeleted } from '../../../../../../../../general/@components/Notifications/notification-messages';
import { useDispatch } from 'react-redux';
import { showConfirmNotification, showNotificationWarning } from '../../../../../../../../../store/actions';
import useRequestLoad from '../../../../../../../../general/services/useRequestLoad';

const ALLOWED_KEYS = [68, 32, 46, 70];

function useGrid() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [rowSelected, setRowSelected] = useState(null);
    const [rowLoading, setRowLoading] = useState(null);
    //START MODAL BANKHOURS STATES
    const [openBankHours, setOpenBankHours] = useState(false);
    const [bankHoursData, setBankHoursData] = useState([]);
    //END MODAL BANKHOURS STATES
    const [fetch, loading] = useRequestLoad(true);

    const {
        filters,
        updateFilters,
    } = useFiltersContext();

    const {
        worker,
        isMyWorkgroup,
        modalValues,
        hasOvertimeActive,
        currentPeriod,
        createCheckPerm,
        selected,
        handleOpen,
        handleClose,
        periodLocked,
        generalValidation,
        workerEndContract,
    } = useModalsContext();

    const {
        tab,
        onTabChange,
    } = useTabsContext();

    const {
        control,
        setValue,
        getValues,
    } = useForm({
        defaultValues: { date_range: null },
        mode: "onChange",
    });

    const dateRange = useWatch({ control, name: 'date_range' });
    const statusFilter = useWatch({ control, name: 'is_authorized' });
    const payFilter = useWatch({ control, name: 'pay_type_filter' });
    const typeFilter = useWatch({ control, name: 'type_filter' });
    const periodRef = useRef(currentPeriod);

    useEffect(() => {
        if ((!currentPeriod?.id && !size(dateRange)) || !worker?.id) return;
        periodRef.current = currentPeriod;
        getAllData();
    }, [currentPeriod?.id, worker, statusFilter, payFilter, dateRange])

    useEffect(() => {
        if (!worker?.id) return;
        getBankHours();
    }, [worker])

    useEffect(() => {
        if (!size(currentPeriod)) return;
        updateFilters({ filters: { ...filters?.filters, initial_date: null, end_date: null } });
        setValue('date_range', null);
    }, [currentPeriod])

    const getDates = () => {
        if (size(dateRange)) {
            const start1 = format(dateRange[0], 'yyyy-MM-dd');
            const end1 = format(dateRange[1], 'yyyy-MM-dd');
            return { start: start1, end: end1 };
        }

        if (size(periodRef.current)) {
            const start2 = periodRef.current?.start_admin_court_date;
            const end2 = periodRef.current?.end_admin_court_date;
            return { start: start2, end: end2 };
        }

        return {};
    };

    const handleRefresh = async (reload = true, item = null) => {
        getBankHours();
        await getAllData(reload, item);
    }

    const getAllData = async (reload = true, item = null) => {
        if (item) setRowLoading(item?.origin_day);
        const { start, end } = getDates();
        const params = parseRequest({
            worker: worker.id,
            initial_date: start,
            end_date: end,
            ...(isNumber(statusFilter) ? { is_authorized: statusFilter } : {}),
            // ...(fromSignWorker || typeFilter ? { is_overtime: fromSignWorker ? true : typeFilter } : {}),
            // is_overtime: true,
            // ...(fromSignWorker ? { all_dates: true } : {}),
            tree: true,
        });
        params.pay_type = payFilter;
        await fetch({
            api: overtimeMopersAPI.get(params),
            load: reload,
            callback: (resp) => {
                const dates = formatOvertimeResponse(resp);
                setData(dates);
                setRowLoading(null);
            },
            onFailed: (err) => {
                resolveError(err);
                setRowLoading(null);
            }
        });
    }

    const getBankHours = async () => {
        try {
            let response = await balancesOvertimeTXT.get({
                worker: worker?.id,
                date: new Date().toISOString().slice(0, 10)
            });
            setBankHoursData(response);
        } catch (error) {
            resolveError(error);
        }
    };

    function handleClearFilters() {
        setValue('selected_calendar_filter', null);
        setValue('date_range', []);
        setValue('is_authorized', null);
        setValue('pay_type_filter', null);
        setValue('type_filter', null);
    }

    //MODALS

    //START BANKHOURS MODAL

    function handleOpenBankHours() {
        setOpenBankHours(true);
    }
    function handleCloseBankHours() {
        setOpenBankHours(false);
    };
    function getHours(minutes) {

        let minutesNumber = Number(minutes);
        minutesNumber = isNaN(minutesNumber) ? 0 : minutesNumber;
        let hours = Math.floor(minutesNumber / 60);
        minutesNumber = minutesNumber % 60;

        return `${(hours < 10) ? '0' + hours.toString() : hours.toString()}:${(minutesNumber < 10) ? '0' + minutesNumber.toString() : minutesNumber.toString()}`;
    }
    //END BANKHOURS MODAL

    const requestCompleteTurn = item => {
        const request = {
            moper: {
                moper_catalog: 5,
                worker: worker.id
            },
            origin_day: item.origin_day,
            minutes_balance: item.missing_time
        };

        implementService(mopersCompleteTurn.post(request), onSuccessCT);
    }

    const onSuccessCT = () => {
        handleRefresh(false, selected);
        showSuccessNotification(t('Se ha solicitado el moper de completar turno'));
    }

    const onRowClick = (item) => setRowSelected(item?.id);

    const onRowKeydown = (e, item) => {
        const keyCode = e.keyCode;
        if (!ALLOWED_KEYS.includes(keyCode)) return e;
        if (!generalValidation(item)) return e;
        const currentRow = document.querySelector(`#time-attendance-row-${item?.id}`);
        const isRowFocused = document.activeElement === currentRow || currentRow?.contains(document.activeElement);
        if (!isRowFocused) return e;
        if (keyCode === 70) {
            performApplyAbsen(item);
        }
        if (keyCode === 68) {
            performApplyRest(item);
        }
        if ([32, 46].includes(keyCode)) {
            e.preventDefault();
            deleteRestApplied(item);
        }
        return e;
    }

    const validateRest = (rest, weekday) => {
        const fn = {
            1: () => weekday === 0,
            2: () => [6, 0].includes(weekday),
            3: () => true,
        }[rest];
        if (!fn) return;
        return fn();
    };

    const deleteRestApplied = async (item) => {
        const restMoper = isWorkerRestMoper(item) || isHolidayRest(item);
        const absenFR = isNonAttendance(item);
        const moperToRemove = restMoper ?? absenFR;
        if (!moperToRemove || !item) return;
        setRowLoading(item.origin_day);
        const request = {
            movement_date: moperToRemove.movement_date,
            worker: worker.id,
        };
        try {
            await absenteeismDirectAPI.destroyMoper(moperToRemove.id, request);
            showSuccessNotification(successDeleted());
            getAllData(false, item);
        } catch (error) {
            setRowLoading(null);
            resolveError(error);
        }
    }

    const performApplyAbsen = async (item) => {
        if (isNonAttendance(item)) {
            dispatch(showNotificationWarning({
                maxWidth: 'sm',
                message: 'Ya existe una falta en este día',
            }));
            return;
        };
        setRowLoading(item?.origin_day);
        try {
            const resp = await absenteeismReasonsAPI.getOne(3);
            const request = {
                movement_date: item?.origin_day,
                absenteeism_reason: resp.id,
                worker: worker.id,
                option: 1,
                worker_info: { name: worker?.name, first_surname: worker?.first_surname, second_surname: worker?.second_surname },
            }
            await applyDirectAbsen(request);
            getAllData(false, item);
        } catch (error) {
            resolveError(error);
            setRowLoading(null);
        }
    }

    const performApplyRest = async (item, confirm, confirmFuture) => {
        if (isWorkerRestMoper(item)) {
            dispatch(showNotificationWarning({
                maxWidth: 'sm',
                message: 'Ya existe un descanso en este día',
            }));
            return;
        };
        setRowLoading(item?.origin_day);
        const restMode = worker?.enterprise_structure_organization?.settings?.config?.day_off_mode;
        const originDay = parse(item?.origin_day, 'yyyy-MM-dd', new Date());
        const weekDay = originDay?.getDay();
        const isWorkerRest = validateRest(restMode, weekDay);
        try {
            const resp = await absenteeismReasonsAPI.getOne(16);
            if (isWorkerRest) {
                const request = {
                    movement_date: item?.origin_day,
                    absenteeism_reason: resp.id,
                    worker: worker.id,
                    option: 1,
                    validate_change: confirm,
                    flag_day_offs: confirmFuture,
                    worker_info: { name: worker?.name, first_surname: worker?.first_surname, second_surname: worker?.second_surname },
                }
                await applyDirectAbsen(request);
                getAllData(false, item);
            } else {
                handleOpen(APPLY_REST_KEY, item);
                setRowLoading(null);
            }
        } catch (error) {
            setRowLoading(null);
            const errorData = error.response?.data ?? {};
            const { key, need_confirm } = errorData;
            if (need_confirm || key) {
                dispatch(showConfirmNotification({
                    maxWidth: 'sm',
                    message: errorData?.detail,
                    description: t("label-draft-question"),
                    onConfirm: () => performApplyRest(
                        item,
                        !confirm ? !!key : confirm,
                        !confirmFuture ? !!need_confirm : confirmFuture
                    ),
                }));
                return;
            }
            resolveError(error);
        }
    }

    return {
        control,
        data,
        tab,
        isMyWorkgroup,
        rowSelected,
        onTabChange,
        statusFilter,
        payFilter,
        typeFilter,
        periodLocked,
        workerEndContract,
        createCheckPerm,
        setRowLoading,
        currentPeriod,
        rowLoading,
        loading,
        getHours,
        setValue,
        getValues,
        onRowClick,
        onRowKeydown,
        worker,
        getAllData,
        setData,
        handleClearFilters,
        handleRefresh,
        requestCompleteTurn,
        hasOvertimeActive,
        //MODALS
        //START BANKHOURS
        openBankHours,
        handleOpenBankHours,
        handleCloseBankHours,
        //END BANK HOURS
        handleOpen,
        handleClose,
        selected,
        modalValues,
        //bankhours
        bankHoursData,
    };
}

export default useGrid;

export const applyDirectAbsen = async (request) => {
    const res = await absenteeismDirectAPI.post(request);
    const appliedMsg = res?.comment || 'Aplicado';
    showSuccessNotification(`${appliedMsg} el ${request?.movement_date?.split('-')?.toReversed()?.join('/')}`);
    return true;
}

const applyDateValues = (date) => {
    const formatedDate = new Date(date?.split('-')?.join('/'));
    const day = formatedDate?.getDate()?.toString()?.padStart(2, '0');
    const day_name = capitalize(formatedDate?.toLocaleDateString('es-ES', { weekday: 'long' }));
    const week_day = formatedDate.getDay();
    const month = capitalize(formatedDate?.toLocaleDateString('es-ES', { month: 'long' }));
    const year = formatedDate.getFullYear();
    return { day, day_name, week_day, month, year };
}

const buildRequestsList = element => {
    if (!size(element)) return [];
    for (const key in pick(element, ALL_MOPER_KEYS)) {
        const list = element[key];
        each(list, (item) => {
            item.origin_day = element?.origin_day;
        });
    };
    return mopersListConstructor(element, customMoperModels);
}

export const formatOvertimeResponse = (response) => {
    const dateRange = [];
    each(response, (element, date) => {
        element.date_data = applyDateValues(isString(date) ? date : element?.origin_day);
        const checks = element?.checks ?? [];
        for (let check in checks) {
            const checkIn = checks[check]?.check_in;
            const checkOut = checks[check]?.check_out;
            const checkInCalc = checks[check]?.clock_process?.[0]?.check_in_calculated;
            const checkOutCalc = checks[check]?.clock_process?.[0]?.check_out_calculated;
            const check_in = checkIn ? formatDate(checkIn) : null;
            const check_out = checkOut ? formatDate(checkOut) : null;
            const check_in_calc = checkInCalc ? formatDate(checkInCalc) : null;
            const check_out_calc = checkOutCalc ? formatDate(checkOutCalc) : null;
            checks[check].check_in = check_in;
            checks[check].check_out = check_out;
            if (checks[check]?.clock_process?.[0]) {
                checks[check].clock_process[0].check_in_calculated = check_in_calc;
                checks[check].clock_process[0].check_out_calculated = check_out_calc;
            }
        }
        const overtimeData = element.overtime;
        const requests = buildRequestsList(overtimeData ?? element);
        const requestDetails = requests.map(el => {
            el.day = element.date_data.day;
            el.day_name = element.date_data.day_name;
            el.week_day = element.date_data.week_day;
            el.month = element.date_data.month;
            el.year = element.date_data.year;
            return el;
        });
        const overtimeInfo = {
            ...element,
            ...element.date_data,
            ...(overtimeData ?? {}),
            pd: (overtimeData ?? element)?.is_generate_sunday_bonus,
            ft: (overtimeData ?? element)?.is_generate_worked_holiday,
            in: (overtimeData ?? element)?.is_generate_night_implicit,
            bn: (overtimeData ?? element)?.is_generate_night_bonus,
            im: (overtimeData ?? element)?.is_generate_mix_implicit,
            dt: {
                ...((overtimeData ?? element)?.is_rest_worked ?? {}),
            },
            td: {
                ...((overtimeData ?? element)?.is_double_shift ?? {}),
            },
            request_details: requestDetails,
        }
        const dateObj = {
            ...element,
            id: isString(date) ? date : element?.origin_day,
            origin_day: isString(date) ? date : element?.origin_day,
            ...(size(overtimeData) ? {
                overtime: {
                    ...overtimeInfo,
                },
            } : overtimeInfo),
        };
        dateRange.push(dateObj);
    });
    return dateRange;
}
