import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EndSvg from "../../assets/svg/tab_end.svg";
import MuiIB from '@mui/material/IconButton';
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

export const Tab = ({
	active = false,
	title = "",
	onClick,
	onClose,
	onDoubleClick = () => { },
}) => {

	const handleClose = e => {
		e.stopPropagation();
		onClose(e);
	};

	return (
		<AnimatePresence>
			<motion.div
				key={`tab-content-${title}`}
				className={`abrhil-window-tab ${active ? "active" : ""}`}
				initial={{ opacity: .5, scale: .5 }}
				animate={{ opacity: 1, scale: 1 }}
				exit={{ opacity: 0, scale: 0 }}
				transition={{ duration: 0.5 }}
				whileInView={{ opacity: 1, scale: 1 }}
				viewport={{ once: true }}
				onClick={onClick}
				onDoubleClick={onDoubleClick}
			>
				<div className="tab-start">
					<EndSvg />
				</div>
				<div className="tab-content">
					<span className='tab-text'>
						{title}
					</span>
					<span className='tab-close-container'>
						<MuiIB size={"small"} onClick={handleClose}>
							<FontAwesomeIcon icon={faXmark} />
						</MuiIB>
					</span>
				</div>
				<div className="tab-end">
					<EndSvg />
				</div>
				<div className='tab-shadow'></div>
			</motion.div>
		</AnimatePresence>
	);
};
