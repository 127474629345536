
//* Arreglo de APIS que requieren el grupo del usuario *//
export const apisWithGroup = [
    'workers',
    'job-positions',
    'sub-departments',
    'departments',
    'sub-areas',
    'areas',
    'branches',
    'sub-branches',
    'payroll-periods',
    'payroll-type',
    'employer-registration',
    'enterprise-structures-organization',
    'calculate',
    'list-rfc',
    'concepts-abrsat',
    'abr-concepts',
    'stamp',
    'salary-movement-records',
    'worker-payment-accounts'
];