import React from "react";
import { motion } from "framer-motion";
import { t } from "i18next";
import Grid from "@mui/material/Grid";
import { withLayout } from '../../../utilities';
import Header from "./Header";
import Menu from "./Menu";

const Aditionals = ({
    items = []
} = {}) => {

    return (
        <>
            <Grid container spacing={2} {...withLayout}>
                <Grid item xs={12} className="mega-menu-header" {...withLayout}>
                    <Header withCloseButton={false} customTitle={t("additionals")} />
                </Grid>
            </Grid>
            <motion.div className='childrens-container aditionals' layout>
                <Menu items={items} />
            </motion.div>
        </ >
    );
};

export default Aditionals;