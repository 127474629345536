import * as yup from 'yup';
import { forEach, size } from 'lodash';
import i18next from 'i18next';
import { current_month, current_year } from '../../../../../general/@components/Inputs/Dates/utilities';
import { accessOptions, attendanceModes } from './dictionaries';
import { stringValidation } from '../../../../../general/@components/validations/stringValidation';
import { createContext } from 'react';

const { t } = i18next;

// General initial

export const initialPage = { skip: 0, take: 6 };

export const initValues = {
  indicate_days: 'ALL',
  active_date: false,
  sort_by: "KEY",
  sort_dir: "UP",
  attendance_mode: attendanceModes.compact,
  ordering: 'key',
  view_switch_period_filters: true,
  view_switch_access_filters: true,
  access_mode: accessOptions.branch,
  filter_type: 99,
  absenteeism: 99
};

export const initAccessFilters = {
  workgroup: null,
  branch: null,
};

//Advanced filters

export const daysType = () => {
  return [
    { key: 1, id: 1, value: 'ALL', label: t('Todos') },
    // { value: 'late', label: t('Retardos / Puntual') },
    { key: 2, id: 2, value: 'ADJUST', label: t('Ajuste manual de asistencia') },
    { key: 3, id: 3, value: 'OVER', label: t('Hrs +/- a la Jornada') },
    // { value: 'incidence', label: t('Incidencia de horarios') }
  ];
};

export const timeOptions = () => {
  return [
    { key: '*', value: 99, label: t('_all') },
    { key: 'HD', value: 0, label: t('Horas dobles') },
    { key: 'HT', value: 1, label: t('Horas triples') },
    { key: 'TXT', value: 2, label: t('Tiempo por tiempo') },
    { key: 'PM', value: 3, label: t('Prima dominical') },
    { key: 'FT', value: 4, label: t('Festivo trabajado') },
    { key: 'TD', value: 5, label: t('Turno doble') },
    { key: 'DT', value: 6, label: t('Descanso trabajado') },
  ];
};

export const excludeInputs = [
  'workgroup',
  'year',
  'month',
  'payroll_type',
  'period',
  'active_date',
  'count',
  'sort_by',
  'sort_dir',
  'ordering',
  'indicate_days',
  'administrative_court',
  'open_period_date',
  'initial_date',
  'end_date',
  'attendance_mode',
  'employer_registration',
  'branch',
];

export const counterInputs = [
  'job_position',
  'department',
  'area',
];

// Cell order

export const orderOptions = [
  { id: 0, value: "KEY", ordering: "key", label: 'Clave del colaborador' },
  { id: 1, value: "NAME", ordering: "name", label: 'Nombre del colaborador' },
  { id: 2, value: "JOB_POSITION,KEY", ordering: "job_position,key", label: 'Puesto, clave del colaborador' },
];

export const orderType = [
  { value: 'UP', label: t('sort-ascending') },
  { value: 'DOWN', label: t('sort-descending') }
];

// Modals

export const AbsenOptions = () => {
  return [
    { key: 2, value: 'ausen_con_goce', name: t('permission-c') },
    { key: 3, value: 'ausen_sin_goce', name: t('permission-g') }
  ]
}

export const calendarChecksInitValues = {
  month: current_month(),
  year: current_year(),
  worker_loading: true,
  calendar_loading: true,
};

export const printValidationSchema = yup.object().shape({
  print_type: yup.object(),
  list_content: yup.object(),
  worker_signature: yup.object(),
  worker_type: yup.object(),
  dates: yup.object(),
  structure: yup.object(),
  separate_by: yup.object(),
  order: yup.object(),
});

export const printInitValues = ({
  catalog,
  workerTypes,
  filter,
}) => {
  let wTypes = {};
  if (size(workerTypes)) {
    forEach(workerTypes, function (type) {
      wTypes[type.name] = false;
    });
  }
  const initDate = filter?.initial_date ?? '0000-00-00';
  const endDate = filter?.end_date ?? '0000-00-00';
  const splitInit = initDate.split('-');
  const splitEnd = endDate.split('-');

  return {
    print_type: {
      complete_list: false,
      visualized_list: false,
      personalized_list: true,
    },
    list_content: {
      with_data: true,
    },
    worker_signature: {
      print_signature: false,
    },
    worker_type: {
      all: true,
      ...wTypes,
    },
    dates: {
      initial_date: new Date(
        splitInit[0],
        splitInit[1] - 1,
        splitInit[2],
      ),
      final_date: new Date(
        splitEnd[0],
        splitEnd[1] - 1,
        splitEnd[2],
      ),
    },
    structure: {
      branch: null,
      employer_registration: null,
      workgroup: "*",
      [catalog]: null,
    },
    separate_by: {
      employer_registration: false,
      branch: false,
      workgroup: false,
      [catalog]: false,
    },
    order: {
      employer_registration: false,
      employer_registration_branch: false,
      [`employer_registration_branch_${catalog}`]: false,
    },
  };
}

// Footer

export const symbols = [
  { description: "Existe un ajuste de asistencia en el día", corner_value: "adjust" },
  { description: "Las horas laboradas fueron mayores a la jornada", corner_value: "over" },
  { description: "Las horas laboradas fueron menor a la jornada", corner_value: "less" },
  { description: "Retardo", corner_value: "late" },
  { description: "Incidencia de horarios", corner_value: "incidence" },
  { label: "F", description: "Falta", color: " var(--scarletRed, #fe1f1f) !important" },
  { label: "FR", description: "Falta de registro (No cuenta como falta)", color: " var(--scarletRed, #fe1f1f) !important" },
  { label: ".", description: "Checada completa (entrada y salida)", color: "var(--egyptianBlue) !important" },
  { label: "/", description: "Checada incompleta (falta una entrada o salida)", color: "var(--egyptianBlue) !important" },
  { label: "?", description: "Checada dentro de un día en el que hay ausentismos", color: "var(--egyptianBlue) !important" },
  { icon: "apply-breaks", className: "footbar-icon apply-breaks", description: "Día de descanso" },
  { icon: "apply-holidays", className: "footbar-icon apply-holidays", description: "Día festivo" },
  { icon: "vacation", className: "footbar-icon vacations", description: "Vacaciones" },
  { icon: "con_goce", className: "footbar-icon con-goce", description: "Permiso con goce" },
  { icon: "sin_goce", className: "footbar-icon sin-goce", description: "Permiso sin goce" },
  { icon: "imss", className: "footbar-icon imss-inability", description: "Incapacidad" }
];

export const coomonExcludeFilters = ['complete_period', 'selected_period', 'cell_selected', 'branch'];

export const ValidationSchema = yup.object().shape({
  month: stringValidation({ required: true })
});


// Attendance contexts

export const AttendanceCalendarContext = createContext();
export const AttendanceSignContext = createContext();
export const BankHoursContext = createContext();
export const CalendarMenuControllerContext = createContext();
export const AttendanceContext = createContext();
export const ModalContext = createContext();
export const FormAbsenteeismListContext = createContext();
export const KioscoPreRequestContext = createContext();