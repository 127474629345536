import { lazy } from 'react';

const abrhilIcons = {
	corporate: {
		allowedTag: "Corporate",
		Icon: lazy(async () => await import("./Corporativo.svg"))
	},
	enterprise: {
		allowedTag: "Enterprise",
		Icon: lazy(async () => await import("./Empresas.svg")),
	},
	imss: {
		allowedTag: "Imss",
		Icon: lazy(async () => await import("./Imss.svg")),
	},
	attendance: {
		allowedTag: "Attendance",
		Icon: lazy(async () => await import("./AttendanceList.svg")),
	},
	migration: {
		allowedTag: "Migration",
		Icon: lazy(async () => await import("./Migracion.svg")),
	},
	payrolls: {
		allowedTag: "Payrolls",
		Icon: lazy(async () => await import("./Nominas.svg")),
	},
	piechart: {
		allowedTag: "Pie-Chart",
		Icon: lazy(async () => await import("./PieChart.svg")),
	},
	tools: {
		allowedTag: "Tools",
		Icon: lazy(async () => await import("./herramientas.svg")),
	},
	pushpin: {
		allowedTag: "Pushpin",
		Icon: lazy(async () => await import("./pushpin.svg")),
	},
	token: {
		allowedTag: "Token",
		Icon: lazy(async () => await import("./token.svg")),
	},
	copy: {
		allowedTag: "Copy",
		Icon: lazy(async () => await import("./Copy.svg")),
	},
	settings: {
		allowedTag: "Settings",
		Icon: lazy(async () => await import("./settings.svg")),
	},
	picture: {
		allowedTag: "Picture",
		Icon: lazy(async () => await import("./picture.svg")),
	},
	shield: {
		allowedTag: "Shield",
		Icon: lazy(async () => await import("./shield.svg")),
	},
	externalroles: {
		allowedTag: "ExternalRoles",
		Icon: lazy(async () => await import("./external_roles.svg")),
	},
	poweronoff: {
		allowedTag: "PowerOnOff",
		Icon: lazy(async () => await import("./power_on_off.svg")),
	},
	plus: {
		allowedTag: "Plus",
		Icon: lazy(async () => await import("./plus.svg")),
	},
	message: {
		allowedTag: "Message",
		Icon: lazy(async () => await import("./message.svg")),
	},
	edit: {
		allowedTag: "Edit",
		Icon: lazy(async () => await import("./edit.svg")),
	}
};

export default abrhilIcons;
