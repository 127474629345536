// @flow
import React from 'react';
import Grid from "@mui/material/Grid";
import Grid2 from "@mui/material/Grid2";
// import type { DividerProps } from "./ComponentsPropTypes";
/**
 * Elemento divisor custimizado para el sistema
 * @param {DividerProps} params
 *
 * @returns {JSX.Element}
 */
const Divider = ({
	style,
	className,
	asV2 = false,
	...others
}/* : DividerProps */) => {

	const Container = asV2 ? Grid2 : Grid;
	const size = asV2 ? { size: 12 } : { item: true, xs: 12 };

	return (
		<Container {...size} {...others}>
			<hr className={`${className} abrhil-divider`} style={style} />
		</Container>
	);
};

export default Divider;