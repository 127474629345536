import React from 'react'
import "./styles.scss"
import Edge from './assets/edge_icon.svg'
import Chrome from './assets/chrome_icon.svg'
import Firefox from './assets/firefox_icon.svg'
import { uid } from "uid";

const navegators = [
    Edge,
    Chrome,
    Firefox
];

function NavMessage({
    width, height
}) {
    return (
        <div className="nav-message-wrap">
            <div className="navegatores-wrap">
                <span>Navegadores recomendados</span>
                <div className="navegatores">
                    {navegators.map(El => (
                        <div key={uid()} style={{ width, height }}>
                            <El />
                        </div>
                    ))}
                </div>
            </div>
            <div className="line"></div>
            <span className="message">
                El uso de otros navegadores podría causar inconsistencias o limitaciones en las funcionalidades.
            </span>
        </div>
    )

}
export default NavMessage