import PropTypes from "prop-types"
import React, { useEffect, useState } from "react";
import { t } from 'i18next';
import { cloneDeep } from "lodash";
import Grid from '@mui/material/Grid2';
import SwitchItem from "./SwitchItem";
import Fieldset from '../../../../layout/Fieldset';
import StyledFormControlLabel from "../../../../../styled/StyledFormControlLabel";
import SwitchMuiCustom from "../../../../Inputs/Booleans/SwitchMui";
import { getArrayItemKey } from "../../../../general/GeneralUtilities";

const styleContainer = {
  maxHeight: '540px',
  overflow: 'auto'
};

const Fields = (props) => {
  const {
    type,
    fieldSelection,
    setFieldSelection,
    setCheckedAll
  } = props;


  const renderByColumns = Array.isArray(fieldSelection.at(0));
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (type !== "review") {
      handleSwitch(type);
    }
    // eslint-disable-next-line
  }, [type]);

  function handleCheckAll() {
    const newField = [...fieldSelection];

    newField.forEach(element => {
      element.forEach(element2 =>
        element2.isSelected = !checkAll
      )
    });

    setCheckAll(!checkAll);
    setFieldSelection(newField);
  }

  const handleChangeSwitch = (fieldId, fieldState, columnIndex) => {
    const updatedField = renderByColumns
      ? fieldSelection[columnIndex][fieldId]
      : fieldSelection[fieldId];

    updatedField.isSelected = fieldState;

    const clone = cloneDeep(fieldSelection);
    clone[columnIndex][fieldId] = updatedField;

    setFieldSelection(clone);

  }

  const handleSwitch = (type) => {

    setCheckedAll(true);

    for (let prop in fieldSelection) {

      const updatedField = fieldSelection[prop];

      if (type !== "review") {
        updatedField.isSelected = true;
      }

      const newField = [...fieldSelection];
      newField[prop] = updatedField;

      setFieldSelection(newField);
    }
  };


  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid>
        <div disabled={type !== 'review'}>
          <Fieldset title={t('information-tab')} className={"export-fieldset"} container spacing={1} fieldsetStyle={styleContainer}>
            {renderByColumns &&
              <Grid size={4}>
                <StyledFormControlLabel
                  control={
                    <SwitchMuiCustom
                      checked={checkAll}
                      onChange={handleCheckAll}
                    />
                  }
                  label={checkAll ? t("uncheck-all") : t("check-all")}
                />
              </Grid>}
            {renderByColumns && (
              <RenderByColumns
                columns={fieldSelection}
                handleSelectedChange={handleChangeSwitch}
              />
            )}

            {!renderByColumns && fieldSelection.map((field, index) => (
              <Grid size={4} key={field.name}>
                <SwitchItem
                  field={field}
                  fieldId={index}
                  type={type}
                  handleSelectedChange={handleChangeSwitch}
                />
              </Grid>
            ))}
          </Fieldset>
        </div>
      </Grid>
    </Grid>
  );
};

Fields.propTypes = {
  fieldSelection: PropTypes.any,
  setCheckedAll: PropTypes.func,
  setFieldSelection: PropTypes.func,
  type: PropTypes.string
}

function RenderByColumns(props) {

  const columns = props.columns;
  const type = props.type;
  const handleSelectedChange = props.handleSelectedChange;

  return (
    <Grid container size={12}>
      {columns.map((column, index) =>
        <Grid
          key={getArrayItemKey(index, 'export-column')}
          size={12 / columns.length}
          direction="column"
        >
          {column?.map((field, _index) =>
            <Grid size={12} key={field.name}>
              <SwitchItem
                field={field}
                fieldId={_index}
                type={type}
                columnIndex={index}
                handleSelectedChange={handleSelectedChange}
              />
            </Grid>)}
        </Grid>)}
    </Grid>
  );
}

RenderByColumns.propTypes = {
  columns: PropTypes.array,
  handleSelectedChange: PropTypes.func,
  type: PropTypes.any
}

export default Fields;