import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    TreeView,
} from "@progress/kendo-react-treeview";
import './styles/style.scss';
import { ItemIcon } from "../../navigation/components/SideMenu/Search/NavItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    // faXmark,
    faChevronDown,
} from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence, motion } from "framer-motion";
import { debounce, isArray, isNull, size, uniq } from "lodash";
import { concidence, markText } from "../../navigation/utilities";
import { useTranslation } from "react-i18next";
import KendoCheckbox from "../../Inputs/Booleans/KendoCheckbox";
import Button from "../../../styled/Button";
import { scaleAnimation, searchTree, updateTreeMenu } from "./Utils";
import GridNoRecordsRender from "../../Templates/GridNoRecordsRender";
import { CustomSearch } from "./Search";
import FormError from "../Error";

function PermissionsTree({
    setValue,
    permissions,
    disabled,
    name,
    control,
    errorName,
    animate,
    maxHeight,
    validate,
    validateDash,
    dashboard,
    dashboardName,
    // additionalSettings = true,
    dashboardSettings,
}) {

    const { t } = useTranslation();
    const [current, setCurrent] = useState(null);
    const [checkRead, setCheckRead] = useState(false);
    const [checkWrite, setCheckWrite] = useState(false);
    const controlItems = useRef({
        [t('access')]: { data: [], method: setCheckRead, value: checkRead },
        [t('write')]: { data: [], method: setCheckWrite, value: checkWrite },
    });
    const selectedValidation = useRef(null);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [dataSearch, setDataSearch] = useState(null);
    const searchRef = useRef(null);
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkeds, setCheckeds] = useState([]);
    const [actionsDisabled, setActionsDisabled] = useState(true);
    const [collapseAll, setCollapseAll] = useState(false);
    const validateRef = useRef(false);
    const validateDashRef = useRef(false);
    const update = useRef(false);

    useEffect(() => {
        if (!size(permissions)) return;
        update.current = true;
        const allDisabled = isNull(searchTree(permissions, 'disabled', false, 'items'));
        const newMenu = updateTreeMenu(permissions, null, true, true, controlItems, selectedValidation);
        setData(newMenu);
        setActionsDisabled(allDisabled);
    }, [permissions])

    useEffect(() => {
        if (!dashboard) return;
        selectedValidation.current = dashboard;
        setCurrent(() => ({ ...dashboard }));
    }, [dashboard])

    useEffect(() => {
        if (size(data) && update.current) {
            const formatedItems = formatItems(data);
            const cleanItems = uniq(formatedItems);
            validateItemSelected();
            setCheckeds(cleanItems);
        }
    }, [data])

    useEffect(() => {
        const valid = validate && validateRef.current;
        if (update.current && name) {
            setValue(name, checkeds, { shouldValidate: valid });
            validateRef.current = false;
        }
    }, [checkeds])

    useEffect(() => {
        update.current = false;
        if (search) {
            let copyMenu = JSON.parse(JSON.stringify(data));
            const searchMenu = searchInMenu(copyMenu, search);
            setDataSearch(searchMenu);
        } else {
            setDataSearch(null);
        }
    }, [search])

    useEffect(() => {
        if (!dataSearch) {
            massiveUpdate('expanded', false);
        } else if (update.current) {
            const dataSync = syncData(dataSearch);
            setData(dataSync);
        }
    }, [dataSearch])

    useEffect(() => {
        validateControlValues();
    }, [isSearchValue()])

    useEffect(() => {
        const valid = validateDash && validateDashRef.current;
        if (dashboardName) {
            setValue(dashboardName, current, { shouldValidate: valid });
            validateDashRef.current = false;
        }
    }, [current])

    function syncData(searchData) {
        let newMenu = [];
        searchData.forEach(el => {
            newMenu = data.map(li => syncTreeMenu(li, el));
        });
        return newMenu;
    }

    function formatItems(items) {
        let permissions = [];
        for (const item of items) {
            if (Object.prototype.hasOwnProperty.call(item, 'permission_id')) {
                if (item.checked) {
                    permissions.push(item.permission_id);
                } else {
                    permissions = permissions.filter(el => el !== item.key);
                }
            }
            if (item?.items && isArray(item?.items) && size(item?.items) > 0) {
                const childPermissions = formatItems(item.items);
                permissions.push(...childPermissions);
            } else if(item?.element_id) {
                if (item.checked) {
                    permissions.push(item.element_id);
                } else {
                    permissions = permissions.filter(el => el !== item.key);
                }
            }
        }
        return permissions;
    }

    function isSearchValue() {
        if (dataSearch) {
            return dataSearch;
        }
        return data;
    }

    function isSearchState() {
        if (dataSearch) {
            return setDataSearch;
        }
        return setData;
    }

    const onItemClick = (event) => {
        validateDashRef.current = true;
        if (!dashboardSettings || event.item?.checked === false) return;
        if (!event.item?.items) return;
        if (!event.item.items.some(el => el.permission_id)) return;
        if (current?.key === event.item?.key) {
            const kpis = data.find(el => el.key === 'kpis');
            if (kpis.checked !== false) {
                setCurrent(kpis);
            }
        } else {
            const id = event.itemHierarchicalIndex;
            setCurrent({ ...event.item, idIndex: id });
        }
    };

    const collapseMassive = () => {
        setCollapseAll(!collapseAll);
        update.current = false;
        massiveUpdate('expanded', !collapseAll);
    };

    const checkAll = (e) => {
        validateRef.current = true;
        const value = checkedAll === null ? false : e.value ?? e.target.value;
        massiveUpdate('checked', value);
        setCheckedAll(value);
    }

    const checkAllRead = (e) => {
        validateRef.current = true;
        const value = checkRead === null ? false : e.value ?? e.target.value;
        let valuesToUpdate = [t('access')];
        setCheckRead(value);
        if (!value) {
            valuesToUpdate = [...valuesToUpdate, t('write')];
            setCheckWrite(value);
        }
        massiveUpdate('checked', value, valuesToUpdate, 'info');
    }

    const checkAllWrite = (e) => {
        validateRef.current = true;
        const value = checkWrite === null ? false : e.value ?? e.target.value;
        setCheckWrite(value);
        massiveUpdate('checked', value, [t('write')], 'info');
    }

    const onExpandChange = (event) => {
        update.current = false;
        let newItem = event.item;
        const method = isSearchState();
        const newValue = !newItem.expanded;
        newItem.expanded = newValue;
        const newMenu = updateTreeMenu(isSearchValue(), newItem, false);
        method(newMenu);
    };

    const onCheckChange = (item) => {
        update.current = true;
        validateRef.current = true;
        const method = isSearchState();
        let newItem = { ...item };
        const value = newItem.checked === null ? false : !newItem.checked;
        newItem = handleRecursive(newItem, value, 'checked');
        const newMenu = updateTreeMenu(isSearchValue(), newItem, true, true, controlItems, selectedValidation);
        item.checked = newItem.checked;
        method(newMenu);
    };

    function validateControlValues() {
        const controlValues = controlItems.current;
        let allChecked = true;
        let allData = [];
        for (let name in controlValues) {
            const data = controlValues[name].data;
            const method = controlValues[name].method;
            const falseExists = data.some(el => el === false);
            const trueExists = data.some(el => el === true);
            allData = [...allData, ...data];
            if (falseExists) {
                method(false);
            }
            if (trueExists) {
                method(true);
            }
            if (falseExists && trueExists) {
                method(null);
            }
        }
        const falseExists = allData.some(el => el === false);
        const trueExists = allData.some(el => el === true);
        if (falseExists) {
            allChecked = false;
        }
        if (trueExists) {
            allChecked = true;
        }
        if (falseExists && trueExists) {
            allChecked = null;
        }
        setCheckedAll(allChecked);
    }

    function validateItemSelected() {
        if (!selectedValidation.current) {
            setCurrent(null);
        }
    }

    function handleRecursive(item, value, field, filter, fieldToFilter) {
        if (item?.initial_disabled && field === 'checked') {
            item[field] = false;
        } else if (isArray(filter) && filter?.includes(item[fieldToFilter]) || item[fieldToFilter] === filter) {
            item[field] = value;
        }
        if (item?.items && size(item.items) > 0) {
            item.items = item.items.map(child => handleRecursive(child, value, field, filter, fieldToFilter));
        }
        return item;
    }

    function massiveUpdate(field, value, filter, fieldToFilter) {
        if (!data) return;
        if (field === 'checked') {
            update.current = true;
        } else {
            update.current = false;
        }
        const method = isSearchState();
        let newMenu = JSON.parse(JSON.stringify(isSearchValue()));
        const updatedMenu = newMenu.map(el => handleRecursive(el, value, field, filter, fieldToFilter));
        const validatedMenu = updateTreeMenu(updatedMenu, null, true, true, controlItems, selectedValidation);
        method(validatedMenu);
    }

    function syncTreeMenu(originalItem, item) {
        if (originalItem?.key === item?.key) {
            if (originalItem?.checked !== item?.checked) {
                originalItem.checked = item.checked;
            }
            if (originalItem?.items && item?.items) {
                item.items.forEach(el => {
                    originalItem.items = originalItem.items.map(li => syncTreeMenu(li, el));
                    return originalItem;
                });
            }
        }
        return originalItem;
    }

    function searchInMenu(menu, keyToSearch) {
        let filteredMenu = menu.map((el) => filterItems(el, keyToSearch)).filter(item => item !== undefined);
        filteredMenu.forEach((el, index) => findItem(el, keyToSearch, index, filteredMenu));
        return filteredMenu;
    }

    function filterItems(item, keyToSearch) {
        if (concidence(item?.info, keyToSearch)) {
            return item;
        } else if (item?.items) {
            item.items = item.items.map((el) => filterItems(el, keyToSearch)).filter(item => item !== undefined);
            if (item.items.every(el => el === undefined)) return undefined;
            return item;
        }
    }

    function findItem(parent, keyToSearch, index, menu) {
        if (keyToSearch) {
            if (concidence(parent.info, keyToSearch)) {
                menu[index] = { ...parent, expanded: true };
            }
        }
        if (parent?.items && isArray(parent?.items) && size(parent?.items) > 0) {
            for (const [childIndex, child] of parent.items.entries()) {
                findItem(child, keyToSearch, childIndex, parent.items);
            }
            if (parent.items.some(el => el.expanded === true)) {
                menu[index].expanded = true;
            }
        }
    }

    const debounceSearch = useMemo(() => {
        return debounce(handleSearch, 300);
        //eslint-disable-next-line
    }, []);

    function handleSearch(value) {
        setSearch(value);
    }

    function handleClear() {
        searchRef.current.value = '';
        setSearch('');
    }

    const onChangeSearch = (e) => {
        const value = e.value ?? e.target.value;
        debounceSearch(value);
        return e;
    }

    return (
        <div className="main-permissions-container">
            <div className="permissions-container">
                <div className="permissions-header">
                    <div className="permissions-search-box-container">
                        <CustomSearch
                            searchRef={searchRef}
                            search={search}
                            onChange={onChangeSearch}
                            handleClear={handleClear}
                            maxWidth='60%'
                        />
                        <AnimatePresence mode='wait' initial={animate}>
                            {
                                !dataSearch && size(data) ?
                                    <motion.div
                                        key='collapse-all-button'
                                        {...scaleAnimation}
                                    >
                                        <Button
                                            className="permissions-button-collapse"
                                            onClick={collapseMassive}
                                        >
                                            <motion.div
                                                initial={false}
                                                animate={{
                                                    rotate: collapseAll ? 180 : 0,
                                                }}
                                                style={{
                                                    padding: 0,
                                                    margin: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </motion.div>
                                        </Button>
                                    </motion.div>
                                    :
                                    null
                            }
                        </AnimatePresence>
                    </div>
                    <AnimatePresence mode='wait' initial={animate}>
                        {
                            !dataSearch || (dataSearch && size(dataSearch)) ?
                                <motion.div
                                    className="buttons-wrapper"
                                    key='buttons-header-wrapper'
                                    {...scaleAnimation}
                                >
                                    <div className="permissions-button">
                                        <KendoCheckbox
                                            checked={checkedAll}
                                            onChange={checkAll}
                                            formAlign={false}
                                            disabled={actionsDisabled || disabled}
                                            className="permissions-button-checkbox"
                                            label="Todos"
                                        />
                                    </div>
                                    <div className="permissions-button">
                                        <KendoCheckbox
                                            checked={checkRead}
                                            onChange={checkAllRead}
                                            formAlign={false}
                                            disabled={actionsDisabled || disabled}
                                            className="permissions-button-checkbox"
                                            label={t('access')}
                                        />
                                    </div>
                                    <div className="permissions-button">
                                        <KendoCheckbox
                                            checked={checkWrite}
                                            onChange={checkAllWrite}
                                            disabled={actionsDisabled || disabled}
                                            formAlign={false}
                                            className="permissions-button-checkbox"
                                            label={t('write')}
                                        />
                                    </div>
                                </motion.div>
                                :
                                null
                        }
                    </AnimatePresence>
                </div>
                <div
                    className="permissions-treeview-container"
                    style={{
                        maxHeight: maxHeight,
                    }}
                >
                    <AnimatePresence mode='wait' initial={animate}>
                        {
                            dataSearch && size(dataSearch) === 0 ?
                                <motion.div
                                    className="no-results"
                                    key='no-results-message'
                                    {...scaleAnimation}
                                >
                                    <GridNoRecordsRender init={true} filter={true} />
                                </motion.div>
                                :
                                <motion.div
                                    key='tree-permissions'
                                    {...scaleAnimation}
                                >
                                    <TreeView
                                        id="tree-permissions"
                                        data={dataSearch ?? data}
                                        onExpandChange={onExpandChange}
                                        aria-multiselectable={false}
                                        textField={"info"}
                                        disableField="no-field"
                                        expandIcons
                                        animate
                                        item={(item) =>
                                            <CustomItem
                                                item={item}
                                                search={search}
                                                disabled={disabled}
                                                onChange={onCheckChange}
                                                onItemClick={onItemClick}
                                                current={current}
                                            />
                                        }
                                    />
                                </motion.div>
                        }
                    </AnimatePresence>
                </div>
                <motion.div
                    key='error-message-p'
                    {...scaleAnimation}
                >
                    <FormError control={control} name={errorName ?? name} />
                </motion.div>
            </div>
            {/* <AnimatePresence>
                {
                    additionalSettings && current &&
                    <AdditionalSettings
                        keyItem={"additional-container"}
                        item={current}
                        setCurrent={setCurrent}
                    />
                }
            </AnimatePresence> */}
        </div>
    );
}

PermissionsTree.propTypes = {
    animate: PropTypes.any,
    control: PropTypes.any,
    dashboard: PropTypes.any,
    dashboardName: PropTypes.any,
    dashboardSettings: PropTypes.any,
    disabled: PropTypes.any,
    errorName: PropTypes.any,
    maxHeight: PropTypes.any,
    name: PropTypes.any,
    permissions: PropTypes.any,
    setValue: PropTypes.func,
    validate: PropTypes.any,
    validateDash: PropTypes.any
}

export default React.memo(PermissionsTree);

export const CustomItem = ({
    item,
    search,
    disabled,
    onChange,
    current,
    label,
}) => {
    const [check] = useState(item.item.checked);
    const text = label ? label : item.item.info;
    const isDisabled = item?.item?.disabled || disabled;

    function validateSelected() {
        if (current) {
            if (current.key === item?.item?.key) {
                return 'current';
            }
        }
        return '';
    }

    return (
        <div className="item-container">
            <div className="item-checkbox">
                <KendoCheckbox
                    checked={check}
                    disabled={isDisabled}
                    className="item-checkbox-component"
                    onChange={() => onChange(item.item)}
                    formAlign={false}
                />
            </div>
            <div className={`item-content ${isDisabled ? 'disabled' : ''} ${validateSelected()}`} onClick={() => !isDisabled ? onChange(item.item) : null}>{/*NOSONAR*/}
                {item.item.icon &&
                    <span className="item-icon">
                        {<ItemIcon icon={item.item.icon} />}
                    </span>
                }
                <span className="item-text">{markText(text, search)}</span>
            </div>
        </div>
    );
}
CustomItem.propTypes = {
    current: PropTypes.any,
    disabled: PropTypes.any,
    item: PropTypes.any,
    label: PropTypes.any,
    onChange: PropTypes.func,
    search: PropTypes.any
}
