import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import FormDialog from '../../../../../../../../../../general/@components/modal/dialog/FormDialog';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import DialogContent from '../../../../../../../../../../general/styled/DialogContent';
import { DayCalculateContext } from '../../../contexts/DayCalculateContext';
import { each, trim } from 'lodash';
import { clearAccents } from '../../../../../../../../../../general/@components/SpeechRecognition/utils/commands';
import { BasicDialogActions } from '../../../../../../../../../../general/@components/form/BasicDialogActions';
import { workerFullName } from '../../../../../../../../../../general/@components/employees/constants';
import { reversDate } from '../../../../../../../../../../general/@components/PayrollPeriod/itemUtilities';
import IconUse from '../../../../../../../../../../general/@components/uiDesign/IconUse';
import { InfoTag } from '../TimePayment';
import FormInput from '../../../../../../../../../../general/@components/form/Field';
import { buildCheck, buildHour } from '../../../utils/utilities';
import { getCheck } from '../../Cards/WorkingDay';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useWatch } from 'react-hook-form';
import { IconButton } from '../../../../../../../../../../general/@components/Buttons';
import { faWandMagicSparkles } from '@fortawesome/pro-light-svg-icons';
import { gridSize } from '../../../../../../../../../../general/@components/general/GeneralUtilities';
import GenericLabel from '../../../../../../../../../../general/@components/general/GenericLabel/GenericLabel';
import Loader from '../../../../../../../../../../general/@components/general/Loader/Loader';
import './styles/_dialog.scss';

export const dateString = ({ day_name, day, month, year }) => year ? `${day_name}, ${day} de ${month} del ${year}` : null;

function DayCalculate() {
    const { t } = useTranslation();

    const {
        worker,
        control,
        handleSubmit,
        onClose,
        selected,
        onSubmit,
        open,
    } = useContext(DayCalculateContext);

    const { config } = selected?.overtime ?? {};

    const mode = clearAccents(config?.shift_mode?.toLowerCase());
    const shiftMode = {
        'libre': 'Libre',
        'lunes-viernes': 'Lu-Vi',
        'sabado-domingo': 'Sá-Do',
    }[mode];

    const configs = [
        { icon: 'schedule', title: 'Tipo de horario', value: config?.shift_type },
        { icon: 'Calculation', title: 'Tipo de cálculo', value: config?.calculation_overhours },
        { icon: 'Asigne-Structure', title: 'Horas jornada', value: trim(`${(config?.journey_hours ?? 0) / 60} horas ${shiftMode ?? ''}`), tooltip: config?.shift_mode },
        { icon: 'Rounding', title: 'Redondeo', value: `${config?.minutes_rounding} minutos` },
    ];

    return (
        <FormDialog
            open={open}
            title={t('Calculo del día')}
            className="day-calculate-dialog minimalist-dialog-design"
            innerSubtitle={'Determine cómo se liberarán las horas extras del colaborador en el día.'}
            headerDivider
            maxWidth="md"
            handleClose={onClose}
        >
            <DialogContent>
                <Grid
                    key='day-calculate-main-wrap'
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid
                        key='worker-day-origin'
                        container
                        {...gridSize(12)}
                        sx={{ justifyContent: "space-between" }}
                    >
                        <Grid>
                            <GenericLabel
                                label={t('worker')}
                                value={workerFullName({ ...worker, include_key: true })}
                                fontSize="14px"
                            />
                        </Grid>
                        <Grid>
                            <GenericLabel
                                label={t('origin-day')}
                                value={reversDate(selected?.origin_day)}
                                fontSize="14px"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        key='config-items-wrap'
                        container
                        spacing={2}
                        {...gridSize(12)}
                    >
                        {configs.map(el =>
                            <Grid key={el.title} container {...gridSize(3)} className="option-config-item">
                                <Grid container spacing={1}>
                                    <Grid>
                                        <IconUse icon={el.icon} />
                                    </Grid>
                                    <Grid {...gridSize("auto")}>
                                        {el.title}
                                    </Grid>
                                </Grid>
                                <Grid {...gridSize(12)}>
                                    <InfoTag
                                        label={el.value}
                                        withIcon={false}
                                        labelStyle={{ fontWeight: '500' }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        key='calc-type-options'
                        container
                        spacing={2}
                        {...gridSize(12)}
                    >
                        <FormInput
                            control={control}
                            fieldInput="Radiogroup"
                            name="option"
                            options={[
                                { label: t('Por horario'), value: 2, desc: 'Las horas extras se liberarán ajustando las checadas al horario asignado al colaborador.', bottomRender: <ByScheduleContent /> },
                                { label: t('Por checada'), value: 1, desc: 'Las horas extras se liberarán según el horario real en que el colaborador marcó sus entradas y salidas.', bottomRender: <ByCheckContent /> },
                            ]}
                            valueField="value"
                            textField="label"
                            highlight
                            outlined
                            isNumericValue
                            modernDesign
                            row={false}
                            gap={16}
                            isRequired
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <BasicDialogActions
                handleSubmit={handleSubmit}
                onCancel={onClose}
                onSubmit={onSubmit}
                saveLabel="request_"
            />
        </FormDialog>
    );
};

export default DayCalculate;

const IncidencesContent = () => {
    const { t } = useTranslation();

    const { control } = useContext(DayCalculateContext);

    const fields = useWatch({ control, name: 'checks' });
    const selection = useWatch({ control, name: 'option' });

    const getCheckValue = (value, type) => {
        let fValue = getCheck(value, false, type);
        if (!fValue) {
            fValue = getCheck(value, true, type);
        }
        return fValue;
    }

    if (selection !== 2) return null;

    return (
        <>
            {fields.length > 0 &&
                <Grid
                    key='incidences-list-wrapper'
                    container
                    {...gridSize(12)}
                    spacing={0}
                    direction="column"
                >
                    <Grid
                        container
                        spacing={1}
                        direction="column"
                        {...gridSize(12)}
                        sx={{
                            paddingTop: '10px'
                        }}
                    >
                        {fields.map((el, ind) =>
                            <Grid
                                key={`check-incidence-${el.id}`}
                                container
                                {...gridSize(12)}
                                spacing={3}
                                direction="row"
                                wrap="nowrap"
                                className="day-calculate-incidence-item vertical-center"
                            >
                                <Grid>
                                    <IconUse icon="Clock" />
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid>
                                        <GenericLabel
                                            label={t('check-in')}
                                            value={buildCheck(getCheckValue(el, 0))}
                                        />
                                    </Grid>
                                    <Grid>
                                        <GenericLabel
                                            label={t('check-out')}
                                            value={buildCheck(getCheckValue(el, 1))}
                                        />
                                    </Grid>
                                </Grid>
                                <ScheduleSelection itemIndex={ind} item={el} />
                                <ItemActions itemIndex={ind} item={el} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>}
        </>
    );
};

const ScheduleSelection = ({ itemIndex, item }) => {
    const { t } = useTranslation();

    const {
        control,
        schedules,
        loadingResolve,
        loading,
    } = useContext(DayCalculateContext);

    const editing = useWatch({ control, name: `checks.[${itemIndex}].editing` });
    const userSelection = item?.schedule;
    const isResolved = item?.clock_process?.[0]?.incidence_resolved;
    const selectedSchedule = schedules.find(el => el.id === userSelection);

    if (loading) {
        return (
            <Grid>
                <Loader size={16} />
            </Grid>
        );
    }

    return (
        <Grid {...(editing ? gridSize(3) : {})}>
            {editing ?
                <FormInput
                    fieldInput="Dropdown"
                    control={control}
                    name={`checks.[${itemIndex}].schedule`}
                    data={schedules}
                    label="Horario"
                    textField='name'
                    valueField='id'
                    disabled={loadingResolve}
                    format={'name|start|end'}
                    isRequired
                    noDefault
                />
                :
                <GenericLabel
                    label={t('schedule')}
                    className={`${!isResolved ? 'no-schedule' : ''}`}
                    value={isResolved ? `${selectedSchedule?.name} - (${selectedSchedule?.start} - ${selectedSchedule?.end})` : t('incidence')}
                />}
        </Grid>
    );
}

ScheduleSelection.propTypes = {
  item: PropTypes.any,
  itemIndex: PropTypes.any
}

const ItemActions = ({ itemIndex, item }) => {
    const { t } = useTranslation();

    const editPath = `checks.[${itemIndex}].editing`;
    const valuePath = `checks.[${itemIndex}].schedule`;
    const {
        control,
        setValue,
        resolveIncidence,
    } = useContext(DayCalculateContext);

    const backupValue = useRef();

    const editing = useWatch({ control, name: editPath });
    const schedule = useWatch({ control, name: valuePath });
    const isLoading = useWatch({ control, name: `checks[${itemIndex}].loading` });
    const isResolved = item?.clock_process?.[0]?.incidence_resolved;

    const unedit = () => setValue(editPath, false);

    const onEdit = () => {
        backupValue.current = item?.schedule;
        setValue(editPath, true);
    }

    const onCancel = () => {
        unedit();
        setValue(valuePath, backupValue.current);
    }

    const onSave = async () => {
        if (!isResolved) {
            await resolveIncidence(item, item?.schedule, itemIndex);
        } else {

        }
        unedit();
    }

    const onAutoResolve = async () => {
        const bestSchedule = item?.clock_process?.[0]?.shift;
        await resolveIncidence(item, bestSchedule, itemIndex);
        unedit();
    }

    return (
        <Grid
            container
            spacing={1}
            direction="row"
            className="day-calculate-check-item-actions"
        >
            {(!editing && !isResolved) &&
                <Grid>
                    <IconButton
                        icon={faWandMagicSparkles}
                        onClick={onAutoResolve}
                        loading={isLoading}
                        disabled={isLoading}
                        title={t('Resolver automáticamente')}
                    />
                </Grid>}
            <Grid>
                <IconButton
                    key={`dynamic-action-${editing}`}
                    icon={!editing ? "Edit" : faXmark}
                    onClick={!editing ? onEdit : onCancel}
                    disabled={isLoading}
                    color={editing ? 'error' : ''}
                    title={t(!editing ? 'edit' : 'cancel')}
                />
            </Grid>
            {editing &&
                <Grid className="vertical-center">
                    <IconButton
                        icon={faCheck}
                        onClick={onSave}
                        title={t('save')}
                        loading={isLoading}
                        disabled={!schedule}
                    />
                </Grid>}
        </Grid>
    );
};

ItemActions.propTypes = {
  item: PropTypes.any,
  itemIndex: PropTypes.any
}

function getTotalMinutes(checks, byCheck) {
    let totalMinutes = 0;
    each(checks, (check) => {
        const totalClock = check?.clock_process?.[0]?.total_minutes_calculated;
        const total = parseInt((byCheck ? (check?.total_minutes ?? 0) : (totalClock ?? 0)));
        totalMinutes += total;
    });
    return totalMinutes;
}

const ByScheduleContent = () => {

    return (
        <Grid
            container
            {...gridSize(12)}
            spacing={2}
            style={{ paddingTop: '10px' }}
        >
            {/* <Grid {...gridSize(6)}>
                <InfoTag label={'Horario asignado: '} />
            </Grid>
            <Grid {...gridSize(6)}>
                <InfoTag label={'Horario asignado: '} />
            </Grid> */}
            <Grid {...gridSize(12)}>
                <IncidencesContent />
            </Grid>
            <Grid
                container
                {...gridSize(12)}
                spacing={2}
                direction="row"
                wrap="nowrap"
            >
                <ChecksSection />
                <HoursSection />
            </Grid>
        </Grid>
    );
};

const ChecksSection = ({ byCheck = false }) => {

    return (
        <Grid
            container
            {...gridSize(6)}
            direction="column"
            spacing={1}
            className="border-item-card"
            sx={{ padding: '10px 14px 20px 14px !important' }}
        >
            <Grid className="black-text" sx={{ lineHeight: 1 }}>Checadas</Grid>
            <ChecksList byCheck={byCheck} />
        </Grid>
    );
};

ChecksSection.propTypes = {
  byCheck: PropTypes.bool
}

const HoursSection = ({ byCheck = false }) => {

    const { selected } = useContext(DayCalculateContext);

    const {
        checks,
        overtime_real,
        total_real_rounded,
        overtime_calculated,
        total_calculated_rounded,
    } = selected?.overtime ?? selected ?? {};

    return (
        <Grid
            container
            {...gridSize(6)}
            direction="column"
            spacing={1}
        >
            <GeneratedHours
                workedHours={buildHour(getTotalMinutes(checks, byCheck))}
                convertedWorkerHours={buildHour(!byCheck ? total_calculated_rounded : total_real_rounded)}
                extraHours={buildHour(!byCheck ? overtime_calculated : overtime_real)}
                nightHours={buildHour(0)}
            />
        </Grid>
    );
};

HoursSection.propTypes = {
  byCheck: PropTypes.bool
}

const ByCheckContent = () => {

    return (
        <Grid
            container
            {...gridSize(12)}
            spacing={2}
            direction="row"
            wrap="nowrap"
            style={{ paddingTop: '10px' }}
        >
            <ChecksSection byCheck />
            <HoursSection byCheck />
        </Grid>
    );
};

const primaryColor = { color: 'var(--egyptianBlue)' };

const GeneratedHours = ({
    workedHours,
    convertedWorkerHours,
    nightHours,
    extraHours
}) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={1} {...gridSize(12)}>
            <Grid container {...gridSize(12)} className="vertical-center">
                <Grid {...gridSize(6)} className="black-text">{t('worked-hours')}</Grid>
                <Grid {...gridSize(6)}>
                    <InfoTag
                        withIcon={false}
                        label={
                            <span style={{ display: 'flex', gap: '3px' }}>
                                <span className="black-text">{workedHours}</span>
                                <IconUse icon="Slim-Arrow" />
                                <span style={primaryColor}>{convertedWorkerHours}</span>
                            </span>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container {...gridSize(12)} className="vertical-center">
                <Grid {...gridSize(6)} className="black-text">{t('nocturnal')}</Grid>
                <Grid {...gridSize(6)}>
                    <InfoTag label={nightHours} withIcon={false} />
                </Grid>
            </Grid>
            <Grid container {...gridSize(12)} className="vertical-center">
                <Grid {...gridSize(6)} className="black-text">{t('extra-hours')}</Grid>
                <Grid {...gridSize(6)}>
                    <InfoTag label={extraHours} withIcon={false} />
                </Grid>
            </Grid>
        </Grid>
    );
};

GeneratedHours.propTypes = {
  convertedWorkerHours: PropTypes.any,
  extraHours: PropTypes.any,
  nightHours: PropTypes.any,
  workedHours: PropTypes.any
}

const ChecksList = ({ byCheck = false }) => {

    const { selected } = useContext(DayCalculateContext);

    const { checks } = selected?.overtime ?? selected ?? {};

    return (
        <Grid
            container
            spacing={2}
            direction="row"
            {...gridSize(12)}
            sx={{
                justifyContent: 'center',
                flex: 1,
            }}
        >
            {checks?.map((el) =>
                <CheckItem
                    key={`check-item-${el.id}`}
                    check={el}
                    byCheck={byCheck}
                />
            )}
        </Grid>
    )
};

ChecksList.propTypes = {
  byCheck: PropTypes.bool
}

const CheckItem = ({
    check,
    byCheck,
}) => {
    const { t } = useTranslation();

    const checkIn = getCheck(check, byCheck, 0);
    const checkOut = getCheck(check, byCheck, 1);

    return (
        <Grid
            container
            spacing={2}
            direction="row"
            wrap="nowrap"
            {...gridSize("auto")}
        >
            <CheckTemplate
                value={checkIn}
                icon="Entry"
                label={t('check-in')}
            />
            <CheckTemplate
                value={checkOut}
                icon="Exit"
                label={t('check-out')}
            />
        </Grid>
    );
};

CheckItem.propTypes = {
  byCheck: PropTypes.any,
  check: PropTypes.any
}

const CheckTemplate = ({
    value,
    label,
    icon,
}) => {

    return (
        <Grid
            container
            spacing={1}
            direction="row"
            wrap="nowrap"
            {...gridSize(12)}
            className="day-calculate-check-item"
        >
            <Grid>
                <IconUse icon={icon} />
            </Grid>
            <Grid container spacing={0.3}>
                <Grid {...gridSize(12)} className="black-text">
                    {label}
                </Grid>
                <Grid {...gridSize(12)}>
                    {buildCheck(value)}
                </Grid>
            </Grid>
        </Grid>
    );
};
CheckTemplate.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any
}
