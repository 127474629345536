import { each, findIndex, isArray, isUndefined, omit } from "lodash";
import { getStructureFields } from "../filter/structureFilters";
import { store } from "../../../../store";

export const isVisible = (title, columns) => {
    const data = columns;
    const foundItem = data.find(item => item.value === title);
    const isInArray = foundItem !== undefined;
    return isInArray;
};

export const getExtras = (column, filters, structure, extraFilters = {}) => {
    const index = findIndex(structure, function (o) { return o.step === column; });
    const considere = getStructureFields(index, structure, "before");
    const values = filters?.filters || filters || {};

    each(considere, extra => {
        const extraValue = values[extra];
        if (!isUndefined(extraValue) && extraValue !== null && extraValue !== "") {
            extraFilters[extra] = extraValue;
        }
    });

    return extraFilters;

};

export const changeCallback = (filters, callback, column, structure) => {
    const index = findIndex(structure, function (o) { return o.step === column; });
    const afterFields = getStructureFields(index, structure, "after");
    const newFilters = filters?.filters || filters || {};
    each(afterFields, step => {
        if (newFilters[step]) {
            delete newFilters[step];
        }
    });
    callback({ filters: newFilters });
};

export const workerData = [
    { field: "name", location: "name", default: "", hasValidation: true },
    { field: "first_surname", location: "first_surname", default: "", hasValidation: true },
    { field: "second_surname", location: "second_surname", default: "", hasValidation: true },
    { field: "birthday", location: "birthday", default: null, hasValidation: true },
    { field: "gender", location: "gender", default: null, hasValidation: true },
    { field: "marital_status", location: "marital_status", default: null, hasValidation: false },
    { field: "birth_entity", location: "birth_entity", default: null, hasValidation: false },
    { field: "old_key", location: "old_key", default: null, hasValidation: false },
    { field: "nss", location: "nss", default: "", hasValidation: true },
    { field: "rfc", location: "rfc", default: "", hasValidation: true },
    { field: "curp", location: "curp", default: "", hasValidation: true },
    { field: "address", location: "address", default: "", hasValidation: true },
    { field: "street_number", location: "street_number", default: "", hasValidation: true },
    { field: "cell_phone", location: "cell_phone", default: "", hasValidation: false },
    { field: "phone", location: "phone", default: "", hasValidation: false },
    { field: "corporate_email", location: "corporate_email", default: "", hasValidation: true },
    { field: "email", location: "email", default: "", hasValidation: true },
    { field: "blood_type", location: "blood_type", default: "", hasValidation: false },
    { field: "payroll_type", location: "payroll_type", default: "", hasValidation: true },
    { field: "payment_config", location: "payment_config", default: "", hasValidation: true },
    { field: "ptu", location: "ptu", default: false, hasValidation: false },
    { field: "leave_vouchers", location: "leave_vouchers", default: false, hasValidation: false }
];

export const getStructure = item => item?.enterprise_structure_organization?.enterprise_structure;

const mopersKeywords = [
    'mopers',
    'attendance'
];

export const isFromMopers = () => {
    const focused = store.getState().navigate?.focused_window;
    const currentElement = store.getState().navigate?.[focused];
    const moduleId = currentElement?.id;
    const modulePath = currentElement?.path;
    const from_mopers = mopersKeywords.some(el => moduleId?.includes(el) || modulePath?.includes(el));
    return from_mopers ? { from_mopers } : {};
}

export const omitPayload = (payload = {}, symbol = ["*", "all", "ALL", 99]) => {

	const validateIfArray = value => {
		if (isArray(symbol)) {
			return symbol.includes(value);
		}
		return value === symbol;
	}

	const toBeFiltered = Object.entries(payload).filter(el => validateIfArray(el[1])).map(el => el[0]);
	return omit(payload, toBeFiltered);
}