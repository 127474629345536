import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isFunction } from "lodash";
import { Popover } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconUse from "../../uiDesign/IconUse";
import { SolidDivider } from "../Dividers";
import "./style.scss";

function ModernPopup({
    target,
    title,
    handleClose,
    triggerPosition,
    content: Content,
    children,
    className = "",
    withBackdrop = true,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    },
    ...others
}) {

    const popActions = useRef();

    const open = Boolean(target);
    const id = open ? 'simple-modern-popover' : undefined;
    const body = isFunction(Content) ? <Content /> : Content;

    useEffect(() => {
        if (!popActions.current) return;
        setTimeout(() => {
            popActions.current.updatePosition();
        }, 1);
    }, [open, triggerPosition])

    return (
        <Popover
            id={id}
            open={open}
            action={popActions}
            anchorEl={target}
            onClose={handleClose}
            className={`modern-popup-component ${className} ${!withBackdrop ? 'no-backdrop' : ''}`}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            {...others}
        >
            <PopHeader title={title} handleClose={handleClose} />
            <div className="modern-popup-body">
                {body ?? children}
            </div>
        </Popover>
    );
};

export default ModernPopup;

const PopHeader = ({
    title,
    handleClose,
}) => {

    return (
        <Grid container columnSpacing={4} rowSpacing={1.1} className="modern-popup-header">
            {!!title && <Grid size={'auto'} sx={{ alignSelf: 'flex-end' }}>{title}</Grid>}
            <Grid sx={{ marginLeft: 'auto', alignSelf: 'flex-start' }}>
                <button onClick={handleClose}>
                    <IconUse icon="SlimClose" />
                </button>
            </Grid>
            <SolidDivider />
        </Grid>
    );
};

ModernPopup.propTypes = {
    target: PropTypes.any,
    triggerPosition: PropTypes.number,
    anchorOrigin: PropTypes.object,
    transformOrigin: PropTypes.object,
    children: PropTypes.any,
    withBackdrop: PropTypes.bool,
    title: PropTypes.string,
    handleClose: PropTypes.func,
    content: PropTypes.any,
    className: PropTypes.string,
};

PopHeader.propTypes = {
    title: PropTypes.string,
    handleClose: PropTypes.func,
};