import { lazy } from "react";

const timbrasoftItems = [
	{
		id: 'modules@timbrasoft_app',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/timbrasoft/application/Timbrasoft')),
	},
	{
		id: 'modules@validation_c_p',
		package_permissions: ["payroll_package"],
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/timbrasoft/application/SATValidation')),
	},
	{
		id: 'modules@concept-abr-sat',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/timbrasoft/config/ConceptAbrSAT')),
	},
	{
		id: 'modules@contract-abr-sat',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/timbrasoft/config/ContractAbrSAT')),
	},
	{
		id: 'modules@periodicity-abr-sat',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/timbrasoft/config/PeriodicityAbrSAT')),
	},
	{
		id: 'modules@config-stamp-receipt',
		type: 'item',
		package_permissions: ["payroll_package"],
		inConstruction: true,
		componentElm: true,
		//componentElm: lazy(()  => import('../../../../../modules/timbrasoft/config/ConfigStampReceipt')),
	},
	{
		id: 'modules@timbrasoft-settings',
		package_permissions: ["payroll_package"],
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/timbrasoft/config/ConfigTimbrasoft')),
	},
	{
		id: 'modules@stamp-notifications',
		type: 'item',
		package_permissions: ["payroll_package"],
		componentElm: lazy(() => import('../../../../../modules/timbrasoft/StampNotification')),
	}
];

export default timbrasoftItems;