import { lazy } from 'react';

const imssIcons = {
	bimonthlyvariables: {
		allowedTag: "Bimonthly-Variables",
		Icon: lazy(async () => await import("./BimonthlyVariables.svg")),
	},
	compare: {
		allowedTag: "Compare",
		Icon: lazy(async () => await import("./Compare.svg")),
	},
	emaeba: {
		allowedTag: "Ema-Eba",
		Icon: lazy(async () => await import("./EMA_EBA.svg")),
	},
	idseaudit: {
		allowedTag: "Idse-Audit",
		Icon: lazy(async () => await import("./IDSEAudit.svg")),
	},
	idseconfirmation: {
		allowedTag: "Idse-Confirmation",
		Icon: lazy(async () => await import("./IDSEConfirmation.svg")),
	},
	idsetransfer: {
		allowedTag: "Idse-Transfer",
		Icon: lazy(async () => await import("./IDSETransfer.svg")),
	},
	settlement: {
		allowedTag: "Settlement",
		Icon: lazy(async () => await import("./Settlement.svg")),
	},
	workriskhistory: {
		allowedTag: "Work-Risk-History",
		Icon: lazy(async () => await import("./WorkRiskHistory.svg")),
	},
	family: {
		allowedTag: "Family",
		Icon: lazy(async () => await import("./Family.svg")),
	},
};

export default imssIcons;