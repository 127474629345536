import React, {
	useContext,
	useState,
	useEffect,
	useMemo,
} from 'react';
import { debounce } from 'lodash';
import { AllMenuSearchContext } from './contexts';

const useController = () => {

	const [search, setSearch] = useState("");
	const debouncedSearch = useMemo(() => {
		return debounce(value => setSearch(value), 500);
	}, []);

	useEffect(() => debouncedSearch.cancel());

	return {
		search,
		debouncedSearch
	};

};

export const AllMenuSearchProvider = ({ children }) => (
	<AllMenuSearchContext.Provider value={useController()}>
		{children}
	</AllMenuSearchContext.Provider>
);

/**
 * Controlador de contexto para los conceptos de nomina
 * @returns {useController}
 * */
export const useAllMenuSearch = () => useContext(AllMenuSearchContext);

export default useAllMenuSearch;