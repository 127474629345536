import { lazy } from 'react';

const TimbrasoftIcons = {
	advancefiltersstamp: {
		allowedTag: "Advance-Filters-Stamp",
		Icon: lazy(async () => await import("./AdvanceFiltersStamp.svg")),
	},
	stamp: {
		allowedTag: "Stamp",
		Icon: lazy(async () => await import("./Stamp.svg")),
	},
	stampmenu: {
		allowedTag: "stamp-menu",
		Icon: lazy(async () => await import("./StampMenu.svg")),
	},
	mail: {
		allowedTag: "Mail",
		Icon: lazy(async () => await import("./Mail.svg")),
	},
	cancelstamp: {
		allowedTag: "Cancel-stamp",
		Icon: lazy(async () => await import("./CancelStamp.svg")),
	},
	editmail: {
		allowedTag: "Edit-mail",
		Icon: lazy(async () => await import("./EditMail.svg")),
	},
	download: {
		allowedTag: "Download",
		Icon: lazy(async () => await import("./Download.svg")),
	},
	stamperror: {
		allowedTag: "Stamp-error",
		Icon: lazy(async () => await import("./Error.svg")),
	},
	cancelerror: {
		allowedTag: "Cancel-error",
		Icon: lazy(async () => await import("./cancelerror.svg")),
	},
	notificated: {
		allowedTag: "Notificated",
		Icon: lazy(async () => await import("./Notificated.svg")),
	},
	stampok: {
		allowedTag: "Stamp-ok",
		Icon: lazy(async () => await import("./Ok.svg")),
	},
	cancel: {
		allowedTag: "Cancel",
		Icon: lazy(async () => await import("./Cancel.svg")),
	},
	opennewtab: {
		allowedTag: "Open-new-tab",
		Icon: lazy(async () => await import("./OpenNewTab.svg")),
	},
	upload: {
		allowedTag: "Upload",
		Icon: lazy(async () => await import("./Upload.svg")),
	},
	bobeda: {
		allowedTag: "Bobeda",
		Icon: lazy(async () => await import("./Bobeda.svg")),
	},
	confronta: {
		allowedTag: "Confronta",
		Icon: lazy(async () => await import("./Confronta.svg")),
	},
	homestamp: {
		allowedTag: "HomeStamp",
		Icon: lazy(async () => await import("./Home.svg")),
	},
	moreoptionstamp: {
		allowedTag: "More-Options-Stamp",
		Icon: lazy(async () => await import("./MoreOptions.svg")),
	},
	stamped: {
		allowedTag: "Stamped",
		Icon: lazy(async () => await import("./Timbrado.svg")),
	},
	validationcp: {
		allowedTag: "Validationcp",
		Icon: lazy(async () => await import("./Validation.svg")),
	},
	certificate: {
		allowedTag: "Certificate",
		Icon: lazy(async () => await import("./menu-icons/Certificate.svg")),
	},
	conceptsat: {
		allowedTag: "Conceptsat",
		Icon: lazy(async () => await import("./menu-icons/Conceptsat.svg")),
	},
	contractingregime: {
		allowedTag: "Contractingregime",
		Icon: lazy(async () => await import("./menu-icons/Contractingregime.svg")),
	},
	origin: {
		allowedTag: "Origin",
		Icon: lazy(async () => await import("./menu-icons/Origin.svg")),
	},
	periodicitysat: {
		allowedTag: "Periodicity-sat",
		Icon: lazy(async () => await import("./menu-icons/Periodicity.svg")),
	},
	relation: {
		allowedTag: "Relation",
		Icon: lazy(async () => await import("./menu-icons/Relation.svg")),
	},
	reportsat: {
		allowedTag: "Reports-sat",
		Icon: lazy(async () => await import("./menu-icons/Reports.svg")),
	},
	typecontractsat: {
		allowedTag: "Typecontract-sat",
		Icon: lazy(async () => await import("./menu-icons/Typecontractsat.svg")),
	},
	configurationstamp: {
		allowedTag: "Configurationstamp",
		Icon: lazy(async () => await import("./menu-icons/Configurationstamp.svg")),
	},
};

export default TimbrasoftIcons;
