import { lazy } from 'react';

const abrhilIcons = {
	advancefilters: {
		allowedTag: "Advance-Filters",
		Icon: lazy(async () => await import("./AdvanceFilters.svg")),
	},
	annual: {
		allowedTag: "Annual",
		Icon: lazy(async () => await import("./Annual.svg")),
	},
	bimonthly: {
		allowedTag: "Bimonthly",
		Icon: lazy(async () => await import("./Bimonthly.svg")),
	},
	calculate: {
		allowedTag: "Calculate",
		Icon: lazy(async () => await import("./Calculate.svg")),
	},
	complement: {
		allowedTag: "Complement",
		Icon: lazy(async () => await import("./Complement.svg")),
	},
	delete: {
		allowedTag: "Delete",
		Icon: lazy(async () => await import("./Delete.svg")),
	},
	edit: {
		allowedTag: "Edit",
		Icon: lazy(async () => await import("./Edit.svg")),
	},
	file: {
		allowedTag: "File",
		Icon: lazy(async () => await import("./File.svg")),
	},
	money: {
		allowedTag: "Money",
		Icon: lazy(async () => await import("./Money.svg")),
	},
	fileedit: {
		allowedTag: "File-Edit",
		Icon: lazy(async () => await import("./FileEdit.svg")),
	},
	fileentry: {
		allowedTag: "File-Entry",
		Icon: lazy(async () => await import("./FileEntry.svg")),
	},
	fileprograming: {
		allowedTag: "File-Programing",
		Icon: lazy(async () => await import("./FilePrograming.svg")),
	},
	initialize: {
		allowedTag: "Initialize",
		Icon: lazy(async () => await import("./Initialize.svg")),
	},
	monthly: {
		allowedTag: "Monthly",
		Icon: lazy(async () => await import("./Monthly.svg")),
	},
	moreoptions: {
		allowedTag: "More-Options",
		Icon: lazy(async () => await import("./MoreOptions.svg")),
	},
	new: {
		allowedTag: "New",
		Icon: lazy(async () => await import("./New.svg")),
	},
	payrollbloq: {
		allowedTag: "Payroll-Bloq",
		Icon: lazy(async () => await import("./PayrollBloq.svg")),
	},
	print: {
		allowedTag: "Print",
		Icon: lazy(async () => await import("./Print.svg")),
	},
	save: {
		allowedTag: "Save",
		Icon: lazy(async () => await import("./Save.svg")),
	},
	comment: {
		allowedTag: "Comment",
		Icon: lazy(async () => await import("./comment.svg")),
	},
	refresh: {
		allowedTag: "Refresh",
		Icon: lazy(async () => await import("./Refresh.svg")),
	},
	switch: {
		allowedTag: "Switch",
		Icon: lazy(async () => await import("./switch.svg")),
	},
	import: {
		allowedTag: "Import",
		Icon: lazy(async () => await import("./Import.svg")),
	},
	warning: {
		allowedTag: "Warning",
		Icon: lazy(async () => await import("./Warning.svg")),
	},
	sort: {
		allowedTag: "Sort",
		Icon: lazy(async () => await import("./Sort.svg")),
	},
};

export default abrhilIcons;
