import PropTypes from 'prop-types';
import React from 'react';
import {
    FlowAndMessage,
    MainInfo,
    MoperIcon,
    MoperInfo,
} from '../../../../../../../../../signature/SignaturesByWorker/components/Details/components/DynamicMoper';
import {
    hasBuiltMoper,
    moperConstructor,
} from '../../../../../../../../../signature/SignaturesByWorker/components/Details/components/models';
import { customMoperModels } from '../../../../models';
import { hasMinimal } from '../utils/utils';
import Grid from '@mui/material/Grid2';
import { colorStyle, keyName, parseDatetime } from '../../../../../../../../../../../general/@components/general/GeneralUtilities';
import GenericLabel from '../../../../../../../../../../../general/@components/general/GenericLabel/GenericLabel';
import { SolidDivider } from '../../../../../../../../../../../general/@components/general/Dividers';
import { t } from 'i18next';
import { toTitleCase } from '../../../../../../../../../../../general/@components/navigation/components/userSection/utilities';
import { omit } from 'lodash';

function DetailMoperItem({
    moper,
    onSign,
    onEdit,
    onlyRead,
    item,
    data,
    refresh,
    withPopupFlow = false,
    fromList = false,
    ...others
}) {

    const req = hasBuiltMoper(item) ? item : moperConstructor(moper, customMoperModels, true);
    const hasMin = hasMinimal(req);
    const moperData = data ?? req?.data;
    const hasData = moperData?.filter(el => el?.value !== '-').length > 0;

    return (
        <div className="mopers-detail-item-flow">
            <DetailMoper
                request={req}
                hasData={hasData}
                fields={moperData}
                moper={req?.exactMoper}
                withPopupFlow={withPopupFlow}
                fromList={fromList}
                onlyRead={onlyRead}
                refresh={refresh ?? onSign}
                {...others}
            />
            {(hasData && !hasMin) &&
                <FlowAndMessage
                    fromList={fromList}
                    exactMoper={req?.exactMoper}
                    payloadFields={req?.payloadFields}
                    refresh={onSign ?? refresh}
                    onEdit={onEdit}
                    onlyRead={onlyRead}
                    showMessage
                    showCancel
                    showLevel
                    {...others}
                />}
        </div>
    );
};

export default DetailMoperItem;

const DetailMoper = ({ request, ...others }) => {

    const hasMin = hasMinimal(request);

    return (
        <div className="popup-moper-info-data">
            {!hasMin ?
                <CompleteDetail {...others} request={request} />
                :
                <MinimalDetail {...others} request={request} />}
        </div>
    );
};

const CompleteDetail = ({
    request,
    moper,
    fromList,
    hasData,
    fields,
    ...others
}) => {

    return (
        <>
            <MainInfo
                {...omit(request, ['key'])}
                moper={moper}
                request={request}
                fromList={fromList}
                statusResponsive
                showDetails={false}
                {...others}
            />
            {!hasData ?
                <div className="no-moper-info-wrapper">
                    <strong>{'No hay información disponible para este moper'}</strong>
                    <span>{'Este moper fue creado a partir de una importación'}</span>
                </div>
                :
                <MoperInfo
                    data={fields}
                    moper={moper}
                    moperValue={request}
                    showFlow={!fromList}
                    {...others}
                />}
        </>
    );
};

const MinimalDetail = ({ request, moper, ...others }) => {

    const username = moper?.user?.username || moper?.requested_by;
    const description = moper?.absenteeism_reason?.description || moper?.description;
    const isF = request?.exactMoper?.attendance_key === 'fault';
    const createdAt = moper?.created_at;

    return (
        <Grid
            container
            spacing={2}
            direction="row"
            size={12}
            wrap="nowrap"
            className="vertical-center"
        >
            <Grid>
                <MoperIcon
                    {...omit(request, ['key'])}
                    request={request}
                    moper={moper}
                    className={isF ? 'red' : ''}
                    {...others}
                />
            </Grid>
            <Grid container direction="column" spacing={0}>
                <Grid
                    size={12}
                    container
                    spacing={0.4}
                    sx={{
                        ...colorStyle('var(--egyptianBlue)'),
                        alignItems: 'center'
                    }}
                >
                    <Grid sx={{ fontWeight: 500, lineHeight: 1 }}>
                        {toTitleCase(keyName(moper?.absenteeism_reason?.absenteeism || moper))}
                    </Grid>
                    {!!description &&
                        <Grid sx={{ fontWeight: 300, fontSize: '12px', lineHeight: 1 }}>
                            {`| ${description}`}
                        </Grid>}
                </Grid>
                <Grid size={12}>
                    <GenericLabel
                        label={'Aplicado por'}
                        fontSize='12px'
                        value={username || t('automatic')}
                    />
                </Grid>
                {!!createdAt &&
                    <Grid size={12}>
                        <GenericLabel
                            label={'Fecha y hora de aplicación'}
                            fontSize='12px'
                            value={parseDatetime(createdAt, 'dd/MM/yyyy HH:mm', true)}
                            labelStyle={colorStyle('var(--midGray)')}
                            style={colorStyle('var(--midGray)')}
                        />
                    </Grid>}
            </Grid>
            {moper?.request_comment &&
                <>
                    <Grid sx={{ height: '100%' }}>
                        <SolidDivider vertical />
                    </Grid>
                    <Grid>
                        <GenericLabel
                            label={'Comentario'}
                            fontSize='12px'
                            align="vertical"
                            value={moper?.request_comment}
                            style={{ maxWidth: '23rem' }}
                            wrapValue
                        />
                    </Grid>
                </>}
        </Grid>
    );
};

DetailMoper.propTypes = {
    moper: PropTypes.object,
    request: PropTypes.object,
    fields: PropTypes.array,
    hasData: PropTypes.bool,
};

DetailMoperItem.propTypes = {
    moper: PropTypes.object,
    onSign: PropTypes.func,
    onEdit: PropTypes.func,
    onlyRead: PropTypes.bool,
    item: PropTypes.object,
    data: PropTypes.array,
    refresh: PropTypes.func,
    fromList: PropTypes.bool,
    withPopupFlow: PropTypes.bool,
};

CompleteDetail.propTypes = {
    request: PropTypes.object,
    moper: PropTypes.object,
    fromList: PropTypes.bool,
    hasData: PropTypes.bool,
    fields: PropTypes.array,
};

MinimalDetail.propTypes = {
    request: PropTypes.object,
    moper: PropTypes.object,
};