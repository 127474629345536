import { store } from "../../../../store";
import { permsGate } from "../navigation/utilities";

export function currentUserInfo() {
    return store.getState().userConfigs;
}

export function currentGroup() {
    const group = store.getState().group;
    if (group?.data.length === 0) {
        return 0;
    }
    return group?.select?.id ?? null;
}

export const canSeeAccounts = () => permsGate('general-settings@accounts-permissions.can-see-accounts');
export const canEditAccounts = () => permsGate('general-settings@accounts-permissions.can-edit-accounts');
export const usesSpeechRecon = () => permsGate('general-settings.use-voice-assistance');
export const speechReconListening = () => permsGate('general-settings.always-listening');
export const usesMaps = () => permsGate('general-settings.use-maps');