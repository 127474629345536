import React from 'react';
import PropTypes from 'prop-types';
import { store } from '../../../../../../../store';
import { unlockedWindow } from '../../../../../../../store/actions';
import { resolveError } from '../../../../../../general/@components/requests/resolve-error';
import { AnimatePresence, motion } from 'framer-motion';
import { opacityAnimation } from '../../../../../../general/@components/form/Permission/Utils';
import { getArrayItemKey, hasValue } from '../../../../../../general/@components/general/GeneralUtilities';
import { CircularProgress } from '@mui/material';
import GenericLabel, {
    canViewMopersAmounts,
    canViewSalary,
    SALARY_CENSURE,
    validateSalaryPerms,
} from '../../../../../../general/@components/general/GenericLabel/GenericLabel';
import '../../style/style.scss';

/**
 * Componente para validar permisos de ver salarios
 * @param {object} params
 * @param {boolean} params.censure
 * @param {"kardex" | "mopers"} params.type - Tipo de permiso a utilizar (kardex o mopers)
 */
export const SalariesGate = ({ children, type = 'kardex', censure = false }) => {
    let hasPerm = canViewSalary();

    if (type === 'mopers') {
        hasPerm = canViewMopersAmounts();
    }

    if (!children) return null;

    if (!hasPerm && !censure) return censure ? SALARY_CENSURE : null;

    return children;
};

SalariesGate.propTypes = {
    type: PropTypes.string,
    censure: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element,
    ])
};

export const SalaryText = ({ children, value }) => {
    const salaryPerm = canViewSalary();
    return validateSalaryPerms(children || value, salaryPerm);
}

SalaryText.propTypes = {
    children: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export const onFailed = error => {
    store.dispatch(unlockedWindow());
    resolveError(error);
}

export const TextMatrix = ({
    items,
    withTooltips = true
}) => {
    const isNotBlank = value => !value?.includes('blank');

    return (
        <table className="gs-text-matrix">
            <tbody>
                {items?.map((item, ind) =>
                    <React.Fragment key={getArrayItemKey(ind, "text-matrix-item")}>
                        <tr>
                            <td>{isNotBlank(item.label) && <span><GenericLabel label={item.label} tooltip={item.value} disableTooltip={!withTooltips} />{':'}</span>}</td>
                            <td>{isNotBlank(item.label) && <GenericLabel value={item.value} className={item.class ?? ''} disableTooltip={!withTooltips} />}</td>
                        </tr>
                        {!!item?.legend &&
                            <tr className={`legend-row ${ind === 0 ? "upside" : ""}`}>
                                <td>
                                    <span>{item.legend}</span>
                                </td>
                            </tr>}
                    </React.Fragment>
                )}
            </tbody>
        </table>
    );
};

TextMatrix.propTypes = {
    items: PropTypes.array,
    withTooltips: PropTypes.bool
};

export const TextAsync = ({
    text,
    className,
    style = {},
    loaderSize = 15,
}) => {

    TextAsync.propTypes = {
        text: PropTypes.any,
        loaderSize: PropTypes.number,
        style: PropTypes.object,
        className: PropTypes.string,
    };

    return (
        <AnimatePresence mode="wait">
            {
                hasValue(text) ?
                    <motion.span
                        key={text}
                        className={`custom-text-async ${className}`}
                        style={style}
                        {...opacityAnimation}
                    >
                        {text}
                    </motion.span>
                    :
                    <CircularProgress className="async-text-loader" size={loaderSize} />
            }
        </AnimatePresence>
    );
};

export const PaddingWidget = ({
    children,
    value = '10px',
    className = '',
    style = {},
}) => {

    return (
        <div className={`sbw-padding-widget ${className}`} style={{ padding: value, ...style }}>
            {children}
        </div>
    );
};

PaddingWidget.propTypes = {
    children: PropTypes.any,
    value: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
};