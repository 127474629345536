import { lazy } from 'react';

const kioskIcons = {
	accesscontrol: {
		allowedTag: "Access-Control",
		Icon: lazy(async () => await import("./AsignEstructure.svg")),
	},
	asignestructure: {
		allowedTag: "Asigne-Structure",
		Icon: lazy(async () => await import("./AccessControl.svg")),
	},
	permissions: {
		allowedTag: "Permissions",
		Icon: lazy(async () => await import("./Permisos.svg")),
	},
	users: {
		allowedTag: "Users",
		Icon: lazy(async () => await import("./Usuarios.svg")),
	},
};

export default kioskIcons;