import PropTypes from "prop-types";
import { InputProps } from '../Input/propsDescriptions';

export const ModalContentProps = {
    children: PropTypes.any,
    extraFields: PropTypes.any,
    extraFilters: PropTypes.any,
    extraParams: PropTypes.any,
    forPayroll: PropTypes.bool,
    fromMopers: PropTypes.bool,
    getApi: PropTypes.any,
    handleCloseDialogForm: PropTypes.any,
    includeData: PropTypes.bool,
    includeTo: PropTypes.any,
    initSearchValue: PropTypes.any,
    multi: PropTypes.bool,
    onClose: PropTypes.func,
    onlyActives: PropTypes.bool,
    prefix: PropTypes.any,
    selectBy: PropTypes.string,
    setWorkersSelectedData: PropTypes.any,
    withAcceptButton: PropTypes.bool,
    withFinishSelection: PropTypes.bool,
    with_permission_user: PropTypes.bool,
    withoutActions: PropTypes.bool,
    workers: PropTypes.any,
    workersSelectedData: PropTypes.array
};

export const ModalProps = {
    handleCloseDialogForm: PropTypes.func,
    multi: PropTypes.bool,
    onClose: PropTypes.func,
    openDialogForm: PropTypes.bool,
    ...ModalContentProps
};

export const IconAndSearchProps = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    selected: PropTypes.any,
    ...ModalProps
};

export const InputAndModalProps = {
    disabled: PropTypes.bool,
    handleSelect: PropTypes.func,
    multi: PropTypes.bool,
    onKeyPress: PropTypes.func,
    value: PropTypes.any,
    ...InputProps,
    ...ModalProps
};