import React, { useEffect, useState } from "react";
import {
	validateShowItem,
	validateShowMessage,
	baseRole
} from "../../../../utils/utilities";
import { transition } from "../../../../../../../../general/@components/uiDesign/animations";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid2';
import Button from "../../../../../../../../general/styled/Button";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWatch } from "react-hook-form";
import RoleItem from "./Item";
import Permission from "../../../../../../../../general/@components/form/Permission/Permission";
import {
	faCheck,
	faLayerGroup as faLayerGroupAlt,
	faCube,
} from "@fortawesome/pro-solid-svg-icons";
import { scaleOpacityAnimation } from "../../../../../../../../general/@components/form/Permission/Utils";
import { useDialogContext } from "../../../../hooks/DialogController";

function RolesView() {

	return (
		<AnimatePresence mode='wait'>
			<Grid
				key="main-container"
				container
				size={12}
				spacing={0}
				component={motion.div}
				style={{
					position: 'relative',
					minHeight: '390px',
					height: '100%',
				}}
				{...scaleOpacityAnimation}
			>
				<GroupList />
				<ModulePermissions />
			</Grid>
		</AnimatePresence>
	);
}

export default RolesView;

const GroupList = () => {

	const { t } = useTranslation();
	const [dataSource, setDataSource] = useState([]);
	const [editing, setEditing] = useState(false);

	const {
		control,
		setValue,
		rolesData,
		setViewName,
		definedItems,
		setCurrentInfo,
		updateHeaderInfo,
		setCurrentGroup,
		handlePushPath,
		validateRoleAssign,
		roleIndex,
		setRoleIndex,
		errors,
	} = useDialogContext();

	const groups = useWatch({ control, name: `groups_perms` });
	const showGroups = groups?.length > 0;

	useEffect(() => {
		if (!rolesData) return;
		const filteredData = rolesData.filter(el => !groups.some(li => li?.group?.id === el.id));
		setDataSource(filteredData);
	}, [rolesData, groups])

	const handleAddRole = () => {
		if (!validateRoleAssign()) return;
		setValue(`role_select`, null);
		setRoleIndex(null);
		setCurrentGroup(null);
		setEditing(true);
		setValue('groups_perms', [...groups, baseRole]);
	}

	const handleConfigAccess = (item, itemIndex) => {
		const onlyBranch = item?.group?.config?.only_branch;
		const info = onlyBranch ? t('branch') : `${t('branch')} - R. Social / Reg. Patronal`;
		updateHeaderInfo(`${info}`, faLayerGroupAlt, 'structure-access');
		setRoleIndex(itemIndex);
		setCurrentGroup(groups[itemIndex]);
		setViewName(item?.name ?? item?.group?.name);
		handlePushPath(definedItems?.find(el => el.key === 'branches')?.path);
	}

	return (
		<Grid
			component={motion.div}
			className="roles-selected-container"
			key="roles-selected-container"
			initial={false}
			animate={{
				width: showGroups ? '40%' : '100%',
				maxWidth: showGroups ? '40%' : '100%'
			}}
		>
			<div className="roles-container">
				<div className="button-container">
					<Button
						design="contained"
						onClick={handleAddRole}
						disabled={editing}
						className={`${errors?.groups_perms && !editing && !groups?.length ? 'error' : ''}`}
					>
						{t('add-roles')}
					</Button>
				</div>
				<AnimatePresence mode='wait' initial={false}>
					{groups?.length ?
						<motion.div
							className="role-items-container"
							key="role-items-container"
							{...scaleOpacityAnimation}
						>
							{groups.map((el, index) =>
								<RoleItem
									key={`role-perm-${index}`}/*NOSONAR*/
									item={el}
									keyItem={index}
									errors={errors}
									dataInput={dataSource}
									setEditing={setEditing}
									roleIndex={roleIndex}
									setRoleIndex={setRoleIndex}
									handleConfigAccess={handleConfigAccess}
									setCurrentInfo={setCurrentInfo}
									setCurrentGroup={setCurrentGroup}
								/>)}
						</motion.div>
						:
						<motion.div
							className="no-roles-message-container"
							key="no-selected-roles-message"
							{...scaleOpacityAnimation}
						>
							{'Sin roles asignados'}
						</motion.div>}
				</AnimatePresence>
			</div>
		</Grid>
	);
};

const ModulePermissions = () => {

	const {
		open,
		control,
		setValue,
		getValues,
		roleIndex,
		roleInModules,
		setCurrentInfo,
		canAssignRoles,
		updateHeaderInfo,
	} = useDialogContext();

	const groups = useWatch({ control, name: `groups_perms` });
	const showGroups = groups?.length > 0;

	const inModules = roleInModules === roleIndex;
	const showModuleInfo = validateShowItem(roleIndex, groups) && inModules;

	useEffect(() => {
		if (showModuleInfo) {
			updateHeaderInfo('Módulos', faCube, 'modules-perms');
			return;
		}
		setCurrentInfo(null);
	}, [roleIndex])

	return (
		<AnimatePresence mode="wait">
			{showGroups ?
				<Grid
					component={motion.div}
					className="permissions-config-container"
					key="permissions-config-container"
					initial={{ width: '0%' }}
					animate={{ width: '60%' }}
					exit={{ width: '0%' }}
					transition={transition}
				>
					<AnimatePresence mode='wait'>
						{showModuleInfo ?
							<motion.div
								className='tree-permissions-config'
								key={`permissions-tree-${roleIndex}`}
								{...scaleOpacityAnimation}
							>
								<Permission
									open={open}
									control={control}
									setValue={setValue}
									getValues={getValues}
									animate={false}
									disabled={!canAssignRoles}
									name={`groups_perms[${roleIndex}].rolePerms`}
									dashboardName={`groups_perms[${roleIndex}].user_group_configs`}
									dashboardSettings
									maxHeight='100%'
								/>
							</motion.div>
							:
							<motion.div
								className="no-roles-message-container"
								key="role-messages-container"
								{...scaleOpacityAnimation}
							>
								<AnimatePresence mode='wait'>
									{
										validateShowMessage(groups) ?
											<motion.div
												className='message-container'
												key="select-rol-message"
												{...scaleOpacityAnimation}
											>
												<div className="action-button">
													<FontAwesomeIcon icon={faCube} size='2xl' />
												</div>
												{'Seleccione un rol a configurar'}
											</motion.div>
											:
											<motion.div
												className='message-container'
												key="add-rol-message"
												{...scaleOpacityAnimation}
											>
												<div className="action-button">
													<FontAwesomeIcon icon={faCheck} size='2xl' />
												</div>
												{'Agregue un rol'}
											</motion.div>
									}
								</AnimatePresence>
							</motion.div>}
					</AnimatePresence>
				</Grid>
				:
				null}
		</AnimatePresence>
	);
}