import { lazy } from "react";

const imssItems = [
	{
		id: 'modules@affiliate-report',
		type: 'item',
		icon: 'idsetransfer',
		componentElm: lazy(() => import('../../../../../modules/imss/AffiliateReport')),
	},
	{
		id: 'modules@idse-audit',
		type: 'item',
		icon: 'idseaudit',
		componentElm: lazy(() => import('../../../../../modules/imss/IDSEAudit')),
	},
	{
		id: 'modules@liquidation',
		type: 'item',
		icon: 'settlement',
		componentElm: lazy(() => import('../../../../../modules/imss/Liquidation')),
	},
	{
		id: 'modules@EMA-EBA',
		type: 'item',
		icon: 'emaeba',
		componentElm: lazy(() => import('../../../../../modules/imss/EMA-EBA')),
	},
	{
		id: 'modules@confirmation',
		type: 'item',
		icon: 'idseconfirmation',
		componentElm: lazy(() => import('../../../../../modules/imss/IDSEConfirmation')),
	},
	{
		id: 'modules@transfer',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/imss/SUAtransfer')),
	},
	{
		id: 'modules@monthly-settlement-comparison',
		type: 'item',
		icon: 'compare',
		componentElm: lazy(() => import('../../../../../modules/imss/MSettlementComparasion')),
	},
	{
		id: 'modules@work-risk-history',
		type: 'item',
		icon: 'workriskhistory',
		componentElm: lazy(() => import('../../../../../modules/imss/WorkRiskHistory')),
	},
	{
		id: 'modules@imss-settings',
		type: 'item',
		componentElm: lazy(() => import('../../../../../modules/imss/SettingsIMSS')),
	},
	//En construccion
	{
		id: 'modules@imss-catalogs',
		type: 'item',
		inConstruction: true,
		componentElm: true,
	},
	{
		id: 'modules@imss-settings',
		title: 'settings',
		type: 'item',
		inConstruction: true,
		componentElm: true,
	},
];

export default imssItems;