import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { has, isBoolean, isNumber, size } from "lodash";
import { useTranslation } from "react-i18next";

const permsDict = {
	read: "access",
	'cancel-vacations': 'cancel-vacations-moper',
};

const Controller = ({
	open,
	dataItem,
	dashboardItem,
	getValues,
	name,
	disabled: treeDisabled,
}) => {
	const { t } = useTranslation();
	const menu = useSelector((state) => state.navigate?.routes);
	const canModifyRoles = useSelector(state => state.userConfigs?.can_modify_roles) ?? false;

	const [formatedData, setFormatedData] = useState(null);
	const [disabled, setDisabled] = useState(treeDisabled || true);

	const data = dataItem ?? getValues(name);

	useEffect(() => {
		if (menu && open && data) {
			setInitialData(menu, data);
		}
	}, [open, dataItem, dashboardItem])

	useEffect(() => {
		setDisabled(!canModifyRoles || treeDisabled);
	}, [canModifyRoles, treeDisabled])

	function setInitialData(menu, dataItem) {
		let treePermissions = menu;
		let userData = [...dataItem];
		treePermissions = treePermissions.filter(el => el.show_in_perms).map(item => formatData(item, userData));
		setFormatedData(treePermissions);
	}

	function formatData(item, itemPermissions) {
		if (item?.permission_id) return item;
		let newItem = {};
		if (isNumber(item?.element_id) && isBoolean(item?.is_subitem)) {
			newItem.element_id = item.element_id;
			newItem.is_subitem = item.is_subitem;
		}
		newItem.info = item.title;
		newItem.icon = item?.icon;
		newItem.key = item.key;
		newItem.text = item.key;
		newItem.checked = false;
		newItem.expanded = false;
		newItem.minus = false;
		if (!item.key?.includes('@') && size(item?.bread) > 1) {
			const parent = item.bread.join(',').toLowerCase();
			newItem.key = `${parent}@${newItem.key}`;
			newItem.id = newItem.key;
			newItem.text = newItem.key;
		}
		newItem.disabled = item?.is_accesible === false;
		if (has(item, 'with_package_perms')) {
			newItem.disabled = !item.with_package_perms;
			newItem.with_package_perms = item.with_package_perms;
		}
		if (item?.element_id) {
			newItem.checked = validateCheck(newItem, itemPermissions, newItem.disabled, 'element_id');
		}
		if (size(item?.perms)) {
			let permissions = item.perms;
			permissions = permissions.map(
				perm => ({
					info: t(permsDict[perm.action.toLowerCase()] ?? perm.action.toLowerCase()),
					permission_id: perm.id,
					key: perm.id,
					disabled: newItem.disabled,
					initial_disabled: newItem.disabled,
					checked: validateCheck(perm, itemPermissions, newItem.disabled),
					...(has(item, 'with_package_perms') ? { with_package_perms: item.with_package_perms } : {}),
				}));
			if (item.type !== 'collapse') {
				newItem.items = permissions;
			} else {
				newItem.items = [...(newItem.items ?? []), ...permissions];
			}
		}
		newItem.initial_disabled = newItem.disabled;
		if (size(item?.children) && !item.children?.some(el => el.index)) {
			newItem.items = [...(newItem.items ?? []), ...item.children];
			newItem.items = newItem.items.filter(el => el.show_in_perms || el.permission_id).map(el => formatData(el, itemPermissions));
		}
		return newItem;
	}

	function validateCheck(item, itemPermissions, disabled, field = 'id') {
		if (!itemPermissions || !size(itemPermissions)) return false;
		if (disabled) {
			itemPermissions = itemPermissions.filter(el => el !== item?.[field]);
			return false;
		};
		const value = itemPermissions.find(el => el === item?.[field]);
		return !!value;
	}

	return {
		disabled,
		formatedData,
	}
};

export default Controller;
