import { lazy } from 'react';

const aditionalsIcons = {
    apportionment: {
        allowedTag: "Apportionment",
        Icon: lazy(async () => await import("./Apportionment.svg")),
    },
    breafing: {
        allowedTag: "Breafing",
        Icon: lazy(async () => await import("./Breafing.svg")),
    },
    budgetcalculation: {
        allowedTag: "Budget-Calculation",
        Icon: lazy(async () => await import("./BudgetCalculation.svg")),
    },
    budgetcontrol: {
        allowedTag: "Budget-Control",
        Icon: lazy(async () => await import("./BudgetControl.svg")),
    },
    dinning: {
        allowedTag: "Dinning",
        Icon: lazy(async () => await import("./Dinning.svg")),
    },
    events: {
        allowedTag: "Events",
        Icon: lazy(async () => await import("./Events.svg")),
    },
    external: {
        allowedTag: "External",
        Icon: lazy(async () => await import("./External.svg")),
    },
    housing: {
        allowedTag: "Housing",
        Icon: lazy(async () => await import("./Housing.svg")),
    },
    internaltraining: {
        allowedTag: "Internal-Training",
        Icon: lazy(async () => await import("./InternalTraining.svg")),
    },
    lockers: {
        allowedTag: "Lockers",
        Icon: lazy(async () => await import("./Lockers.svg")),
    },
    safeguards: {
        allowedTag: "Safeguards",
        Icon: lazy(async () => await import("./Safeguards.svg")),
    },
    staffing: {
        allowedTag: "Staffing",
        Icon: lazy(async () => await import("./Staffing.svg")),
    },
    tips: {
        allowedTag: "Tips",
        Icon: lazy(async () => await import("./Tips.svg")),
    },
    uniforms: {
        allowedTag: "Uniforms",
        Icon: lazy(async () => await import("./Uniforms.svg")),
    },
};

export default aditionalsIcons;