// @flow
import React, {
	useEffect,
	useState,
	useRef,
	useMemo,
} from 'react';
import { size as _size, debounce, isFunction, isString, pick } from "lodash";
import { uid } from 'uid';
// import type { payrollPeriodProps } from "../../PayrollPeriod/propTypes";

/* components */
import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";

/* utilities */
import withValueField from "../../Templates/withValueField";
import { valueOrOption, hasValue, isEqualsValues } from '../../general/GeneralUtilities';
import { objectValue } from '../../form/utilities';
import { kendoSizeClasses } from '../../uiDesign/sizesUtilities';
import { payrollPeriodDataConstructor } from '../../PayrollPeriod/searchUtilities';

/* templates */
import { KendoLabelContainer } from '../../Templates/SideLabelContainer';
import { NoPeriodsDataRender, payrollPeriodItemRender, payrollPeriodItemvalueRender } from '../../PayrollPeriod/renderTemplates';
import { conditional } from '../../table/CommandCell';

const DropDownListWithValueField = withValueField(DropDownList);

/**
 * Hook que nos obtiene una lista de los periodos de nómina de acuerdo a los filtros usados
 * @param {payrollPeriodProps} props
 * @returns {JSX.Componet}
*/
const PayrollPeriod = ({
	id,
	value,
	label,
	current_year,
	current_month,
	payroll_type,
	computed_type,
	forceReload = false,
	onChange,
	setLoading,
	dataPayrollType = [],
	sideLabel = false,
	objValueHandler = {},
	cutomNoDataMsg = "",
	lockWindow = true,
	setPeriodsData = () => null,
	...others
}/* : payrollPeriodProps */) => {

	const { size, className } = kendoSizeClasses(others);
	const fieldId = id || "period_" + uid();
	const filter = useRef(null);
	const periods = useRef([]);
	const noDataMsg = useRef(null);
	const [data, setData] = useState([]);
	const [load, setLoad] = useState(false);
	const force = useRef(false);
	const lastSearch = useRef({
		computed_type: null,
		payroll_type: null,
		current_year: null,
		current_month: null
	});

	const newSearchValues = { computed_type, payroll_type, current_year, current_month };

	const initialize = async ({ value, ...search_values }) => {

		if (!search_values?.payroll_type || !search_values?.current_year) {
			return;
		}

		const pType = conditional(search_values?.payroll_type === 99, null, search_values?.payroll_type);
		const reload = valueOrOption(search_values?.forceReload, false);
		const news = pick(search_values, "computed_type", "payroll_type", "current_year", "current_month");
		const isSame = isEqualsValues(news, lastSearch.current);
		const notSearch = isSame && !reload && !force.current;

		if (notSearch) {
			force.current = false;
			return;
		}

		//Se agrega opcion 99 para indicar todos los tipos de nomina se pone en null para omitir el filtros¡
		performLoad(true);
		const {
			data: searchedData,
			current,
			message
		} = await payrollPeriodDataConstructor({
			...search_values,
			value,
			payroll_type: pType
		});

		noDataMsg.current = message;

		periods.current = searchedData;
		lastSearch.current = news;
		setData(searchedData);
		setPeriodsData(searchedData);
		filter.current = null;

		if (!_size(searchedData)) {
			handleChange({ value: null });
			performLoad(false);
			return;
		}

		//si es 99 se busca el predeterminado y se pone el periodo actual
		//const finded = find(searchedData, ["id", value]);
		//finded se omite, ya que no cambiaba el valor al seleccionar todos y despues el tipo de nomina por defecto
		const firstInList = searchedData[0]?.id ?? null;

		performLoad(false);
		if (pType === 99) {
			const pred = dataPayrollType.find(f => f.is_predetermined)?.id ?? 0;
			const new_value = searchedData.find(f =>
				f.id === valueOrOption(value?.id, value) || (f.list && f.payroll_type === pred)
			);
			return handleChange({ value: valueOrOption(new_value?.id, firstInList) });
		}

		if (hasValue(current) || reload) {
			return handleChange({ value: conditional(reload, value, current) });
		}

		if (_size(searchedData) && searchedData[0]?.id !== value) {
			// const finded = find(searchedData, ["id", value]);
			return handleChange({ value: firstInList });
		}
	};

	const performLoad = value => {
		setLoad(value);
		if (isFunction(setLoading)) {
			setLoading(value);
		}
	};

	const debouncedSearch = useMemo(() => debounce(initialize, 500), []);

	useEffect(() => {
		debouncedSearch.cancel();
		debouncedSearch({
			current_year,
			payroll_type,
			computed_type,
			current_month,
			forceReload,
			lockWindow,
			value,
			...others
		});
		//eslint-disable-next-line
	}, [current_year, payroll_type, computed_type, current_month, forceReload]);

	useEffect(() => {
		if (
			!isFunction(objValueHandler?.getter) ||
			!isFunction(objValueHandler?.setter) ||
			!isString(objValueHandler?.objName) ||
			!hasValue(value) ||
			!hasValue(data)
		) {
			return;
		}

		const { objName, getter, setter, } = objValueHandler;

		const currentObjValue = getter(objName);
		if (!hasValue(currentObjValue)) {
			const objValue = data.find(item => item.id === value) ?? null;
			if (objValue?.id !== currentObjValue?.id) {
				setter(objName, objValue);
			}
		}
	}, [value, data]);

	const filterChange = (event) => {
		filter.current = event.filter?.value;
		setData(filterBy(
			periods.current,
			{ ...event.filter, field: "forSearch" }
		));
	};

	const handleChange = (e) => {
		if (isFunction(onChange)) {
			let returned = objectValue(e, e.value);
			returned.selected = e.value ? periods.current.find(item => item.id === e.value) : null;
			onChange(returned);
		}
	};

	const onReload = () => {
		force.current = true;

		debouncedSearch({
			current_year,
			payroll_type,
			computed_type,
			current_month,
			forceReload: true,
			lockWindow,
			value,
			...others
		});
	};

	const noDataRender = element => NoPeriodsDataRender(
		element,
		valueOrOption(noDataMsg.current, cutomNoDataMsg),
		newSearchValues,
		onReload
	);

	const valueRender = (li, item) => payrollPeriodItemvalueRender(li, item, onReload);

	return (
		<KendoLabelContainer
			label={label}
			editorId={fieldId}
			editorValue={!!value}
			className={"custom-floating-label"}
			sideLabel={sideLabel}
		>
			<DropDownListWithValueField
				popupSettings={{
					popupClass: "customPopup"
				}}
				{...others}
				id={fieldId}
				size={size}
				loading={load}
				valueField={"id"}
				textField={"forSearch"}
				className={`${className ?? ""} asMui`}
				data={data}
				value={value}
				onChange={handleChange}
				listNoDataRender={noDataRender}
				itemRender={payrollPeriodItemRender}
				valueRender={valueRender}
				filterable={_size(periods.current) > 5}
				filter={filter.current}
				onFilterChange={filterChange}
			/>
		</KendoLabelContainer>
	);
};

export default PayrollPeriod;